import React from 'react'

const MailLogs = () => {
  return (
    <div>
      <p>Mail</p>
    </div>
  )
}

export default MailLogs
