import React, { useEffect, useState } from 'react'
import { getExamResultForAdmin, getResultDetail } from '../../../apis/ExamApi';
import { toastError } from '../../../global/Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const ViewResults = () => {

const [search,setSearch]=useState();
const [isLoading,setIsLoading]=useState();
const [resultData,setResultData]=useState([]);

const [page, setPage] = useState(1)
const [totalPage, setTotalPage] = useState(0)
const [perPage, setPerPage] = useState(5)

let {examId,stdName} = useParams()



const handleGetResultDetail = async (pageNo) => {

  setIsLoading(true)
  let data = JSON.stringify({
    exam_id:examId,
  })

  await getResultDetail(data)
    .then((response) => {
      setIsLoading(false)
    
      if (response.data.code != 206) {
        setResultData(response.data.data)
      } else {
        toastError('Content not available')
        setResultData('')
      }
    })
    .catch((err) => {
  
      setIsLoading(false)
    })
}
const navigate=useNavigate();

const handleNavigateToBack = async () => {

  // navigate(`/results/${(window.localStorage.getItem('userType')===1)?'admin':'std'}`);
if(window.localStorage.getItem('userType')==1){
  navigate(`/results/admin`);
}else{
  navigate(`/results/std`);

}

}

useEffect(() => {
  handleGetResultDetail(page)
}, [])



  return (
    <>
    <div class="row">
      <h4>{stdName} Result Detail </h4>
      <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button
            className='btn btn-sm btn-primary'
            type='button'
            onClick={handleNavigateToBack}
          >
        Back
          </button>
        </div>
    </div>
    

       
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>

            <div class='card'>
 
           
            
              </div>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>

              

            
                </div>
              </div>

      
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>QUESTION</th>
                    <th className='text-end'>CHOOSED  ANS</th>
                    <th className='text-end'>STATUS</th>
                    <th className='text-end'>CORRECT</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
      
               
                {resultData
                    ? resultData.map((item) => {
                          return (

                            <tr>
                            <td className='min-w-125px'>{item.translations.q_content}</td>

                            <td className='text-end'> 
                            
                            {
                              item.choosedOption=='a'? 
                              item.translations.opt_a:''

                            }
                            {
                              item.choosedOption=='b'? 
                              item.translations.opt_b:''

                            }
                            {
                              item.choosedOption=='c'? 
                              item.translations.opt_c:''

                            }
                            </td>
                            <td className='text-end'>
                            {
                              item.translations.question.correct_opt== item.choosedOption? 
                              <span className='badge badge-light-success'>Correct</span>
                              :
                              <span className='badge badge-light-danger'>Wrong</span>

                            }
                            </td>


                            <td className='text-end'>
                            {
                              item.translations.question.correct_opt=='a'? 
                              item.translations.opt_a:''

                            }
                            {
                              item.translations.question.correct_opt=='b'? 
                              item.translations.opt_b:''

                            }
                            {
                              item.translations.question.correct_opt=='c'? 
                              item.translations.opt_c:''

                            }
                            </td>
                        
                    
                            {/* <td className='text-end'>
                            {item.correct >= item.exam.config.correct_ans?
                            <span className='badge badge-light-success'>Pass</span>
                            :
                            <span className='badge badge-light-danger'>Fail</span>
                            }
                            </td> */}
                        

                            </tr>

                            )
                        })
                    : ''}
           
                 
                </tbody>
              </table>

            
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewResults
