/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import StdAreaModal from '../../../../components/modals/StdAreaModal'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  courseName:string
  totalQ:number
}

const MixedWidget7: React.FC<Props> = ({className, chartColor, chartHeight,courseName,totalQ}) => {
  
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,totalQ))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <>
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Standard Questions</span>
          <span className='text-muted fw-bold fs-7'> {totalQ} Question For {courseName} </span>
        </h3>

        <div className='card-toolbar'>
     
        </div>
      </div>

      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>

        <div className='pt-5'>
          {/* <p className='text-center fs-6 pb-5 '>
            <span className='badge badge-light-danger fs-8'>Some:</span>&nbsp; note for students
            <br />
            to approve newly amended polices
          </p> */}

          <a    data-bs-toggle='modal'  data-bs-target='#kt_modal_create_app'  className={`btn btn-${chartColor} w-100 py-3`}>
            Start Now
          </a>
        </div>
      </div>
      {/* end::Body */}
    </div>
    <StdAreaModal attemptNumber="0"/>
    </>
  )
}

const chartOptions = (chartColor: string, chartHeight: string,totalQ:number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')
  const totalQPerc = totalQ

  return {
    series: [100],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {MixedWidget7}
