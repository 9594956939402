import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-bootstrap'
import { importLocalization } from './LocaleFile'
import { useParams } from 'react-router-dom'
import defaultLocale from '../../lang/en/homepage.json'


const LanguageTranslation = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [localizationData, setLocalizationData] = useState([]);

    const [formData, setFormData] = useState({});

    let {langSlug} = useParams();
    const [locale, setLocale] = useState(langSlug);

    const handleImportLocaleFn = async (locale) => {
      
        await importLocalization(locale)
          .then((response) => {
            setLocalizationData(response);
    
          })
          .catch((err) => {
            console.log(err)
          })
      }



      

    useEffect(() => {
        handleImportLocaleFn(locale);
        
      }, [locale]);

      
      useEffect(() => {
        setFormData(localizationData);
      }, [localizationData]);


        
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };


      const handleFormSubmit = async (e) => {
        e.preventDefault()
        console.log('formData',formData);

        // fs.writeFile(`../../lang/${locale}/homepage.json`, JSON.stringify(formData), (err) => {
        //     if (err) console.log('Error writing file:', err);
        // })
     
  
    
      }
    


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <ToastContainer />

        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h6 className='fw-bolder m-0'>Language Translation</h6>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form className='form' onSubmit={handleFormSubmit}>

            <div className='card-body border-top p-9'>
        

       {/* {localizationData? Object.entries(localizationData).map(([key,value]) => (
              <div className='row mb-6'>
              
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>{defaultLocale[key]}</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder={value}
                   value={value} 
                   
                  />
                </div>
             
              </div>

)):'Locale file not exist'}  */}



{Object.keys(formData).map((key) => (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>{defaultLocale[key]}</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name={key}
                    id={key}
                    placeholder={key}
                    value={formData[key] || ''}
                   onChange={handleInputChange}
                   
                  />
                </div>
             
              </div>

))} 

    
        
            </div>
            {localizationData?
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' class='btn btn-primary'>
                {isLoading ? (
                  <div>
                    <i className='fa fa-spinner fa-spin'></i>
                  </div>
                ) : (
                  'Save Changes'
                )}
              </button>
            </div>
            :''}
          </form>
        </div>
      </div>
    </>
  )
}

export default LanguageTranslation