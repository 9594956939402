import React from 'react'

const ExamViseoSection = (props) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const { qData,handleVideoEnded,index } = props;

  return (
    <>
     <div className='VideoSection' style={{height: '100%',marginTop: '100px'}}>
     <div className='video-container '>
        {qData.length > 0 &&  qData[index].q_video ? (
       

         <video  autoplay="autoplay" controls  onEnded={handleVideoEnded} >
                  <source src={imageUrl + qData[index].q_video} /> 
                 
                  </video>
         
       ) : (
         ''
       )}


   
   
 
     </div>
      </div>
    </>
  )
}

export default ExamViseoSection
