
import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {RootState} from '../../setup'
import {App} from '../App'
import StdLogin from '../modules/auth/components/StdLogin'
import StdDashboard from '../../components/std-module/StdDashboard'
import { UserModel } from '../modules/auth/models/UserModel'
import AdminRoutes from './AdminRoutes'




const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
   
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthorized ? (
            <>
               <Route path='/*' element={<PrivateRoutes />} />
              {/* <Route path='/*' element={<AdminRoutes />} /> */}
              <Route index element={<Navigate to='/dashboard' />} />
              
            </>
          ) : (
            <>
            <Route path='std-login/*' element={<StdLogin/>}/>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
