import React from 'react'
import { useTranslation } from 'react-i18next';

const ExamFooter = (props) => {
  const { t, i18n } = useTranslation();

  const {handleNextPrevClick,isShowBtnFinish,index,handelExit,direction,reviewStack} = props;

  return (
    <>
      <div className="footer py-4 d-flex flex-lg-column" id="kt_footer" dir={direction==2?'rtl':'ltr'}>
       <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
    
       
      <button className="btn btn-success" style={{ visibility :index == 0 || reviewStack && reviewStack[0]==index ? 'hidden':''}} onClick={() => { handleNextPrevClick('prev') }}>
    
      { direction==1?  
      <><i className='fa fa-angle-double-left' aria-hidden='true'></i><span>{t('btn_prev')} </span></>:
      <><i className='fa fa-angle-double-right' aria-hidden='true'></i> <span>{t('btn_prev')} </span> </>
      }
      
      </button>
     
      {!isShowBtnFinish ? (
      <button className="btn btn-success"  
      onClick={() => { handleNextPrevClick('next') }}>
     { direction==1?
        <> <span>{t('btn_next')}</span> <i className='fa fa-angle-double-right' aria-hidden='true'></i> </>
      : <><span>{t('btn_next')}</span><i className='fa fa-angle-double-left' aria-hidden='true'></i></> }
      </button> 
      
    
        
      ):
      <button className="btn btn-danger"  onClick={() =>
       { 
        handelExit()
         }}>
         {t('btn_finish')}
          {/* { direction==1?"Finish":"ختم" } */}
         </button> 
      }             
   </div>
</div>
    </>
  )
}

export default ExamFooter
