import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getAllUsers= async (pageNo)=>{
  
    var config = {
      method: 'get',
      url: `${API_URL}/get-all-users?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
       'Content-Type': 'application/json',
     },
    };

  const response=await axios(config)
  return response;
}



export const createUsers = async (data) => {


  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/create-user`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


export const deleteUser= async (data)=>{

  var config = {
    method: 'delete',
    url: `${API_URL}/delete-user`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const editUser= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/edit-user`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}

export const updateUser= async (data)=>{
  var config = {
    method: 'post',
    url: `${API_URL}/update-user`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const getInvigilatorForDropdown= async ()=>{
  
  var config = {
    method: 'get',
    url: `${API_URL}/get-invigilator`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
  };

const response=await axios(config)
return response;
}





