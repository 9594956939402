import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {KTSVG} from '../../_metronic/helpers'
import { editLang, getAllLang } from '../apis/LanguageApi';
import { toastError } from '../global/Global';
import RtlPracticeExam from './exam/RtlPracticeExam';
import { saveAttempt } from '../apis/QuestionsBankApi';
import '../../components/assets/styles/css/style.css'
import StdArea from './std-area/StdArea';
import StdAreaModal from '../modals/StdAreaModal';
import { getStdExamInfo, isScheduleExam } from '../apis/ExamApi';
import ActualExamStdModal from '../modals/ActualExamStdModal';
import { useTranslation } from 'react-i18next';

const customStyle = {

  mainSection: {
    background: "#33B8FF",
     height: "380px",
     marginLeft: "-10px",
     marginRight: "-10px",
     borderRadius: "5px"
 },

welcomeSection: {
    textAlign:" center",
    paddingTop: "30px",
    color:"white",
    fontFamily:"system-ui",
},
imgSection: {
    textAlign: "center"
},
imgSectionImg: {
    maxWidth:"15%",
    textAlign:"center"
},

buttonSection: {
    textAlign:"center",
    marginTop:"15px",
  
}
};  


const StdDashboard = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [langList, setLangList] = useState([])
  const [langId, setLangId] = useState('')
  const [audioLang, setAudioLang] = useState('')
  const [langDir, setLangDir] = useState('')
  const [stdName, setStdName] = useState('')
  const [stdId, setStdId] = useState('')
  const [isSchedleExam, setIsSchedleExam] = useState(false)


  const { i18n } = useTranslation();

  const handleLanguage = (lang) => { 
      i18n.changeLanguage(lang);
  }
 


  const navigate=useNavigate();

  const getAllLangFunc = async (pageNo) => {
    await getAllLang(pageNo)
      .then((response) => {
        //  console.log(response.data.data.data)
        setLangList(response.data.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const getExamInfo = async (pageNo) => {

    let data = JSON.stringify({
      std_id:window.localStorage.getItem('stdId'),
    })
  
    await getStdExamInfo(data)
      .then((response) => {
        console.log('exam schedule',response);

    if(response.data.success){
        setIsSchedleExam(true);
    }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }



  const getStdInfo = () =>{
    setStdName(window.localStorage.getItem('stdName'));
    // setStdName(sessionStorage.getItem('stdName'));
    setStdId(window.localStorage.getItem('stdId'));
}


  useEffect(() => {
    getStdInfo();
    getAllLangFunc(1);
    getExamInfo();


  }, []  )



  const modalCloseEvent=()=>{
    setLangId('');
    setAudioLang('');
    }


    const handleAttempt = async (e) => {

      setIsLoading(true)
      let data = JSON.stringify({
        std_id: stdId,
      })
      
      await saveAttempt(data)
        .then((response) => {
          setIsLoading(false)
          // console.log(response)

        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      setIsLoading(true)
      let data = JSON.stringify({
        id: langId,
      })
      
      await editLang(data)
        .then((response) => {
          setIsLoading(false)
           console.log(response.data.data.lang_short)
  
          if (response.data.code == 200) {
            handleLanguage(response.data.data.lang_short)
            handleAttempt();
          setLangDir(response.data.data.direction);
          if(response.data.data.direction=='LTR'){
          navigate(`/practice-test/${langId}/${audioLang}`)
          }else{
         
            navigate(`/rtl-exam/${langId}/${audioLang}`)
          }
  
          }
          if (response.data.error) {
            setLangDir(response.data.data.direction);
            toastError('Content not available')
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }

  



  return (
    <>
    <h2>Dashboard</h2>
      <main class='flex-shrink-0'>
        <div class='mt-3' style={customStyle.mainSection}>
          <div  style={customStyle.welcomeSection}>
            <h4 style={{color:"#ffff"}}>
              <span> Welcome to SDC</span>
            </h4>
            <h3 style={{color:"#ffff"}}>
              <span><b>{stdName}</b></span>
            </h3>
          </div>

          <div style={customStyle.imgSection}>
            <img src='/media/illustrations/sketchy-1/4.png'  alt='No Image' className='mw-100 mh-150px mb-7'/>
                              
          </div>

          <div style={customStyle.buttonSection}>
              <button className='btn btn-sm btn-primary' style={{marginRight:"10px"}}
             onClick={() => {  navigate('/exam-type'); }}                       
            > Practice Test</button>
            {isSchedleExam?

  <button className='btn btn-sm btn-primary'  data-bs-toggle='modal'  data-bs-target='#actualExamModal' > Start Exam</button>
  :
  
  <button className='btn btn-sm btn-primary'>Exam not schedule</button>
            }

           
          </div>
        </div>
      </main>

      <div
        className='modal '
        id='kt_modal_create_app'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-close'
                data-bs-dismiss='modal'
                onClick={()=> modalCloseEvent()}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
       
              <form
            
                     class='form w-100'
                    novalidate='novalidate'
                    id='kt_sign_in_form'
                    onSubmit={handleSubmit}
                  >

                    <div class='text-center mb-10'>
                      <h1 class='text-dark mb-3'>Choose Desired Options</h1>
                    </div>

                    <div class='fv-row mb-10'>
                      <div class='d-flex flex-stack mb-2'>
                        <label class='form-label fw-bolder text-dark fs-6 mb-0'>
                          Screen Text Language
                        </label>
                      </div>
                      <select
                        name='langText'
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) =>{ 
                          setLangId(e.target.value);
                          }}
                      >
                        <option value=''>Choose Language</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='fv-row mb-10'>
                      <label class='form-label fs-6 fw-bolder text-dark'>Audio Language</label>
                      <select
                        name='langAudio'
                        id=''
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) => setAudioLang(e.target.value)}
                      >
                        <option value=''>Choose Language</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        class='btn btn-lg btn-primary w-100 mb-5'
                      >
                         {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                      </button>
                    </div>
                  </form>

        
            </div>
          </div>
        </div>
      </div>
      <ActualExamStdModal/>
    
    </>
    
  )
}

export default StdDashboard
