import React from 'react'
import { useTranslation } from 'react-i18next';



const Header = (props) => {
  const { objectProps,handelExit,timeProps,direction } = props;

  const { t, i18n } = useTranslation();



  return (
    <>
      <div id='kt_header' style={{left:'0'}} className='header align-items-stretch' dir={direction==2?'rtl':'ltr'}>
        <div className='container-fluid d-flex align-items-stretch justify-content-between'>
        {objectProps.examType!=1?
          <div className='d-flex align-items-center me-1 me-lg-3'>
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px' id='kt_activities_toggle'  onClick={() => {
                handelExit()
              }}>
            <i className='fa fa-power-off'></i>  
            </div>
          </div>
          :''}
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <div
                className='header-menu align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode='prepend'
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                <h1 className='d-flex align-items-center text-dark fw-bolder fs-3 my-1'>
                  {objectProps.examTitle}
                  <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                  <small className='text-muted fs-7 fw-bold my-1 ms-1'>{objectProps.name}</small>
                </h1>
              </div>
            </div>
            <div className='d-flex align-items-stretch flex-shrink-0'>
              <div className='d-flex align-items-stretch flex-shrink-0'>
                <div className='d-flex flex-column align-items-stretch ms-1 ms-lg-3'>

                {objectProps.examType==1?

                  <div className='progress mt-4' style={{width: '300px'}}>
                    <div
                      className='progress-bar bg-success'
                      role='progressbar'
                      style={{width: `${timeProps.progress}%` }}
                      aria-valuenow='75'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    >
                      {Math.ceil(timeProps.progress)}%
                    </div>
                  </div>

                  :''}

                  <div className='progress mt-4' style={{width: '300px'}}>
                    <div
                      className='progress-bar bg-warning'
                      role='progressbar'
                      style={{width: `${objectProps.percntage}%` }}
                      
                      aria-valuenow='75'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    >{  Math.ceil(objectProps.percntage)}%
                     
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column align-items-stretch ms-1 ms-lg-3'>
                {objectProps.examType==1?(
                
                   timeProps.timeRemaining > 0 ?
                  <span className='text-muted mt-3'>{t('time_remaining')} - {timeProps.formatTime(timeProps.timeRemaining)}</span>
                  :<span className='text-muted mt-3'>{t('time_up')}</span>
               
                  ):''}
                  <span className='text-muted mt-2'>{t('question')} - {objectProps.index+1} / {objectProps.totalQuestion}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
