import React from 'react'
import ProgressBar from '../../partials/ProgressBar'

const ExamHeader = (props) => {

	const { objectProp } = props;

  return (
    <>
    <nav className="navbar navbar-expand navbar-dark bg-warning" style={{height:"90px" ,backgroundColor: "#ff9900"}}>
    <div className="container-fluid">
     <h4 className='bold'> {objectProp.testType}- {objectProp.name}</h4>
    

  							<div className="d-flex align-items-stretch flex-shrink-0">
							<div className="d-flex align-items-stretch flex-shrink-0">
								<div className="d-flex flex-column align-items-stretch ms-1 ms-lg-3">
									<div className="progress mt-4" style={{width:"300px"}}>
										<div className="progress-bar bg-success" role="progressbar" style={{width: "15%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">15%</div>
									</div>
									<div className="progress mt-4" style={{width:"300px"}}>
										<div className="progress-bar bg-primary" role="progressbar" style={{ width: `${objectProp.percntage}%` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">{objectProp.percntage}%</div>
									</div>
								</div>
								<div className="d-flex flex-column align-items-stretch ms-1 ms-lg-3">
									<span className="text mt-3">Time Remaining - 35:00</span><span class="text mt-2">Questions - {objectProp.index+1} / {objectProp.totalQuestion}</span>
								</div>
							</div>
						</div>

    </div>
  </nav>
</>
  )
}

export default ExamHeader
