import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()

  const userImage: string | null  =window.localStorage.getItem('userImage');

  return (

    <div className='d-flex align-items-stretch flex-shrink-0'>

      <div
     
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >

        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
      
          {
              userImage?

            <img  src={process.env.REACT_APP_IMAGE_URL + userImage} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
        </div>
        <HeaderUserMenu />

      </div>
  
    </div>
  )
}

export {Topbar}
