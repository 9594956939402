import React, { useState, useEffect } from 'react';
import { Howl } from 'howler';

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [sound, setSound] = useState(null);

  useEffect(() => {
    const audio = new Howl({
      src: ['http://localhost/sweden-api/storage/app/public/uploads/q-audios/ur.mp3'], // Replace with your audio file path
      html5: true, // Use HTML5 audio
      onend: () => {
        setIsPlaying(false);
      },
    });
    setSound(audio);

    return () => {
      // Cleanup on unmount
      audio.unload();
    };
  }, []);

  const handleTogglePlay = () => {
    if (isPlaying) {
      sound.pause();
    } else {
      sound.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <button onClick={handleTogglePlay}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
    </div>
  );
};

export default AudioPlayer;
