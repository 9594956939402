import React, {useState, useEffect} from 'react'
import car from '../../assets/img/car.jpg'
import {getAllLang} from '../../apis/LanguageApi'
import {useNavigate} from 'react-router-dom'

const StdArea = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [langList, setLangList] = useState([])
  const [landId, setLangId] = useState('')
  const [audioLang, setAudioLang] = useState('')
  const navigate = useNavigate()

  const getAllLangFunc = async (pageNo) => {
    await getAllLang(pageNo)
      .then((response) => {
        // console.log(response.data.data.data)
        setLangList(response.data.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    navigate(`/practice-test/${landId}/${audioLang}`)
  }

  useEffect(() => {
    getAllLangFunc(1)
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-root' style={{overflowX: 'hidden'}}>
        <div
          className='d-flex flex-column flex-column-fluid position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url(${car})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
          }}
        >
          <div className='row '>
            <div className='col-md-7'> </div>
            <div className='col-md-5 overflow-hidden'>
              <div className='d-flex p-15 pb-lg-20'>
                <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 ml-auto'>
                  <form
                    class='form w-100'
                    novalidate='novalidate'
                    id='kt_sign_in_form'
                    onSubmit={handleSubmit}
                  >
                    <div class='text-center mb-10'>
                      <h1 class='text-dark mb-3'>STUDENT AREA</h1>
                    </div>

                    <div class='fv-row mb-10'>
                      <div class='d-flex flex-stack mb-2'>
                        <label class='form-label fw-bolder text-dark fs-6 mb-0'>
                          Screen Text Language
                        </label>
                      </div>
                      <select
                        name='langText'
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) => setLangId(e.target.value)}
                      >
                        <option value=''>Choose Language</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='fv-row mb-10'>
                      <label class='form-label fs-6 fw-bolder text-dark'>Audio Language</label>
                      <select
                        name='langAudio'
                        id=''
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) => setAudioLang(e.target.value)}
                      >
                        <option value=''>Choose Language</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        class='btn btn-lg btn-primary w-100 mb-5'
                      >
                        Submit{' '}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StdArea
