import React, {useEffect, useRef, useState} from 'react'
import Header from './Header'
import ExamFooter from './ExamFooter'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  getQuestionsForExam,
  saveActualExam,
  saveMcqsForActualExam,
  saveSolvedQuestions,
} from '../../../apis/QuestionsBankApi'
import {toastError} from '../../../global/Global'
import ExamViseoSection from './ExamViseoSection'
import ReviewSection from '../ReviewSection'
import ExamReviewSection from './ExamReviewSection'
import { useTranslation } from 'react-i18next'

import { Howl } from 'howler';

const ExamPage = () => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate()
  let {attempt, langId, audioLang, testType, direction} = useParams()

  const timeUpButton = useRef(null)



  const [isLoading, setIsLoading] = useState(false)
  const [qData, setQData] = useState([])
  const [index, setIndex] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [questionPerc, setQuestionPerc] = useState(0)
  const [ans, setAns] = useState(2)
  const [isShowRevSection, setIsShowRevSection] = useState(false)

  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const [isPlaying, setIsPlaying] = useState(false)

  const [isHighlightedQAudio, setIsHighlightedQAudio] = useState(false)
  const [isHighlightedOptA, setIsHighlightedOptA] = useState(false)
  const [isHighlightedOptB, setIsHighlightedOptB] = useState(false)
  const [isHighlightedOptC, setIsHighlightedOptC] = useState(false)
  const [stdName, setStdName] = useState('')
  const [stdId, setStdId] = useState('')
  const [count, setCount] = useState(0)
  const [isShowMcqsSection, setIsShowMcqsSection] = useState(true)

  const [isVidSecShow, setIsVidSecShow] = useState(0)
  const [reviewStack, setReviewStack] = useState([])
  const [stackCounter, setStackCounter] = useState(0)
  const [isShowBtnFinish, setIsShowBtnFinish] = useState(false)
  const [markMcqs, setMarkMcqs] = useState([])
  const [standardTime, setStandardTime] = useState('')
  const [courseTitle, setCourseTitle] = useState('')
  const [isActiveReviewMarkedOnly, setIsActiveReviewMarkedOnly] = useState(true)
  const [examId, setExamId] = useState('')
  const [examInitialTime, setExamInitialTime] = useState('')
  const [firstAudioSound, setFirstAudioSound] = useState('')

  var initialTime = ''
  const [timeRemaining, setTimeRemaining] = useState(60)
  const [progress, setProgress] = useState(100)

  const [isAudioExist, setIsAudioExist] = useState(false)

  const [imageStyleWithDir, setImageStyleWithDir] = useState('right top')

  const [selectedOptions, setSelectedOptions] = useState([
    {
      optA: '',
      optB: '',
      optC: '',
      correctAns: '',
      isDisable: false,
      qId: '',
    },
  ])

  const qAudioRef = React.createRef()
  const optAAudioRef = React.createRef()
  const optBAudioRef = React.createRef()
  const optCAudioRef = React.createRef()

  const getStdInfo = () => {
    setStdName(window.localStorage.getItem('stdName'))
    setStdId(window.localStorage.getItem('stdId'))

  }

  const handleNextPrevClick = (type) => { 
    
    if (type == 'next' && index < qData.length - 1) {
      

      handleAutoPlayAudio('q',index+1);

      if (reviewStack.length > 0) {
        if (reviewStack[stackCounter]) {
          setIndex(reviewStack[stackCounter])
          setStackCounter(stackCounter + 1)
          handleVideQIndex(reviewStack[stackCounter])

          if (reviewStack.length == stackCounter + 1) {
            setIsShowBtnFinish(true)
          }
        } else {
          setIsShowBtnFinish(true)
        }
      } else {
        setIndex(index + 1)
        handleVideQIndex(index + 1)

        if (index + 1 == totalQuestion - 1) {
          setIsShowBtnFinish(true)
        }
      }
    }
    if (type == 'prev' && index > 0) {

      handleAutoPlayAudio('q',index-1);
      
      if (reviewStack.length > 0) {
        const prevCounter = stackCounter - 2

        if (reviewStack.length > stackCounter - 1) {
          setIsShowBtnFinish(false)
        }

        setIndex(reviewStack[prevCounter])

        setStackCounter(stackCounter - 1)
      } else {
        setIsShowBtnFinish(false)
        setIndex(index - 1)
      }
    }
  }

  const handleGetQuestionForExam = async () => {
    setIsLoading(true)

    let data = JSON.stringify({
      lang_id: langId,
      audioLangId: audioLang,
      std_id: window.localStorage.getItem('stdId'),
      testType: testType,
      attempt: attempt,
      q_type: langId == 0 ? 2 : 1,
    })


    await getQuestionsForExam(data)
      .then((response) => {
  

        if (response.data.success) {

          setExamId(response.data.data.examId)
          setExamInitialTime(response.data.data.standardTime * 60)
          setTimeRemaining(response.data.data.standardTime * 60)

          if (response.data.data.examQuestions.length == 1) {
            // console.log('1 length');
            setIsShowBtnFinish(true)
          }

          if (response.data.data.examQuestions[0].q_video != null) {
            setIsVidSecShow(1)
            setIsShowMcqsSection(false)
          } else {
            setIsVidSecShow(0)
            setIsShowMcqsSection(true)
          }

          

          setCourseTitle(response.data.data.courseName)
          setStandardTime(response.data.data.standardTime)
          setQData(response.data.data.examQuestions)
          setTotalQuestion(response.data.data.examQuestions.length)
          setIsLoading(false)

          if(audioLang > 0 && response.data.data.examQuestions[0] && response.data.data.examQuestions[0].q_lang_audio.q_audio){
              handleFirstLoadAudio(response.data.data.examQuestions[0].q_lang_audio.q_audio,1);
            
          }

        }

        if(response.data.success === false){
          setQData([])
          toastError(response.data.message);
          navigate('/exam-type')
          
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }


 
  const handleFirstLoadAudio =  (audioSrc,isPlay) => {
    
        const sound = new Howl({
          src: [imageUrl + audioSrc],
          html5: true,
          onend: () => {
            setIsHighlightedQAudio(false)
          },
        });

        setFirstAudioSound(sound);

  
        if(isPlay===1){
          setIsHighlightedQAudio(true)
          sound.play();
        }
    

  
  }

  const handlePauseFirstAudio =  () => {
   if(audioLang > 0){
    firstAudioSound.pause();
   }

}



  const handleAutoPlayAudio = async (type,indexNo) => {

     if (type == 'q' && qData[indexNo].q_lang_audio && qData[indexNo].q_lang_audio.q_audio) {
      handlePauseFirstAudio();
        setIsHighlightedQAudio(true)
        optAAudioRef.current.pause()
        optBAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedOptA(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(false)
        qAudioRef.current.load()
        qAudioRef.current.play()
      
    }
    // handlePlayAudio('q',index);
  }
  

  const handleChangeRadioOptions = (index, property, value, qId) => {

    value && value === qData[index].correct_opt ? setAns(1) : setAns(0)

    const newArray = [...selectedOptions]
    const updatedObject = {...newArray[index]}

    updatedObject['isDisable'] = true
    updatedObject['correctAns'] = value
    updatedObject['qId'] = qId //e && value === qData[index].correct_opt ? 1 : 0

    if (value == 'a' && property == 'optA') {
      updatedObject[property] = value
      updatedObject['optB'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'b' && property == 'optB') {
      updatedObject[property] = value
      updatedObject['optA'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'c' && property == 'optC') {
      updatedObject[property] = value
      updatedObject['optA'] = ''
      updatedObject['optB'] = ''
      newArray[index] = updatedObject
    }

    setSelectedOptions(newArray)
    
    if (testType == 2) {
      handleSubmit(qData[index].id, value && value === qData[index].correct_opt ? 1 : 0, value)
    }
  }
  
  const handleSubmit = async (qId, optAns, choosedValue) => {
    setIsLoading(true)
    let data = JSON.stringify({
      std_id: stdId,
      q_id: qId,
      ans: optAns,
      exam_type: testType,
      q_type: langId == 0 ? 2 : 1,
      attempt: attempt,
      choosedOption: choosedValue,
    })

    await saveSolvedQuestions(data)
      .then((response) => {

        if (response.data.success == false) {
      
          // toastError(response.data.message)
        }
        setIsLoading(false)

        if (response.data.code == 201) {
        }
        if (response.data.error) {
          console.log(response.data.error)
          // toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const handlePlayAudio = (type,index) => {
  handlePauseFirstAudio();
    if (type == 'q' && qData[index].q_lang_audio && qData[index].q_lang_audio.q_audio) {

      if(!optAAudioRef.current.paused || !optBAudioRef.current.paused || !optCAudioRef.current.paused){

        optAAudioRef.current.load()
        optAAudioRef.current.pause()
        optBAudioRef.current.load()
        optBAudioRef.current.pause()
        optCAudioRef.current.load()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(true)

        setIsHighlightedOptA(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(false)
        qAudioRef.current.load()
        qAudioRef.current.play()

      }else{

   
 
      if (qAudioRef.current.paused) {

        setIsHighlightedQAudio(true)
        optAAudioRef.current.load()
        optAAudioRef.current.pause()
        optBAudioRef.current.load()
        optBAudioRef.current.pause()
        optCAudioRef.current.load()
        optCAudioRef.current.pause()
        setIsHighlightedOptA(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(false)

        qAudioRef.current.load()
        qAudioRef.current.play()
      } else {
        setIsHighlightedQAudio(false)
        qAudioRef.current.pause()
      }
    }
  }

    if (type == 'a' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_a_audio) {

      if(!qAudioRef.current.paused || !optBAudioRef.current.paused || !optCAudioRef.current.paused){

    
        qAudioRef.current.pause()
        optBAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(false)
        setIsHighlightedOptA(true)
        optAAudioRef.current.load()
        optAAudioRef.current.play()

      }else{
      if (optAAudioRef.current.paused) {
        setIsHighlightedOptA(true)
        qAudioRef.current.pause()
        optBAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(false)
        optAAudioRef.current.play()
      } else {
        
         setIsHighlightedOptA(false)
         optAAudioRef.current.pause()
      }
    }
    }



    if (type == 'b' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_b_audio) {



      if(!qAudioRef.current.paused || !optAAudioRef.current.paused || !optCAudioRef.current.paused){

        qAudioRef.current.pause()
        optAAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptA(false)
        setIsHighlightedOptC(false)
        setIsHighlightedOptB(true)
        optBAudioRef.current.load()
        optBAudioRef.current.play()

      }else{

      if (optBAudioRef.current.paused) {
        setIsHighlightedOptB(true)

        qAudioRef.current.pause()
        optAAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptA(false)
        setIsHighlightedOptC(false)

        optBAudioRef.current.play()
      } else {
        setIsHighlightedOptB(false)
        optBAudioRef.current.pause()
      }
    }
  }

    if (type == 'c' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_c_audio) {

      if(!qAudioRef.current.paused || !optAAudioRef.current.paused || !optBAudioRef.current.paused){

        qAudioRef.current.pause()
        optAAudioRef.current.pause()
        optBAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptA(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(true)
        optCAudioRef.current.load()
        optCAudioRef.current.play()

      }else{


      if (optCAudioRef.current.paused) {
        setIsHighlightedOptC(true)
        qAudioRef.current.pause()
        optAAudioRef.current.pause()
        optBAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptA(false)
        setIsHighlightedOptB(false)
        optCAudioRef.current.play()
      } else {
        setIsHighlightedOptC(false)
        optCAudioRef.current.pause()
      }
    }
  }

    setIsPlaying(!isPlaying)
  }

  const changeHighlightState = () => {
    setIsHighlightedQAudio(false)
    setIsHighlightedOptA(false)
    setIsHighlightedOptB(false)
    setIsHighlightedOptC(false)
  }

  const handleImageTyleAcordingDir = () => {
    if(direction==2){
setImageStyleWithDir('left top');
}
  }

  //audioPlayingEvent
  const audioPlayingEvent = (qType) => {
 

    if (qType == 'q') {
      setIsHighlightedQAudio(true)
    } else {
      setIsHighlightedQAudio(false)
    }
    if (qType == 'a') {
      setIsHighlightedOptA(true)
    } else {
      setIsHighlightedOptA(false)
    }
    if (qType == 'b') {
      setIsHighlightedOptB(true)
    } else {
      setIsHighlightedOptB(false)
    }
    if (qType == 'c') {
      setIsHighlightedOptC(true)
    } else {
      setIsHighlightedOptC(false)
    }
  }

  const handleVideoEnded = () => {
    setIsVidSecShow(0)
    setIsShowMcqsSection(true)
  }

  const handleVideQIndex = (indexVal) => {
    if (qData.length > 0 && qData[indexVal] && qData[indexVal].q_video != null) {
      setIsVidSecShow(1)
      setIsShowMcqsSection(false)
    } else {
      setIsVidSecShow(0)
      setIsShowMcqsSection(true)
    }
  }

  const handelExit = () => {
    if (testType == 2) {
     

      if (questionPerc < 100) {
        Swal.fire({
          text: t('pop_exit_text_uncomplete'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F1416C',
          cancelButtonColor: '#181C32',
          confirmButtonText: t('btn_pop_exit'),
          cancelButtonText: t('btn_pop_exit_cancel'),
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/exam-type')
          }
        })
      }

      if (questionPerc == 100) {
        Swal.fire({
          text: t('pop_exit_text_complete'),
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#F1416C',
          cancelButtonColor: '#181C32',
          confirmButtonText: t('btn_pop_exit'),
          cancelButtonText: t('btn_pop_exit_cancel'),
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/std-dashboard')
          }
        })
      }
    } else {
      setIsShowRevSection(true)
      setReviewStack([])
      setIsShowBtnFinish(false)
      setIsShowMcqsSection(false)
    }
  }

  const handleEndReview = () => {
    Swal.fire({
      text: t('handle_end_review_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText:t('btn_pop_exit'),
      cancelButtonText: t('btn_pop_exit_cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        handleActualExamSubmit()
      }
    })
  }

  const handleActualExamSubmit = async () => {


    setIsLoading(true)
    let data = JSON.stringify({
      std_id: stdId,
      selectedOptions: selectedOptions,
      qData: qData,
      examId: examId,
      exam_lang_id:langId,
      audioLang:audioLang,
      examDuration:timeRemaining,
    })

    await saveActualExam(data)
      .then((response) => {
        console.log(response);
        setIsLoading(false)
        if (response.data.success) {
            navigate(`/print-result/${response.data.data.exam_id}`)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  //handleReviewAll
  const handleReview = (type) => {
    //review all
    if (type == 1) {
      handleReviewStackStates()
      setIndex(0)
    }

    //review unanswerd
    if (type == 2) {
      if (markMcqs.length != qData.length) {
        handleReviewStackStates()
        const nonMatched = []
        for (let i = 0; i < qData.length; i++) {
          const index = markMcqs.indexOf(i)
          if (index === -1) {
            nonMatched.push(i)
          }
        }

        setReviewStack(nonMatched)

        handleVideQIndex(nonMatched[0])
        setIndex(nonMatched[0])
        setStackCounter(1)
        if (nonMatched.length == 1) {
          setIsShowBtnFinish(true)
        }
      } else {
        toastError('All questions are attempted')
      }
    }

    //review marked only
    if (type == 3) {
      if (reviewStack.length > 0) {
        handleReviewStackStates()
        handleVideQIndex(reviewStack[0])
        setIndex(reviewStack[0])
        setStackCounter(1)
        if (reviewStack.length == 1) {
          setIsShowBtnFinish(true)
        }
      } else {
        toastError('Select questions please')
      }
    }
  }

  const handleReviewStackStates = () => {
    setIsShowRevSection(false)
    setIsShowBtnFinish(false)
    setIsShowMcqsSection(true)
    setStackCounter(0)
    // handleShowFinishButton();
  }

  const getEmptyIndices = () => {
    const emptyIndices = selectedOptions.reduce((acc, value, index) => {
      if (value === '') {
        acc.push(index)
      }
      return acc
    }, [])
    return emptyIndices
  }

  const handleReviewMarkedOnly = (e, paramIndex) => {
    if (e.target.checked) {
      if (reviewStack.indexOf(paramIndex) === -1) {
        const updatedArray = [...reviewStack]
        updatedArray[reviewStack.length] = paramIndex
        setReviewStack(updatedArray)
      }
    } else {
      
      const updatedStack = [...reviewStack]
      updatedStack.pop()
      setReviewStack(updatedStack)
    }
  }

  const handleMarkedMcqs = (paramIndex) => {
    if (!markMcqs.includes(paramIndex)) {
      setMarkMcqs([...markMcqs, paramIndex])
    }

  }

  const headerProp = {
    name: stdName,
    percntage: questionPerc,
    totalQuestion: totalQuestion,
    index: index,
    examType: testType,
    examTitle: testType == 1 ? courseTitle : t('test_type'),
  }

  const handleTimeUp = () => {

    // toastError(t('time_up_message'));
    // handleActualExamSubmit();

    Swal.fire({
      text: t('time_up_message'),
      icon: 'warning',
      allowOutsideClick:false,
      showCancelButton: false,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: t('btn_ok'),
    }).then((result) => {

      if (result.isConfirmed) {
        handleActualExamSubmit()
    

      }
    })
  }

  useEffect(() => {
    getStdInfo()
    handleGetQuestionForExam()
    handleImageTyleAcordingDir();
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    const calculateProgress = () => {
      const remainingPercentage = (timeRemaining / examInitialTime) * 100
      setProgress(remainingPercentage)
    }

    calculateProgress()
  }, [timeRemaining, examInitialTime])

  useEffect(() => {
    const calculatePercentage = () => {
      if(totalQuestion > 0){
      const calc = (markMcqs.length * 100) / parseFloat(totalQuestion)
      setQuestionPerc(calc)
    }
    }

    calculatePercentage()
  }, [markMcqs])

  // Format the time in hh:mm:ss
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  const timeProps = {
    timeRemaining: timeRemaining,
    formatTime: formatTime,
    progress: progress,
  }

  useEffect(() => {
    if (timeRemaining === 0) {
      if(testType==1){
        handleTimeUp()
      }
     
    }
  }, [timeRemaining])



  return (
    <>
      <Header
        objectProps={headerProp}
        handelExit={handelExit}
        timeProps={timeProps}
        direction={direction}
      />

      {isShowMcqsSection ? (
        <div
          className='ExamBodySection'
          style={{height: '100%'}}
          dir={direction == 2 ? 'rtl' : 'ltr'}
        >
          <div
            className='content d-flex flex-column flex-column-fluid'
            id='kt_content'
            style={{marginTop: '100px'}}
          >
            <div></div>

            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div className='col-md-7'>
                <div id='kt_content_container' className='container px-0 mx-20'>
                  <div className='full-modal-body'>
                    <div className='question-text'>
                      <span
                        className='question-text fs-1 cursor'
                        style={{
                          margin: '0px',
                          fontSize: '20px',
                          fontWeight: '500',
                          color: isHighlightedQAudio ? 'blue' : '',
                        }}
                        onClick={() => {
                          handlePlayAudio('q', index)
                        }}
                      >
                        {qData.length > 0 ? qData[index].q_with_lang.q_content : ''}
                      </span>
                    </div>

                    <div className='question-choice-container mt-10'>
                      <table className='table-borderless'>
                        <tbody>
                          {qData.length > 0 &&
                          qData[index].q_lang_audio &&
                          qData[index].q_lang_audio.q_audio ? (
                            <audio
                              ref={qAudioRef}
                              onEnded={changeHighlightState}
                              onPlaying={() => {
                                audioPlayingEvent('q')
                              }}
                            >
                     
                                <source
                                src={imageUrl + qData[index].q_lang_audio.q_audio}
                                type='audio/mpeg'
                              />
                              
                            </audio>
                          ) : (
                            ''
                          )}
                          {qData.length > 0 &&
                          qData[index].q_lang_audio &&
                          qData[index].q_lang_audio.opt_a_audio ? (
                            <audio ref={optAAudioRef} onEnded={changeHighlightState}>
                              <source
                                src={imageUrl + qData[index].q_lang_audio.opt_a_audio}
                                type='audio/mpeg'
                              />
                            </audio>
                          ) : (
                            ''
                          )}
                          {qData.length > 0 &&
                          qData[index].q_lang_audio &&
                          qData[index].q_lang_audio.opt_b_audio ? (
                            <audio ref={optBAudioRef} onEnded={changeHighlightState}>
                              <source
                                src={imageUrl + qData[index].q_lang_audio.opt_b_audio}
                                type='audio/mpeg'
                              />
                            </audio>
                          ) : (
                            ''
                          )}
                          {qData.length > 0 &&
                          qData[index].q_lang_audio &&
                          qData[index].q_lang_audio.opt_c_audio ? (
                            <audio ref={optCAudioRef} onEnded={changeHighlightState}>
                              <source
                                src={imageUrl + qData[index].q_lang_audio.opt_c_audio}
                                type='audio/mpeg'
                              />
                            </audio>
                          ) : (
                            ''
                          )}

                          <tr className='question-choice-item-wrapper' style={{height: '110px'}}>
                            <td
                              className={`question-choice-select-wrapper ${
                                direction == 1 ? 'pe-6' : 'ps-6'
                              }`}
                            >
                              <label
                                id='option_1'
                                for='choice-1'
                                className='normal options radio-container '
                                style={{
                                  width: '70px',
                                  height: '70px',
                                  borderRadius: '10px',
                                  background: 'aqua',
                                }}
                              >
                                <input
                                  className='inpRadio'
                                  type='radio'
                                  value='a'
                                  id='choice-1'
                                  name='opt'
                                  onChange={(e) => {
                                    handleChangeRadioOptions(index,'optA',e.target.value,qData[index].id)
                                    handleMarkedMcqs(index)
             
                                  }}
                                  checked={
                                    selectedOptions[index] && selectedOptions[index].optA === 'a'
                                      ? 'checked'
                                      : ''
                                  }
                                  disabled={
                                    testType == 2 && selectedOptions[index]
                                      ? selectedOptions[index].isDisable
                                      : ''
                                  }
                                />
                                <span className='checkmark'></span>
                              </label>
                            </td>
                            <td className='question-choice-text-wrapper'>
                              <span
                                className='question-choice-text fs-1 cursor'
                                style={{
                                  color: 'black',
                                  fontSize: '20px',
                                  fontWeight: '500',
                                  color: isHighlightedOptA ? 'blue' : '',
                                }}
                                onClick={() => {
                                  handlePlayAudio('a', index)
                                }}
                              >
                                {qData.length > 0 ? qData[index].q_with_lang.opt_a : ''}
                              </span>{' '}
                            </td>
                            <td className='choice-result-display'> </td>

                            <td className='choice-result-display'>
                              {qData[index] && qData[index].opt_a_image != '' ? (
                                <img
                                  src={imageUrl + qData[index].opt_a_image}
                                  alt=''
                                  className='radio-container zoom d-inline'
                                />
                              ) : (
                                ''
                              )}
                            </td>

                            <td className='choice-result-display'>
                              
                              {testType == 2 ? (
                                <span class='ml-5'>
                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA == qData[index].correct_opt ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      <i className='fa fa-check d-inline'></i>
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA != '' &&
                                  selectedOptions[index].optA != qData[index].correct_opt ? (
                                    <span className='btn btn-icon btn-danger btn-circle btn-lg bg-color-white mx-4'>
                                      <i className='fa fa-times d-inline'></i>
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB &&
                                  qData[index].correct_opt == 'a' ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC &&
                                  qData[index].correct_opt == 'a' ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>

                          <tr className='question-choice-item-wrapper' style={{height: '110px'}}>
                            <td
                              className={`question-choice-select-wrapper ${
                                direction == 1 ? 'pe-6' : 'ps-6'
                              }`}
                            >
                              <label
                                id='option_1'
                                for='choice-2'
                                className='normal options radio-container '
                                style={{
                                  width: '70px',
                                  height: '70px',
                                  borderRadius: '10px',
                                  background: 'aqua',
                                }}
                              >
                                <input
                                  className='inpRadio'
                                  type='radio'
                                  value='b'
                                  id='choice-2'
                                  name='opt'
                                  onChange={(e) => {
                                    handleChangeRadioOptions(
                                      index,
                                      'optB',
                                      e.target.value,
                                      qData[index].id
                                    )
                                    handleMarkedMcqs(index)
                                  }}
                                  checked={
                                    selectedOptions[index] && selectedOptions[index].optB === 'b'
                                      ? 'checked'
                                      : ''
                                  }
                                  disabled={
                                    testType == 2 && selectedOptions[index]
                                      ? selectedOptions[index].isDisable
                                      : ''
                                  }
                                />
                                <span className='checkmark'></span>
                              </label>
                            </td>
                            <td className='question-choice-text-wrapper'>
                              <span
                                className='question-choice-text fs-1 cursor'
                                style={{
                                  color: 'black',
                                  fontSize: '20px',
                                  fontWeight: '500',
                                  color: isHighlightedOptB ? 'blue' : '',
                                }}
                                onClick={() => {
                                  handlePlayAudio('b', index)
                                }}
                              >
                                {qData.length > 0 ? qData[index].q_with_lang.opt_b : ''}
                              </span>
                            </td>
                            <td className='choice-result-display'> </td>

                            <td className='choice-result-display'>
                              {qData[index] && qData[index].opt_b_image != '' ? (
                                <img
                                  src={imageUrl + qData[index].opt_b_image}
                                  alt='opt b'
                                  // style={{height: '70px', marginLeft: '40px'}}
                                  className='radio-container zoom d-inline'
                                />
                              ) : (
                                ''
                              )}
                            </td>
                            <td className='choice-result-display'>
                              {testType == 2 ? (
                                <span class='ml-5'>
                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB == qData[index].correct_opt ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB != '' &&
                                  selectedOptions[index].optB != qData[index].correct_opt ? (
                                    <span className='btn btn-icon btn-danger btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-times d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA &&
                                  qData[index].correct_opt == 'b' ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC &&
                                  qData[index].correct_opt == 'b' ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>

                          <tr className='question-choice-item-wrapper' style={{height: '110px'}}>
                            <td
                              className={`question-choice-select-wrapper ${
                                direction == 1 ? 'pe-6' : 'ps-6'
                              }`}
                            >
                              <label
                                id='option_1'
                                for='choice-3'
                                className='normal options radio-container '
                                style={{
                                  width: '70px',
                                  height: '70px',
                                  borderRadius: '10px',
                                  background: 'aqua',
                                }}
                              >
                                <input
                                  className='inpRadio'
                                  type='radio'
                                  value='c'
                                  id='choice-3'
                                  name='opt'
                                  onChange={(e) => {
                                    handleChangeRadioOptions(
                                      index,
                                      'optC',
                                      e.target.value,
                                      qData[index].id
                                    )
                                    handleMarkedMcqs(index)
                                  }}
                                  checked={
                                    selectedOptions[index] && selectedOptions[index].optC === 'c'
                                      ? 'checked'
                                      : ''
                                  }
                                  disabled={
                                    testType == 2 && selectedOptions[index]
                                      ? selectedOptions[index].isDisable
                                      : ''
                                  }
                                />
                                <span className='checkmark'></span>
                              </label>
                            </td>
                            <td className='question-choice-text-wrapper'>
                              <span
                                className='question-choice-text fs-1 cursor'
                                style={{
                                  color: 'black',
                                  fontSize: '20px',
                                  fontWeight: '500',
                                  color: isHighlightedOptC ? 'blue' : '',
                                }}
                                onClick={() => {
                                  handlePlayAudio('c', index)
                                }}
                              >
                                {qData.length > 0 ? qData[index].q_with_lang.opt_c : ''}
                              </span>
                            </td>
                            <td className='choice-result-display'> </td>

                            <td className='choice-result-display'>
                              {qData[index] && qData[index].opt_c_image != '' ? (
                                <img
                                  src={imageUrl + qData[index].opt_c_image}
                                  alt=''
                                  // style={{height: '70px', marginLeft: '40px'}}
                                  className='radio-container zoom d-inline'
                                />
                              ) : (
                                ''
                              )}
                            </td>
                            <td className='choice-result-display'>
                              {testType == 2 ? (
                                <span class='ml-5'>
                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC == qData[index].correct_opt ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC != '' &&
                                  selectedOptions[index].optC != qData[index].correct_opt ? (
                                    <span className='btn btn-icon btn-danger btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-times d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA &&
                                  qData[index].correct_opt == 'c' ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB &&
                                  qData[index].correct_opt == 'c' ? (
                                    <span className='btn btn-icon btn-success btn-circle btn-lg bg-color-white mx-4'>
                                      {' '}
                                      <i className='fa fa-check d-inline'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='col-md-3'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-5'> 
                {qData[index] && qData[index].q_image != '' ? (
                  <img
                    className='img-fluid img-thumbnail zoom'
                    src={imageUrl + qData[index].q_image}
                    alt=''
                    style={{transformOrigin:imageStyleWithDir}}
                  
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {isVidSecShow ? (
        <ExamViseoSection qData={qData} handleVideoEnded={handleVideoEnded} index={index} />
      ) : (
        ''
      )}

      <ExamFooter
        handleNextPrevClick={handleNextPrevClick}
        isShowBtnFinish={isShowBtnFinish}
        index={index}
        handelExit={handelExit}
        direction={direction}
        handleEndReview={handleEndReview}
        reviewStack={reviewStack}
      />

  
      {isShowRevSection ? (
        <ExamReviewSection
          qData={qData}
          handleReviewMarkedOnly={handleReviewMarkedOnly}
          selectedOptions={selectedOptions}
          handleReview={handleReview}
          handleEndReview={handleEndReview}
          isActiveReviewMarkedOnly={isActiveReviewMarkedOnly}
          direction={direction}
        />
      ) : (
        ''
      )}



    </>
  )
}

export default ExamPage
