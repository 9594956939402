import React, {useEffect, useRef, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {KTSVG} from '../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {deleteLang, editLang, getAllLang, saveLang, updateLang} from '../apis/LanguageApi'
import {swalSuccess, toastError, toastSuccess} from '../global/Global'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import {exit} from 'process'
import { useNavigate } from 'react-router-dom'

const Languages = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [langData, setLangData] = useState([])
  const [lang, setLang] = useState('')
  const [status, setStatus] = useState(true)
  const [shortCode, setShortCode] = useState('')
  const [direction, setDirection] = useState(0)
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(5)

  const navigate=useNavigate();

  const [isChecked, setIsChecked] = useState(true)

  const handleStatusChange = () => {
    setStatus(!status)
  }

  const handleDirectionChange = () => {
    setDirection(!direction)
  }

  const saveButton = useRef(null)
  const editButton = useRef(null)

  useEffect(() => {
    getAllLangFunc(page)
  }, [])

  const getAllLangFunc = async (pageNo) => {
    setIsLoading(true)

    await getAllLang(pageNo)
      .then((response) => {
        setIsLoading(false)
        console.log(response)
        if (response.data.code != 206) {
          setPerPage(response.data.data.per_page)
          setTotalPage(response.data.data.total)
          setLangData(response.data.data.data)
        } else {
          toastError('Content not available')
          setLangData('')
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const autoButtonClick = (buttonType) => {
    buttonType.current.addEventListener('click', getAllLangFunc)
    buttonType.current.click()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    let data = JSON.stringify({
      lang: lang,
      short_code:shortCode,
      status: status,
      direction: direction,
    })

    await saveLang(data)
      .then((response) => {
        setIsLoading(false)
        console.log('Post Responce', response.data.code)

        if (response.data.code == 200) {
          autoButtonClick(saveButton)
          setLang()
          setStatus(0)
          setDirection(0)
          getAllLangFunc()
          toastSuccess('Record save successfully')
        }
        if (response.data.error) {
          toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const handleDelete = async (id) => {
    try {
      let data = JSON.stringify({
        id: id,
      })

      await deleteLang(data)
        .then((response) => {
          getAllLangFunc(page)
          toastSuccess('Record deleted successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      toastError('Record not deleted')
    }
  }

  const deleteRecord = (id) => {
    Swal.fire({
      text: 'Are you sure you want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id)
      }
    })
  }

  const handleEdit = async (id) => {
    let data = JSON.stringify({
      id: id,
    })

    await editLang(data)
      .then((response) => {
        console.log('Edit Response', response)

        var statValue = 1
        if (response.data.data.status != 'Active') {
          statValue = 0
        }

        var directionValue = 0
        if (response.data.data.direction != 'LTR') {
          directionValue = 1
        }

        setId(response.data.data.id)
        setLang(response.data.data.lang)
        setStatus(statValue)
        setDirection(directionValue)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //handleLangTranslate
  const handleLangTranslate=(langId)=>{
    navigate(`/language-translate/${langId}`);
  }

  const handleUpdateLanguage = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    let data = JSON.stringify({
      id: id,
      lang: lang,
      direction: direction,
      status: status,
    })

    await updateLang(data)
      .then((response) => {
        setIsLoading(false)
        console.log(response)

        if (response.data.code == 200) {
          autoButtonClick(editButton)
          setLang()
          setStatus(0)
          setDirection(0)
          getAllLangFunc()
          toastSuccess('Record updated successfully')
        }

        if (response.data.error) {
          toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const modalCloseEvent = () => {
    setLang('')
    setStatus(0)
    setDirection(0)
    setId('')
  }

  const [permArray, setPermArray] = useState([])
  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermArray(JSON.parse(storedArray))
    }
  }, [])

  return (
    <>
      <h2>Languages</h2>

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    class='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Language'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className='card'>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {permArray.some(item => item.name === 'language-create')? 
                  <button
                    className='btn btn-sm btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                    style={{marginRight: '-30px'}}
                  >
                    Add Language
                  </button>
:''}
                </div>
              </div>
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Languages</th>
                    <th className='text-end min-w-125px'>STATUS</th>
                    <th className='text-end min-w-100px'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
                  {langData
                    ? langData
                        .filter((item) => {
                          return search.toLowerCase() === ''
                            ? item
                            : item.lang.toLowerCase().includes(search)
                        })
                        .map((item) => {
                          return (
                            <tr>
                              <td>{item.lang}</td>

                              <td className='text-end'>
                  
                                <span className={`badge badge-light-${item.status=='Active' ? 'success' : 'danger'}`}>{item.status}</span>
                              </td>
                              <td className='text-end'>
                                <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>

                                  <Dropdown.Menu>
                                  {permArray.some(item => item.name === 'language-edit')? 
                                    <Dropdown.Item
                                      data-bs-toggle='modal'
                                      data-bs-target='#edit_language'
                                      onClick={() => {
                                        handleEdit(item.id)
                                      }}
                                    >
                                      {' '}
                                      Edit
                                    </Dropdown.Item>
                                    :''}
                                    {permArray.some(item => item.name === 'language-delete')? 
                                    <Dropdown.Item
                                      onClick={() => {
                                        deleteRecord(item.id)
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    :''}
                                    <Dropdown.Item onClick={()=>{handleLangTranslate(item.lang_short)}}>Translation</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        })
                    : ''}
                </tbody>
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    console.log(page)
                    getAllLangFunc(page)
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='kt_modal_create_app'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-close'
                data-bs-dismiss='modal'
                onClick={() => modalCloseEvent()}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Add Language</h1>
              </div>
              <form onSubmit={handleSubmit}>

                <div className='fv-row mb-10'>

                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Language</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='appName'
                    placeholder='Language Title'
                    required
                    onChange={(e) => setLang(e.target.value)}
                    value={lang}
                  />
                  <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>

<label className='d-flex align-items-center fs-5 fw-bold mb-2'>
  <span className='required'>Short Code</span>
  <i
    className='fas fa-exclamation-circle ms-2 fs-7'
    data-bs-toggle='tooltip'
    title='Specify your unique app name'
  ></i>
</label>

<input
  type='text'
  className='form-control form-control-lg form-control-solid'
  name='appName'
  placeholder='Language Short Code'
  required
  onChange={(e) =>setShortCode(e.target.value)}
  value={shortCode}
/>
<div className='text-danger'></div>
</div>

                

                <div className='fv-row mb-10'>
                  <div className='d-flex flex-stack'>
                    <div className='me-5 fw-bold'>
                      <label className='fs-6'>Language Direction</label>
                    </div>

                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        onChange={handleDirectionChange}
                      />

                      <span className='form-check-label fw-bold text-muted'>Right To Left</span>
                    </label>
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  <div className='d-flex flex-stack'>
                    <div className='me-5 fw-bold'>
                      <label className='fs-6'>Language Status</label>
                    </div>

                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={status}
                        onChange={handleStatusChange}

                      />

                      <span className='form-check-label fw-bold text-muted'>Enabled</span>
                    </label>
                  </div>
                </div>

                <div className='d-flex flex-center flex-row-fluid pt-12'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={saveButton}
                    onClick={() => modalCloseEvent()}
                  >
                    Cancel
                  </button>

                  <button type='submit' class='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}
      <div
        className='modal fade'
        id='edit_language'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-close'
                data-bs-dismiss='modal'
                onClick={() => modalCloseEvent()}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Edit Language</h1>
              </div>
              <form onSubmit={handleUpdateLanguage}>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Language</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='appName'
                    placeholder='Language Title'
                    required
                    onChange={(e) => setLang(e.target.value)}
                    value={lang}
                  />
                  <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>
                  <div className='d-flex flex-stack'>
                    <div className='me-5 fw-bold'>
                      <label className='fs-6'>Language Direction</label>
                    </div>

                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={direction}
                        onChange={handleDirectionChange}
                      />

                      <span className='form-check-label fw-bold text-muted'>Right To Left</span>
                    </label>
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  <div className='d-flex flex-stack'>
                    <div className='me-5 fw-bold'>
                      <label className='fs-6'>Language Status</label>
                    </div>

                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={status}
                        onChange={handleStatusChange}
                      />

                      <span className='form-check-label fw-bold text-muted'>Enabled</span>
                    </label>
                  </div>
                </div>
                <div className='d-flex flex-center flex-row-fluid pt-12'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={editButton}
                    onClick={() => modalCloseEvent()}
                  >
                    Cancel
                  </button>

                  <button type='submit' class='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Save Changes'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Languages
