import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getAllExamList= async (pageNo)=>{
  
    var config = {
      method: 'get',
      url: `${API_URL}/get-exam-list?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
       'Content-Type': 'application/json',
     },
    };

  const response=await axios(config)
  return response;
}



export const createExam = async (data) => {


  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/save-exam`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


export const deleteExam= async (data)=>{

  var config = {
    method: 'delete',
    url: `${API_URL}/delete-exam`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const editExam= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/edit-exam`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}

export const updateExam= async (data)=>{


  var config = {
    method: 'post',
    url: `${API_URL}/update-exam`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const getInvigilatorForDropdown= async ()=>{
  
  var config = {
    method: 'get',
    url: `${API_URL}/get-invigilator`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
  };

const response=await axios(config)
return response;
}

export const isScheduleExam= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/is-exam-schedule`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}


export const getPracticeExamResult= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-practice-result`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}

//getPracticeExamResultReport

export const getPracticeExamStudent= async ()=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-practice-result-for-admin-report`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
   
  };
const response=await axios(config)
return response;
}



export const getExamResult= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-exam-result`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}


export const printResult= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/print-exam-result`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}

export const getExamResultForAdmin= async (data,pageNo)=>{


  var config = {
    method: 'post',
    url: `${API_URL}/get-admin-exam-result?page=${pageNo}`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}

export const getResultDetail= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-result-detail`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}



export const getStdExamInfo= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-std-exam-info`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}








