import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL
const API_TOKEN=window.localStorage.getItem('authToken');


export const getAllLang= async (page)=>{

    var config = {
      method: 'get',
      url: `${API_URL}/get-all-lang?page=${page}`,
        headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${API_TOKEN}`
  },
    
    };

  const response=await axios(config)
  return response;
}
export const saveLang= async (data)=>{


var config = {
  method: 'post',
  url: `${API_URL}/save-lang`,
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${API_TOKEN}`
  },
  data : data
};
const response=await axios(config)
return response;


}

export const deleteLang= async (data)=>{
 
  var config = {
    method: 'delete',
    url: `${API_URL}/delete-lang`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${API_TOKEN}`
    },
    data: data,
  };

const response=await axios(config)
return response;
}
export const editLang= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/edit-lang`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${API_TOKEN}`
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const updateLang= async (data)=>{
  var config = {
    method: 'post',
    url: `${API_URL}/update-lang`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${API_TOKEN}`
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const getAllLangForDropdown= async ()=>{

  var config = {
    method: 'get',
    url: `${API_URL}/get-all-lang-for-dropdown`,
      headers: { 
  'Content-Type': 'application/json', 
  'Authorization': `Bearer ${API_TOKEN}`
},
  
  };

const response=await axios(config)
return response;
}




