
import React, {FC} from 'react'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget5,

} from '../../../_metronic/partials/widgets'
import StdDashboard from '../../../components/std-module/StdDashboard'
import AdminDashboard from '../../../components/dashboard/AdminDashboard'

const DashboardPage: FC = () => (
  <>
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>

  </>
)

const DashboardWrapper: FC = () => {

  return (
    <>
    {
        window.localStorage.getItem('userType')=='2'? 
        <StdDashboard />:''
       }

      {
        window.localStorage.getItem('userType')=='1'? 
        <AdminDashboard />:''
       }

    </>
  )
}

export {DashboardWrapper}
