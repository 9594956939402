import React, {useState, useEffect} from 'react'
import userImage from '../assets/img/userImage.png'
import {toastError, toastSuccess} from '../global/Global'
import {ToastContainer} from 'react-toastify'
import { createStudent } from '../apis/StudentApi.js'

const AddStudent = () => {


  const [file, setFile] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isSaveClick, setIsSaveClick] = useState(false)
  const [status, setStatus] = useState(1)
  const [isStatusCheck, setIsStatusCheck] = useState(false)
  const [errors, setErrors] = useState({});

  const [data, setData] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    traffic_id: '',
    nationality: '',
    gender: '',
  })

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    // console.log(name, value)
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const handleStatusChange = () => {
    data.isStatusCheck ? setStatus(1) : setStatus(0)
    setIsStatusCheck((prev) => !prev)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const FormData = require('form-data')
    let newData = new FormData()


    newData.append('name', data.fname)
    newData.append('l_name', data.lname)
    newData.append('email', data.email)
    newData.append('phone', data.phone)
    newData.append('traffic_id', data.traffic_id)
    newData.append('nationality_id', data.nationality)
    newData.append('gender', data.gender)
    newData.append('status', status)
    newData.append('file', file)

    await createStudent(newData)
      .then((response) => {
        setIsLoading(false)
        console.log('handleSubmitForm', response)

        if (response.data.code == 200) {
          setData({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            traffic_id: '',
            nationality: '',
            gender: '',
          })
          setFile('')
          setStatus('');
          toastSuccess('Record save successfully')
        }

        if (response.data.code == 204) {
      
          toastError('This record already exist')
        }
      })
      .catch((err) => {
        console.log('handleSubmitForm', err)
        setIsLoading(false)
        toastError(err.data.error)
        console.log(err.message)
      })
  }

  const saveStudent = async () => {


    const validationErrors = validateForm(data);
    setErrors(validationErrors);


    if (Object.keys(validationErrors).length === 0) {
      setIsSaveClick(true)
    const FormData = require('form-data')
    let newData = new FormData()


    
    newData.append('name', data.fname)
    newData.append('l_name', data.lname)
    newData.append('email', data.email)
    newData.append('phone', data.phone)
    newData.append('traffic_id', data.traffic_id)
    newData.append('nationality_id', data.nationality)
    newData.append('gender', data.gender)
    newData.append('status', status)
    newData.append('file', file)


      await createStudent(newData)
      .then((response) => {
        
    console.log('Save Student',response);
        setIsSaveClick(false)
       

        if (response.data.code == 200) {
          toastSuccess('Record save successfully')
        }
        if (response.data.code == 204) {
          toastError('This record already exist')
        }
      })
      .catch((err) => {
        setIsSaveClick(false)
        toastError(err.data.error)
        console.log(err)
      })

    }


  }

  const validateForm = (data) => {


    let errors = {};


    // if (!data.email) {
    //   errors.email = 'Email is required.';
    // } else if (!/\S+@\S+\.\S+/.test(data.email)) {
    //   errors.email = 'Invalid email format.';
    // }
    if (!data.fname) {
      errors.fname = 'Name is required.';
    } 

    if (!data.phone) {
      errors.phone = 'Phone is required.';
    } 

    if (!data.traffic_id) {
      errors.traffic_id = 'Traffic Id is required.';
    } 

      
    if (!data.nationality) {
      errors.nationality = 'Nationality Id is required.';
    } 

    if (!data.gender) {
      errors.gender = 'Gender Id is required.';
    } 
    return errors;



  };

  

  return (
    <>
      <h3>Add New Student</h3>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h6 className='fw-bolder m-0'> Student Profile Detail</h6>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form className='form' onSubmit={handleSubmitForm}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Avatar</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{backgroundImage: `url(${userImage})`}}
                      >
                        {file ? (
                          <img
                            className='image-input-wrapper w-125px h-125px'
                            src={URL.createObjectURL(file)}
                            alt='Thumb'
                          />
                        ) : (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{backgroundImage: `url(${userImage})`}}
                          ></div>
                        )}

                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title='Change avatar'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>
                          <input
                            type='file'
                            name='avatar'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => {
                              setFile(e.target.files[0])
                            }}
                          />
                          <input type='hidden' name='avatar_remove' />
                        </label>
                        <span
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='cancel'
                          data-bs-toggle='tooltip'
                          title='Cancel avatar'
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                        <span
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='remove'
                          data-bs-toggle='tooltip'
                          title='Remove avatar'
                          onClick={(e) => {
                              setFile('')
                            }}
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                      </div>
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
               

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        name='fname'
                        value={data.fname}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        name='lname'
                        value={data.lname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    {errors.fname && <span style={{color:"red"}}>{errors.fname}</span>}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>Email</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email'
                    name='email'
                    value={data.email}
                    onChange={handleChange}
                    
                    
                  />
                  {errors.email && <span style={{color:"red"}}>{errors.email}</span>}
                </div>
                
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Contact Phone</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone'
                    name='phone'
                    value={data.phone}
                    onChange={handleChange}
                    required
                  />

{errors.phone && <span style={{color:"red"}}>{errors.phone}</span>}
                </div>

                
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Traffic ID</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Traffic ID'
                    name='traffic_id'
                    value={data.traffic_id}
                    onChange={handleChange}
                    required
                  />
                  {errors.traffic_id && <span style={{color:"red"}}>{errors.traffic_id}</span>}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nationality</label>

                <div className='col-lg-8 fv-row'>
                  <select
                    name='nationality'
                    className='form-control select2'
                    value={data.nationality}
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Choose Country</option>
                  
    <option value="AF">Afghanistan</option>
    <option value="AX">Aland Islands</option>
    <option value="AL">Albania</option>
    <option value="DZ">Algeria</option>
    <option value="AS">American Samoa</option>
    <option value="AD">Andorra</option>
    <option value="AO">Angola</option>
    <option value="AI">Anguilla</option>
    <option value="AQ">Antarctica</option>
    <option value="AG">Antigua and Barbuda</option>
    <option value="AR">Argentina</option>
    <option value="AM">Armenia</option>
    <option value="AW">Aruba</option>
    <option value="AU">Australia</option>
    <option value="AT">Austria</option>
    <option value="AZ">Azerbaijan</option>
    <option value="BS">Bahamas</option>
    <option value="BH">Bahrain</option>
    <option value="BD">Bangladesh</option>
    <option value="BB">Barbados</option>
    <option value="BY">Belarus</option>
    <option value="BE">Belgium</option>
    <option value="BZ">Belize</option>
    <option value="BJ">Benin</option>
    <option value="BM">Bermuda</option>
    <option value="BT">Bhutan</option>
    <option value="BO">Bolivia</option>
    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
    <option value="BA">Bosnia and Herzegovina</option>
    <option value="BW">Botswana</option>
    <option value="BV">Bouvet Island</option>
    <option value="BR">Brazil</option>
    <option value="IO">British Indian Ocean Territory</option>
    <option value="BN">Brunei Darussalam</option>
    <option value="BG">Bulgaria</option>
    <option value="BF">Burkina Faso</option>
    <option value="BI">Burundi</option>
    <option value="KH">Cambodia</option>
    <option value="CM">Cameroon</option>
    <option value="CA">Canada</option>
    <option value="CV">Cape Verde</option>
    <option value="KY">Cayman Islands</option>
    <option value="CF">Central African Republic</option>
    <option value="TD">Chad</option>
    <option value="CL">Chile</option>
    <option value="CN">China</option>
    <option value="CX">Christmas Island</option>
    <option value="CC">Cocos (Keeling) Islands</option>
    <option value="CO">Colombia</option>
    <option value="KM">Comoros</option>
    <option value="CG">Congo</option>
    <option value="CD">Congo, Democratic Republic of the Congo</option>
    <option value="CK">Cook Islands</option>
    <option value="CR">Costa Rica</option>
    <option value="CI">Cote D'Ivoire</option>
    <option value="HR">Croatia</option>
    <option value="CU">Cuba</option>
    <option value="CW">Curacao</option>
    <option value="CY">Cyprus</option>
    <option value="CZ">Czech Republic</option>
    <option value="DK">Denmark</option>
    <option value="DJ">Djibouti</option>
    <option value="DM">Dominica</option>
    <option value="DO">Dominican Republic</option>
    <option value="EC">Ecuador</option>
    <option value="EG">Egypt</option>
    <option value="SV">El Salvador</option>
    <option value="GQ">Equatorial Guinea</option>
    <option value="ER">Eritrea</option>
    <option value="EE">Estonia</option>
    <option value="ET">Ethiopia</option>
    <option value="FK">Falkland Islands (Malvinas)</option>
    <option value="FO">Faroe Islands</option>
    <option value="FJ">Fiji</option>
    <option value="FI">Finland</option>
    <option value="FR">France</option>
    <option value="GF">French Guiana</option>
    <option value="PF">French Polynesia</option>
    <option value="TF">French Southern Territories</option>
    <option value="GA">Gabon</option>
    <option value="GM">Gambia</option>
    <option value="GE">Georgia</option>
    <option value="DE">Germany</option>
    <option value="GH">Ghana</option>
    <option value="GI">Gibraltar</option>
    <option value="GR">Greece</option>
    <option value="GL">Greenland</option>
    <option value="GD">Grenada</option>
    <option value="GP">Guadeloupe</option>
    <option value="GU">Guam</option>
    <option value="GT">Guatemala</option>
    <option value="GG">Guernsey</option>
    <option value="GN">Guinea</option>
    <option value="GW">Guinea-Bissau</option>
    <option value="GY">Guyana</option>
    <option value="HT">Haiti</option>
    <option value="HM">Heard Island and Mcdonald Islands</option>
    <option value="VA">Holy See (Vatican City State)</option>
    <option value="HN">Honduras</option>
    <option value="HK">Hong Kong</option>
    <option value="HU">Hungary</option>
    <option value="IS">Iceland</option>
    <option value="IN">India</option>
    <option value="ID">Indonesia</option>
    <option value="IR">Iran, Islamic Republic of</option>
    <option value="IQ">Iraq</option>
    <option value="IE">Ireland</option>
    <option value="IM">Isle of Man</option>
    <option value="IL">Israel</option>
    <option value="IT">Italy</option>
    <option value="JM">Jamaica</option>
    <option value="JP">Japan</option>
    <option value="JE">Jersey</option>
    <option value="JO">Jordan</option>
    <option value="KZ">Kazakhstan</option>
    <option value="KE">Kenya</option>
    <option value="KI">Kiribati</option>
    <option value="KP">Korea, Democratic People's Republic of</option>
    <option value="KR">Korea, Republic of</option>
    <option value="XK">Kosovo</option>
    <option value="KW">Kuwait</option>
    <option value="KG">Kyrgyzstan</option>
    <option value="LA">Lao People's Democratic Republic</option>
    <option value="LV">Latvia</option>
    <option value="LB">Lebanon</option>
    <option value="LS">Lesotho</option>
    <option value="LR">Liberia</option>
    <option value="LY">Libyan Arab Jamahiriya</option>
    <option value="LI">Liechtenstein</option>
    <option value="LT">Lithuania</option>
    <option value="LU">Luxembourg</option>
    <option value="MO">Macao</option>
    <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
    <option value="MG">Madagascar</option>
    <option value="MW">Malawi</option>
    <option value="MY">Malaysia</option>
    <option value="MV">Maldives</option>
    <option value="ML">Mali</option>
    <option value="MT">Malta</option>
    <option value="MH">Marshall Islands</option>
    <option value="MQ">Martinique</option>
    <option value="MR">Mauritania</option>
    <option value="MU">Mauritius</option>
    <option value="YT">Mayotte</option>
    <option value="MX">Mexico</option>
    <option value="FM">Micronesia, Federated States of</option>
    <option value="MD">Moldova, Republic of</option>
    <option value="MC">Monaco</option>
    <option value="MN">Mongolia</option>
    <option value="ME">Montenegro</option>
    <option value="MS">Montserrat</option>
    <option value="MA">Morocco</option>
    <option value="MZ">Mozambique</option>
    <option value="MM">Myanmar</option>
    <option value="NA">Namibia</option>
    <option value="NR">Nauru</option>
    <option value="NP">Nepal</option>
    <option value="NL">Netherlands</option>
    <option value="AN">Netherlands Antilles</option>
    <option value="NC">New Caledonia</option>
    <option value="NZ">New Zealand</option>
    <option value="NI">Nicaragua</option>
    <option value="NE">Niger</option>
    <option value="NG">Nigeria</option>
    <option value="NU">Niue</option>
    <option value="NF">Norfolk Island</option>
    <option value="MP">Northern Mariana Islands</option>
    <option value="NO">Norway</option>
    <option value="OM">Oman</option>
    <option value="PK">Pakistan</option>
    <option value="PW">Palau</option>
    <option value="PS">Palestinian Territory, Occupied</option>
    <option value="PA">Panama</option>
    <option value="PG">Papua New Guinea</option>
    <option value="PY">Paraguay</option>
    <option value="PE">Peru</option>
    <option value="PH">Philippines</option>
    <option value="PN">Pitcairn</option>
    <option value="PL">Poland</option>
    <option value="PT">Portugal</option>
    <option value="PR">Puerto Rico</option>
    <option value="QA">Qatar</option>
    <option value="RE">Reunion</option>
    <option value="RO">Romania</option>
    <option value="RU">Russian Federation</option>
    <option value="RW">Rwanda</option>
    <option value="BL">Saint Barthelemy</option>
    <option value="SH">Saint Helena</option>
    <option value="KN">Saint Kitts and Nevis</option>
    <option value="LC">Saint Lucia</option>
    <option value="MF">Saint Martin</option>
    <option value="PM">Saint Pierre and Miquelon</option>
    <option value="VC">Saint Vincent and the Grenadines</option>
    <option value="WS">Samoa</option>
    <option value="SM">San Marino</option>
    <option value="ST">Sao Tome and Principe</option>
    <option value="SA">Saudi Arabia</option>
    <option value="SN">Senegal</option>
    <option value="RS">Serbia</option>
    <option value="CS">Serbia and Montenegro</option>
    <option value="SC">Seychelles</option>
    <option value="SL">Sierra Leone</option>
    <option value="SG">Singapore</option>
    <option value="SX">Sint Maarten</option>
    <option value="SK">Slovakia</option>
    <option value="SI">Slovenia</option>
    <option value="SB">Solomon Islands</option>
    <option value="SO">Somalia</option>
    <option value="ZA">South Africa</option>
    <option value="GS">South Georgia and the South Sandwich Islands</option>
    <option value="SS">South Sudan</option>
    <option value="ES">Spain</option>
    <option value="LK">Sri Lanka</option>
    <option value="SD">Sudan</option>
    <option value="SR">Suriname</option>
    <option value="SJ">Svalbard and Jan Mayen</option>
    <option value="SZ">Swaziland</option>
    <option value="SE">Sweden</option>
    <option value="CH">Switzerland</option>
    <option value="SY">Syrian Arab Republic</option>
    <option value="TW">Taiwan, Province of China</option>
    <option value="TJ">Tajikistan</option>
    <option value="TZ">Tanzania, United Republic of</option>
    <option value="TH">Thailand</option>
    <option value="TL">Timor-Leste</option>
    <option value="TG">Togo</option>
    <option value="TK">Tokelau</option>
    <option value="TO">Tonga</option>
    <option value="TT">Trinidad and Tobago</option>
    <option value="TN">Tunisia</option>
    <option value="TR">Turkey</option>
    <option value="TM">Turkmenistan</option>
    <option value="TC">Turks and Caicos Islands</option>
    <option value="TV">Tuvalu</option>
    <option value="UG">Uganda</option>
    <option value="UA">Ukraine</option>
    <option value="AE">United Arab Emirates</option>
    <option value="GB">United Kingdom</option>
    <option value="US">United States</option>
    <option value="UM">United States Minor Outlying Islands</option>
    <option value="UY">Uruguay</option>
    <option value="UZ">Uzbekistan</option>
    <option value="VU">Vanuatu</option>
    <option value="VE">Venezuela</option>
    <option value="VN">Viet Nam</option>
    <option value="VG">Virgin Islands, British</option>
    <option value="VI">Virgin Islands, U.s.</option>
    <option value="WF">Wallis and Futuna</option>
    <option value="EH">Western Sahara</option>
    <option value="YE">Yemen</option>
    <option value="ZM">Zambia</option>
    <option value="ZW">Zimbabwe</option>
                  </select>
                  {errors.nationality && <span style={{color:"red"}}>{errors.nationality}</span>}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Gender</label>

                <div className='col-lg-8 fv-row'>
                  <select
                    name='gender'
                    className='form-control'
                    value={data.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Choose Gender</option>
                    <option value='1'>Male</option>
                    <option value='2'>Female</option>
                    <option value='3'>Other</option>
                  </select>
                  {errors.gender && <span style={{color:"red"}}>{errors.gender}</span>}
                </div>
              </div>

              <div className='row mb-0'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      onChange={(e) => handleStatusChange()}
                      checked
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>

              <button type='button' class='btn btn-primary' onClick={()=>saveStudent()}>
                {isSaveClick ? (
                  <div>
                    <i className='fa fa-spinner fa-spin'></i>
                  </div>
                ) : (
                  'Save'
                )}
              </button>

              <button type='submit' class='btn btn-primary' style={{marginLeft:'10px'}} >
                {isLoading ? (
                  <div>
                    <i className='fa fa-spinner fa-spin'></i>
                  </div>
                ) : (
                  'Save & Next'
                )}
              </button>
            </div>
         
          </form>
        </div>
      </div>
    </>
  )
}

export default AddStudent
