import React from 'react'
import Swal from 'sweetalert2'

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




  
  export const swalSuccess = (mesageType,message) => {
    Swal.fire(mesageType,message,"success");
  };

  export const toastError = (toastMessage) => {
    toast.warning(toastMessage , {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  };
  
  export const toastSuccess = (toastMessage) => {
  toast.success(toastMessage , {
      position: toast.POSITION.BOTTOM_RIGHT
  });
  };