/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect,useState} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import car from '../../../components/assets/img/car.jpg'
import { getConfigInfo } from '../../../components/apis/ConfigApi'

const AuthLayout = () => {

  const imageUrl = process.env.REACT_APP_IMAGE_URL

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])


  const [adminBgImage, setAdminBgImage] = useState(null)


  const handleGetConfigInfo = async () => {
    await getConfigInfo()
      .then((response) => {
      if(response.data.code!=206){
        setAdminBgImage(response.data.data.admin_bg_img)
        localStorage.setItem('appTitle',response.data.data.title);
        localStorage.setItem('appLogo',response.data.data.logo);
        localStorage.setItem('adminBgImage',response.data.data.admin_bg_img);
        localStorage.setItem('resPageLogo',response.data.data.res_page_logo);
  
	  }else{
      localStorage.setItem('adminBgImage','');
      localStorage.setItem('appLogo','');
      localStorage.setItem('appTitle','');
      localStorage.setItem('resPageLogo','');
	  }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetConfigInfo();
  }, []);




  return (
  
    <div className="d-flex flex-column flex-root" style={{overflowX:'hidden'}}>

      <div className='d-flex flex-column flex-column-fluid position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed' 
      
       style={{
       backgroundImage:adminBgImage?  "url(" + imageUrl+adminBgImage + ")":`url(${car})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
       
      }}>

<div className='row '>
<div className='col-md-7'> </div>
<div className='col-md-5 overflow-hidden'> 
<div className="d-flex p-15 pb-lg-20">
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 ml-auto">
         
          <Outlet />
        </div>
        </div>
        </div>
      </div>
      </div>
      </div>
    


  )
}
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
