/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'

import {getCSSVariableValue} from '../../../assets/ts/_utils'
import { Link } from 'react-router-dom'
import { saveAttempt } from '../../../../components/apis/QuestionsBankApi'


type Props = {
  className: string
  chartColor: string
  chartHeight: string
  courseName: string
  totalQ: number
}

const VideoExamWidgets: React.FC<Props> = ({className, chartColor, chartHeight,courseName,totalQ}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)


  const [isLoading, setIsLoading] = useState(false)

  const handleAttempt = async () => {
// console.log('handle attempt');
    setIsLoading(true)
    let data = JSON.stringify({
      std_id: window.localStorage.getItem('stdId'),
      exam_type:2,
    })
    
    await saveAttempt(data)
      .then((response) => {
        setIsLoading(false)
        //  console.log('Save attempt',response)

      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Video Questions</span>
          <span className='text-muted fw-bold fs-7'>{totalQ} Question For {courseName}</span>
        </h3>

      
      </div>
 

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>

        <div className='pt-5'>
          {/* <p className='text-center fs-6 pb-5 '>
            <span className='badge badge-light-danger fs-8'>Some:</span>&nbsp; note for students
            <br />
            to approve newly amended polices
          </p> */}

{/* 
          <Link to='/video-q/0' className={`btn btn-${chartColor} w-100 py-3`} onClick={()=>{handleAttempt()}}>  Start Now</Link> */}
       {totalQ > 0?
          <Link to='/exam/0/0/0/2/1' className={`btn btn-${chartColor} w-100 py-3`} onClick={()=>{handleAttempt()}}>  Start Now</Link>
          :
          <button  className={`btn btn-danger w-100 py-3`} >Questions Not Exist</button>
       }
          
        
          
          
          
        </div>
      </div>
      {/* end::Body */}

    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [0],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {VideoExamWidgets}
