import React, {useEffect, useState} from 'react'
import '../../assets/styles/css/RtlStyle.css'
import { getAllQuestionsForPractice, getQuestionsForActualExam, saveMcqsForActualExam, saveMcqsForPractExam } from '../../apis/QuestionsBankApi'
import { toastError } from '../../global/Global'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'


const customStyle = {
  navSection: {
    position: 'absolute',
    right: 0,
    top: '5px',
  },
  headerSection: {
    margin: '1px 10px 10px',
    height: '5rem',
  },
  switchButon: {
    marginLeft: '5px',
  },

  button: {
    backgroundColor: '#fff' /* Green */,
    border: 'none',
    color: 'black',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
  },
}
const ActualRtlExam = () => {

  const navigate=useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [qData, setQData] = useState([])
  const [index, setIndex] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [ans, setAns] = useState(2)
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const [isPlaying, setIsPlaying] = useState(false)
  const [isHighlightedQAudio, setIsHighlightedQAudio] = useState(false);
  const [isHighlightedOptA, setIsHighlightedOptA] = useState(false);
  const [isHighlightedOptB, setIsHighlightedOptB] = useState(false);
  const [isHighlightedOptC, setIsHighlightedOptC] = useState(false);
  const [stdName, setStdName] = useState('')
  const [questionPerc, setQuestionPerc] = useState(0)
  const [stdId, setStdId] = useState('')

  const [isHeighLighted, setIsHeighLighted] = useState([{

  }]);

  const qAudioRef = React.createRef()
  const optAAudioRef = React.createRef()
  const optBAudioRef = React.createRef()
  const optCAudioRef = React.createRef()


  let {langId, audioLang} = useParams()


  const handleGetQuestionForActualExam = async () => {
    setIsLoading(true)

    let data = JSON.stringify({
      lang_id: langId,
      audioLangId: audioLang,
      std_id: sessionStorage.getItem('stdId'),
   
    })


    await getQuestionsForActualExam(data)
      .then((response) => {
        // console.log('Get All Q',response.data.data.examQuestions)
        if (response.data.code != 206) {
          setQData(response.data.data.examQuestions)
          setTotalQuestion(response.data.data.examQuestions.length)
          setIsLoading(false)
        } else {
          setQData([])
          toastError('Content not available')
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const [selectedOptions, setSelectedOptions] = useState([
    {
      optA: '',
      optB: '',
      optC: '',
      correctAns: '2',
      isDisable: false,
    },
  ])


  const handleChangeRadioOptions = (index, property, value) => {
    value && value === qData[index].correct_opt ? setAns(1) : setAns(0)

    const newArray = [...selectedOptions]
    const updatedObject = {...newArray[index]}

    updatedObject['isDisable'] = true
    updatedObject['correctAns'] = value && value === qData[index].correct_opt ? 1 : 0

    if (value == 'a' && property == 'optA') {
      updatedObject[property] = value
      updatedObject['optB'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'b' && property == 'optB') {
      updatedObject[property] = value
      updatedObject['optA'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'c' && property == 'optC') {
      updatedObject[property] = value
      updatedObject['optA'] = ''
      updatedObject['optB'] = ''
      newArray[index] = updatedObject
    }

    setSelectedOptions(newArray)
  }

  const handleNextPrevClick = (type) => {
    if (type == 'next' && index < qData.length - 1) {
      setIndex(index + 1)
    }
    if (type == 'prev' && index > 0) {
      setIndex(index - 1)
    }
    calculatePercentage(index+1)
  }

  const handleSubmit = async (qId) => {
    setIsLoading(true)
    let data = JSON.stringify({
      std_id: stdId,
      q_id: qId,
      ans: ans,
      exam_type:1
  
    })

    await saveMcqsForActualExam(data)
      .then((response) => {
 
        if(response.data.success==false){
          toastError(response.data.message)
        }
        setIsLoading(false)

  
    
        if (response.data.code == 200) {
        }
        if (response.data.error) {
          toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }
  const handlePlayAudio = (type,index) => {
    console.log(type,index);
    setIsPlaying(!isPlaying)
   
      if(type=='q' && qData[index].q_lang_audio && qData[index].q_lang_audio.q_audio){
        
    if (isPlaying) {

      setIsHighlightedQAudio(true);

      optAAudioRef.current.pause()
      optBAudioRef.current.pause()
      optCAudioRef.current.pause()
      setIsHighlightedOptA(false);
      setIsHighlightedOptB(false);
      setIsHighlightedOptC(false);

      qAudioRef.current.play()
     
      }
      else {
        setIsHighlightedQAudio(false);
        qAudioRef.current.pause()
      }
    } 

    if(type=='a' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_a_audio){
      if (isPlaying) {
        setIsHighlightedOptA(true);


        qAudioRef.current.pause()
        optBAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false);
        setIsHighlightedOptB(false);
        setIsHighlightedOptC(false);

        optAAudioRef.current.play()
        }
        else {
          setIsHighlightedOptA(false);
          optAAudioRef.current.pause()
        }
      } 

     
        if(type=='b' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_b_audio){
        if (isPlaying) {


          setIsHighlightedOptB(true);

          qAudioRef.current.pause()
          optAAudioRef.current.pause()
          optCAudioRef.current.pause()
          setIsHighlightedQAudio(false);
          setIsHighlightedOptA(false);
          setIsHighlightedOptC(false);

          optBAudioRef.current.play()
          }
          else {
            setIsHighlightedOptB(false);
            optBAudioRef.current.pause()
          }
        } 

      
          if(type=='c' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_c_audio){
          if (isPlaying) {
            setIsHighlightedOptC(true);

            qAudioRef.current.pause()
            optAAudioRef.current.pause()
            optBAudioRef.current.pause()
            setIsHighlightedQAudio(false);
            setIsHighlightedOptA(false);
            setIsHighlightedOptB(false);

            optCAudioRef.current.play()
            }
            else {
              setIsHighlightedOptC(false);
              optCAudioRef.current.pause()
            }
          } 
   }



  const changeHighlightState = () => {
    setIsHighlightedQAudio(false);
    setIsHighlightedOptA(false);
    setIsHighlightedOptB(false);
    setIsHighlightedOptC(false);
  }

  const getStdInfo = () => {
    setStdName(sessionStorage.getItem('stdName'))
    setStdId(sessionStorage.getItem('stdId'))
  }

const handelExit = (id) => {
  Swal.fire({
    text: 'Are you sure you want to exit from Practice test Next time,it will be start from beginning?!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#F1416C',
    cancelButtonColor: '#181C32',
    confirmButtonText: 'Yes, Exit Now!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      navigate('/std-dashboard')
    }
  })
}

const handleFinish = (id) => {
  Swal.fire({
    text: ' Practice questions finished, Press below button to logout?!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#F1416C',
    cancelButtonColor: '#181C32',
    confirmButtonText: 'Logout!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      navigate('/std-dashboard')
    }
  })
}


  const calculatePercentage = (value) => {
  
    let perc=0;
    if(totalQuestion > 0){
      perc=(value / totalQuestion) * 100;
    }
    setQuestionPerc(perc)
}




  useEffect(() => {
    handleGetQuestionForActualExam();
    getStdInfo()
 
  
  }, [])


  return (
      <>
        <div class="d-flex flex-column h-100">
          
  <header>
      <nav class="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ff9900"}}>
  
          <div class="collapse navbar-collapse" >
              <ul class="navbar-nav mr-auto"></ul>
              <ul  class="no-li-style" style={{marginTop:"-5px",bottom:"-5px"}}>
                  <li>
                      <div class="progress" style={{width: "200px"}}>
                          <div class="progress-bar bg-success" role="progressbar" style={{width: "75%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> {questionPerc.toFixed(1)}%</div>
                      </div>
                  </li>

              
              </ul>
              <ul style={{listStyleType:" none", marginTop:"-5px",bottom:"-5px"}}>
             
                  <li> <span class="navbar-custom-text">{index+1} /{totalQuestion}:Question Attempted</span></li>
              </ul>
          </div>
  <div style={{marginRight: "10px"}}>
          <a class="navbar-brand" href="#">
          <span> {stdName}</span>-
          <span>LMV</span>
       
            
            </a>
          <button class="navbar-brand"    
           onClick={()=>{ handelExit()}}><i class="fa fa-power-off"></i></button>
  </div>
      </nav>
  </header>

  
<main class="flex-shrink-0" dir="rtl">
    <div class="mt-3">

    {qData.length > 0 &&  qData[index].q_with_lang ? 
        <div class="row">

            <div class="col-md-9" >
            {(qData.length > 0 && qData[index].q_lang_audio &&  qData[index].q_lang_audio.q_audio)  ? (
                  
             <audio ref={qAudioRef} onEnded={changeHighlightState}>
            <source  src={imageUrl + qData[index].q_lang_audio.q_audio} type="audio/mpeg" />
              </audio>
                ) : (
                  ''
                )}

     

                {/* <audio ref={qAudioRef} src='/media/audio/audio.mp3'/> */}

               

            <div style={{marginRight: "50px"}}>
                <ul class="no-li-style-rtl" style={{fontSize: "30px",margin:"20px"}}>
                    <li style={{margin:"20px"}}>
                     <h3 class="mt-5 cursor"    onClick={() =>{
                     handlePlayAudio('q',index);
                
                     } }
                    
                    style={{color: isHighlightedQAudio ? 'blue' : ''}}>
                     {qData.length > 0 && qData[index].q_with_lang ? qData[index].q_with_lang.q_content : ''}
                     </h3> 
             
                     </li>

                  
                    <li style={{margin:"20px"}}>
                        <label class="container-rtl">
                            <input type="radio"  name="opt"  value="a" 
                            onChange={(e) => {
                          handleChangeRadioOptions(index, 'optA', e.target.value)
                        }}
                        checked={
                          selectedOptions[index] && selectedOptions[index].optA === 'a'
                            ? 'checked'
                            : ''
                        }

                        disabled={selectedOptions[index] ? selectedOptions[index].isDisable : ''}
                            />
                            <span class="checkmark-rtl"></span>
                        </label>
                      
                        <span className='cursor'     onClick={() =>{
                        handlePlayAudio('a',index);
                
                     } }
                     style={{color: isHighlightedOptA ? 'blue' : ''}}
                      > {qData.length > 0 &&  qData[index].q_with_lang.opt_a ? qData[index].q_with_lang.opt_a : ''}</span>




                        {qData[index] && qData[index].opt_a_image != '' ? (
                        <img
                          src={imageUrl + qData[index].opt_a_image}
                          alt=''
                          style={{height: "60px"}} width="auto" 
                          className='zoom'
                        />
                      ) : (
                        ''
                      )}


                     

                    </li>

                  
                 
                    <li className='cursor'  style={{margin:"20px"}}>
                        <label class="container-rtl">
                            <input type="radio"name="opt" value="b"
                               onChange={(e) => {
                          handleChangeRadioOptions(index, 'optB', e.target.value)
                        }}
                        checked={
                          selectedOptions[index] && selectedOptions[index].optB === 'b'
                            ? 'checked'
                            : ''
                        }
                        disabled={selectedOptions[index] ? selectedOptions[index].isDisable : ''}
                             />
                            <span class="checkmark-rtl"></span>

                        </label>
                        <span  
                        onClick={() =>{
                        handlePlayAudio('b',index);
                
                     } }
                     style={{color: isHighlightedOptB ? 'blue' : ''}}
                        > {qData.length > 0 ? qData[index].q_with_lang.opt_b : ''}</span>
                
              
                        {qData[index] && qData[index].opt_b_image != '' ? (
                        <img
                          src={imageUrl + qData[index].opt_b_image}
                          alt=''
                          style={{height: "60px"}} width="auto" 
                          className='zoom'
                        />
                      ) : (
                        ''
                      )}

 
                
       

                    </li>

                    <li className='cursor'  style={{margin:"20px"}}>
                        <label class="container-rtl">
                            <input type="radio"  name="opt" value="c"
                                       onChange={(e) => {
                          handleChangeRadioOptions(index, 'optC', e.target.value)
                        }}
                        checked={
                          selectedOptions[index] && selectedOptions[index].optC === 'c'
                            ? 'checked'
                            : ''
                        }

                        disabled={selectedOptions[index] ? selectedOptions[index].isDisable : ''}
                             />
                            <span class="checkmark-rtl"></span>
                        </label>
                     
                     <span   
                        onClick={() =>{
                        handlePlayAudio('c',index);
                
                     } }
                     style={{color: isHighlightedOptC ? 'blue' : ''}}
                     > {qData.length > 0 ? qData[index].q_with_lang.opt_c : ''}</span>
                     {qData[index] && qData[index].opt_c_image != '' ? (
                        <img
                          src={imageUrl + qData[index].opt_c_image}
                          alt=''
                          style={{height: "60px"}} width="auto" 
                          className='zoom'
                        />
                      ) : (
                        ''
                      )}
                    

               

                    </li>

               
                   

                </ul>
            </div>
            </div>
            <div class="col-md-3">
             
                {qData[index] && qData[index].q_image != '' ? (
                  <img
                   className='img-fluid img-thumbnail zoom'
                    src={imageUrl + qData[index].q_image}
                    alt=''
                    style={{height: '300px'}}
                    width='auto'
                  />
                ) : (
                  ''
                )}
            </div>
        </div>
:''}
    </div>
</main>


<div
          className='footer mt-auto py-3'
          style={{backgroundColor: '#ff9900', marginRight: '10px', marginLeft: '10px'}}
        >
          <div className='container'>
            <span className='text-muted'>
        
            {index+1 != totalQuestion ? (
              <button
                style={customStyle.button}
                onClick={() => {
                  handleNextPrevClick('next');
                  handleSubmit(qData[index].id)
                  changeHighlightState()
                }}
              >
               
                <i className='fa fa-angle-double-left' aria-hidden='true'></i>اگلے
              </button>
            ):
           
            <button
                style={customStyle.button}
                onClick={() => {
                  handleNextPrevClick('next')
                  handleSubmit(qData[index].id)
                  handelExit()
               
                }}
              >
               
              ختم
              </button>
            }
           

            </span>
            <span className='text-muted float-right'>
            {index != 0 ? (

              <button
                className='btn btn-default'
             
                style={{
                  backgroundColor: '#fff',
                  border: 'none',
                  color: 'black',
                  padding: '15px 32px',
                  textAlign: 'center',
                  textDecoration: 'none',
                  display: 'inline-block',
                  fontSize: '16px',
                  float: 'right',
                }}

                onClick={() => {
                  handleNextPrevClick('prev')
                }}
              >
                پچھلا<i class='fa fa-angle-double-right' aria-hidden='true'></i>
              </button>

            ):''}

            </span>
          </div>
        </div>

        </div>
      </>
   
  )
}

export default ActualRtlExam
