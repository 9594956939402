import React, {useEffect, useRef, useState} from 'react'
import '../../assets/styles/css/PractiseStyle.css'
import {getAllQuestionsForPractice, getVideoQuestionForExam, saveMcqsForPractExam} from '../../apis/QuestionsBankApi'
import {toastError} from '../../global/Global'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import { auto } from '@popperjs/core'
import ProgressBar from '../../partials/ProgressBar'


const customStyle = {
  navSection: {
    position: 'absolute',
    right: 0,
    top: '5px',
  },
  headerSection: {
    margin: '1px 10px 10px',
    height: '5rem',
  },
  switchButon: {
    marginLeft: '5px',
  },

  button: {
    backgroundColor: '#fff' /* Green */,
    border: 'none',
    color: 'black',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
  }

}

const VideoExam = () => {

  let {attempt} = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [qData, setQData] = useState([])
  const [index, setIndex] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [questionPerc, setQuestionPerc] = useState(0)
  const [ans, setAns] = useState(2)
  const imageUrl = process.env.REACT_APP_IMAGE_URL

  const [isPlaying, setIsPlaying] = useState(false)

  const [isHighlightedQAudio, setIsHighlightedQAudio] = useState(false)
  const [isHighlightedOptA, setIsHighlightedOptA] = useState(false)
  const [isHighlightedOptB, setIsHighlightedOptB] = useState(false)
  const [isHighlightedOptC, setIsHighlightedOptC] = useState(false)
  const [isMcqsShow, setIsMcqsShow] = useState(false)
  const [isVidSectionShow, setIsVidSectionShow] = useState(true)
  const [count, setCount] = useState(0);
  const [stdName, setStdName] = useState('')
  const [stdId, setStdId] = useState('')


  const optAAudioRef = React.createRef()
  const optBAudioRef = React.createRef()
  const optCAudioRef = React.createRef()


  const [selectedOptions, setSelectedOptions] = useState([
    {
      optA: '',
      optB: '',
      optC: '',
      correctAns: '2',
      isDisable: false,
    },
  ])


  const handleChangeRadioOptions = (index, property, value) => {

    value && value === qData[index].correct_opt ? setAns(1) : setAns(0)

    const newArray = [...selectedOptions]
    const updatedObject = {...newArray[index]}

    updatedObject['isDisable'] = true
    updatedObject['correctAns'] = value && value === qData[index].correct_opt ? 1 : 0

    if (value == 'a' && property == 'optA') {
      updatedObject[property] = value
      updatedObject['optB'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'b' && property == 'optB') {
      updatedObject[property] = value
      updatedObject['optA'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'c' && property == 'optC') {
      updatedObject[property] = value
      updatedObject['optA'] = ''
      updatedObject['optB'] = ''
      newArray[index] = updatedObject
    }
    
    setSelectedOptions(newArray)
    handleSubmit(qData[index].id,value && value === qData[index].correct_opt ? 1 : 0);
  
  }

  const handleGetAllVideoQuestions = async () => {
    setIsLoading(true)

    let data = JSON.stringify({
  
      std_id: sessionStorage.getItem('stdId'),
      exam_type:0,
      attempt:attempt
    })


    await getVideoQuestionForExam(data)
      .then((response) => {
        //  console.log('Video',response.data.data.examQuestions.length)
         if(response.data.length==0){
          toastError('Video content not available')
         }
        if (response.data.code != 206) {

          setQData(response.data.data.examQuestions)
          setTotalQuestion(response.data.data.examQuestions.length)
          setIsLoading(false)
        } else {
          setQData([])
          toastError('Content not available')
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleSubmit = async (qId,optAns) => {
    setIsLoading(true)
    let data = JSON.stringify({
      std_id: stdId,
      q_id: qId,
      ans: optAns,
      exam_type:0,
      q_type:2,
      attempt:attempt
    })

    await saveMcqsForPractExam(data)
      .then((response) => {
        setIsLoading(false)
        console.log('Q Responce', response.data.code)
        if (response.data.code == 201) {

          setCount(count + 1);
          calculatePerc(count + 1)
        }
        if (response.data.error) {
          toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const handleNextPrevClick = (type) => {
   
 
    setAns(2)
    if (type == 'next' && index < qData.length - 1) {
      setIndex(index + 1)
    }
    if (type == 'prev' && index > 0) {
      setIndex(index - 1)
    }
   
      setIsVidSectionShow(true);
      setIsMcqsShow(false);
   

    calculatePerc(index)
  }

  const changeHighlightState = () => {
    setIsHighlightedQAudio(false)
    setIsHighlightedOptA(false)
    setIsHighlightedOptB(false)
    setIsHighlightedOptC(false)
  }

  const handlePlayAudio = (type, index) => {
    setIsPlaying(!isPlaying)

    if (type == 'a' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_a_audio) {
      if (isPlaying) {
        setIsHighlightedOptA(true)

        optBAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptB(false)
        setIsHighlightedOptC(false)

        optAAudioRef.current.play()
      } else {
        setIsHighlightedOptA(false)
        optAAudioRef.current.pause()
      }
    }

    if (type == 'b' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_b_audio) {
      if (isPlaying) {
        setIsHighlightedOptB(true)

        optAAudioRef.current.pause()
        optCAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptA(false)
        setIsHighlightedOptC(false)

        optBAudioRef.current.play()
      } else {
        setIsHighlightedOptB(false)
        optBAudioRef.current.pause()
      }
    }

    if (type == 'c' && qData[index].q_lang_audio && qData[index].q_lang_audio.opt_c_audio) {
      if (isPlaying) {
        setIsHighlightedOptC(true)

     
        optAAudioRef.current.pause()
        optBAudioRef.current.pause()
        setIsHighlightedQAudio(false)
        setIsHighlightedOptA(false)
        setIsHighlightedOptB(false)

        optCAudioRef.current.play()
      } else {
        setIsHighlightedOptC(false)
        optCAudioRef.current.pause()
      }
    }
  }

  const getStdInfo = () => {
    setStdName(sessionStorage.getItem('stdName'))
    setStdId(sessionStorage.getItem('stdId'))
  }


  const calculatePerc = (solvedCounter) => {

    const calc = (solvedCounter * 100) / totalQuestion
    setQuestionPerc(calc)
  }

  const handelExit = (id) => {
    Swal.fire({
      text: 'Are you sure you want to exit from Practice test Next time,it will be start from beginning?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes, Exit Now!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/std-dashboard')
      }
    })
  }

  const handleFinish = (id) => {

    setIsVidSectionShow(false);
    setIsMcqsShow(false);

    Swal.fire({
      text: ' Practice questions finished, Press below button to logout?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Logout!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/std-dashboard')
      }
    })
  }

  const handleVideoEnded = () => {
 setIsMcqsShow(true);
 setIsVidSectionShow(false);
  }



  useEffect(() => {
    handleGetAllVideoQuestions()
    getStdInfo();
  
  }, [])

  return (
    <>
      <div className='d-flex flex-column h-100'>
        <header style={customStyle.headerSection}>
          <nav
            className='navbar navbar-expand-lg navbar-light'
            style={{backgroundColor: '#ff9900'}}
          >
            <button
              className='navbar-brand'
              style={customStyle.switchButon}
              onClick={() => {
                handelExit()
              }}
            >
              <i className='fa fa-power-off'></i>
            </button>
            <a className='navbar-brand' href='#'>
              Practice Driving Test - {stdName}
            </a>

            <div className='collapse navbar-collapse' style={customStyle.navSection}>
              <ul className='navbar-nav mr-auto'></ul>
              <ul
                className='no-li-style'
                style={{marginTop: '-5px;bottom:-5px', fontSize: '22px', fontFamily: 'system-ui'}}
              >
                <li>

                   <ProgressBar progress={questionPerc.toFixed(1)} />
                </li>

              
              </ul>
              <ul style={{listStyleType: 'none', marginTop: '-5px', bottom: '-5px'}}>
                <li> {/* <span className='navbar-custom-text'>Time Remaining:185/30</span> */}</li>
                <li>
                  {' '}
                  <span className='navbar-custom-text'>
                    Question Attempted:{index + 1}/{totalQuestion}
                  </span>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <main class='flex-shrink-0'>
          <div class='mt-3'>
            <div class='row'>
         
          {isMcqsShow?
              <div class='col-md-12'>

                {qData.length > 0 &&
                qData[index].q_lang_audio &&
                qData[index].q_lang_audio.opt_a_audio ? (
                  <audio ref={optAAudioRef} onEnded={changeHighlightState}>
                    <source
                      src={imageUrl + qData[index].q_lang_audio.opt_a_audio}
                      type='audio/mpeg'
                    />
                  </audio>
                ) : (
                  ''
                )}
                {qData.length > 0 &&
                qData[index].q_lang_audio &&
                qData[index].q_lang_audio.opt_b_audio ? (
                  <audio ref={optBAudioRef} onEnded={changeHighlightState}>
                    <source
                      src={imageUrl + qData[index].q_lang_audio.opt_b_audio}
                      type='audio/mpeg'
                    />
                  </audio>
                ) : (
                  ''
                )}
                {qData.length > 0 &&
                qData[index].q_lang_audio &&
                qData[index].q_lang_audio.opt_c_audio ? (
                  <audio ref={optCAudioRef} onEnded={changeHighlightState}>
                    <source
                      src={imageUrl + qData[index].q_lang_audio.opt_c_audio}
                      type='audio/mpeg'
                    />
                  </audio>
                ) : (
                  ''
                )}
                <ul className='no-li-style' style={{fontSize: '30px', margin: '20px'}}>


           
                  <li style={{margin: '20px'}}>
                    <label className='radio-container '>
                      <input
                        className='inpRadio'
                        type='radio'
                        name='opt'
                        value='a'
                        onChange={(e) => {
                          handleChangeRadioOptions(index, 'optA', e.target.value)
                        }}
                        checked={
                          selectedOptions[index] && selectedOptions[index].optA === 'a'
                            ? 'checked'
                            : ''
                        }
                        disabled={selectedOptions[index] ? selectedOptions[index].isDisable : ''}
                      />
                      <span className='checkmark'></span>
                    </label>

                    <span
                      className='radio-span cursor  d-inline'
                      onClick={() => {
                        handlePlayAudio('a', index)
                      }}
                      style={{color: isHighlightedOptA ? 'blue' : ''}}
                    >
                      {qData.length > 0 ? qData[index].q_with_lang.opt_a : ''}
                    </span>
                    {qData[index] && qData[index].opt_a_image != '' ? (
                      <img
                        src={imageUrl + qData[index].opt_a_image}
                        alt=''
                        style={{height: '70px', marginLeft: '40px'}}
                        width='80px'
                        className='radio-container zoom  d-inline'
                      />
                    ) : (
                      ''
                    )}


                    {
                      qData[index]  && selectedOptions[index] &&  selectedOptions[index].optA== qData[index].correct_opt?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }

                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optA!='' && selectedOptions[index].optA!= qData[index].correct_opt?
            
                    <span> <i className='fa fa-times d-inline' style={{color: 'red'}}></i> </span>
                    :
                   ''
                    }

                 

            

                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optB &&  qData[index].correct_opt=='a'?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }

                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optC &&  qData[index].correct_opt=='a'?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }


                
    
                  </li>

                  <li style={{margin: '20px'}}>
                    <label class='radio-container'>
                      <input
                        type='radio'
                        className='inpRadio'
                        name='opt'
                        value='b'
                        onChange={(e) => {
                          handleChangeRadioOptions(index, 'optB', e.target.value)
                        }}
                        checked={
                          selectedOptions[index] && selectedOptions[index].optB === 'b'
                            ? 'checked'
                            : ''
                        }
                        disabled={selectedOptions[index] ? selectedOptions[index].isDisable : ''}
                      />
                      <span className='checkmark'></span>
                    </label>

                    <span
                      className='radio-span cursor d-inline'
                      onClick={() => {
                        handlePlayAudio('b', index)
                      }}
                      style={{color: isHighlightedOptB ? 'blue' : ''}}
                    >
                      {qData.length > 0 ? qData[index].q_with_lang.opt_b : ''}
                    </span>
                    {qData[index] && qData[index].opt_b_image != '' ? (
                      <img
                        src={imageUrl + qData[index].opt_b_image}
                        alt=''
                        style={{height: '70px', marginLeft: '40px'}}
                        width='80px'
                        className='radio-container zoom d-inline'
                      />
                    ) : (
                      ''
                    )}



                    {
                      qData[index]  && selectedOptions[index] &&  selectedOptions[index].optB== qData[index].correct_opt?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }

                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optB!='' && selectedOptions[index].optB!= qData[index].correct_opt?
            
                    <span> <i className='fa fa-times d-inline' style={{color: 'red'}}></i> </span>
                    :
                   ''
                    }

                 


                      
                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optA &&  qData[index].correct_opt=='b'?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }

                      
                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optC &&  qData[index].correct_opt=='b'?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }


                 
                  </li>

                  <li style={{margin: '20px'}}>
                    <label className='radio-container'>
                      <input
                        type='radio'
                        className='inpRadio'
                        name='opt'
                        value='c'
                        onChange={(e) => {
                          handleChangeRadioOptions(index, 'optC', e.target.value)
                        }}
                        checked={
                          selectedOptions[index] && selectedOptions[index].optC === 'c'
                            ? 'checked'
                            : ''
                        }
                        disabled={selectedOptions[index] ? selectedOptions[index].isDisable : ''}
                      />
                      <span className='checkmark '></span>
                    </label>

                    <span
                      className='radio-span cursor d-inline'
                      onClick={() => {
                        handlePlayAudio('c', index)
                      }}
                      style={{color: isHighlightedOptC ? 'blue' : ''}}
                    >
                      {qData.length > 0 ? qData[index].q_with_lang.opt_c : ''}
                    </span>

                    {qData[index] && qData[index].opt_c_image != '' ? (
                      <img
                        src={imageUrl + qData[index].opt_c_image}
                        alt=''
                        style={{height: '70px', marginLeft: '40px'}}
                        width='80px'
                        className='radio-container zoom d-inline'
                      />
                    ) : (
                      ''
                    )}


                    {
                      qData[index]  && selectedOptions[index] &&  selectedOptions[index].optC== qData[index].correct_opt?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }

                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optC!='' && selectedOptions[index].optC!= qData[index].correct_opt?
            
                    <span> <i className='fa fa-times d-inline' style={{color: 'red'}}></i> </span>
                    :
                   ''
                    }

              
                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optA &&  qData[index].correct_opt=='c'?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }

                    {
                      qData[index]  && selectedOptions[index] && selectedOptions[index].optB &&  qData[index].correct_opt=='c'?
            
                    <span> <i className='fa fa-check d-inline' style={{color: 'green'}}></i> </span>
                    :
                   ''
                    }
         

                  </li>
                </ul>
              </div>
            :''}
  
            {isVidSectionShow?

              <div className='video-container '>
            
              
     
              {qData.length > 0 &&
                qData[index].q_video ? (
                  <video  autoplay="autoplay" controls  onEnded={handleVideoEnded} >
                  <source src={imageUrl + qData[index].q_video}    /> 
                 
                  </video>
                ) : (
                  ''
                )}
            
          
              </div>
              :''}
          
            </div>
          </div>
        </main>

        {isMcqsShow?
        <div className='footer mt-auto py-3'
          style={{backgroundColor: '#ff9900', marginRight: '10px', marginLeft: '10px'}}
        >
          <div className='container'>
            <span className='text-muted'>
              {index != 0 ? (
                <button
                  style={customStyle.button}
                  onClick={() => {
                    handleNextPrevClick('prev')
                  }}
                >
                  {' '}
                  <i className='fa fa-angle-double-left' aria-hidden='true'></i>PREVIOUS
                </button>
              ) : (
                ''
              )}

            </span>
            <span className='text-muted float-right'>
              {index + 1 != totalQuestion ? (
                <button
                  className='btn btn-default'
                  onClick={() => {
                    handleNextPrevClick('next')
                    // handleSubmit(qData[index].id)
                    changeHighlightState()
                  }}
                  style={{
                    backgroundColor: '#fff',
                    border: 'none',
                    color: 'black',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    float: 'right',
                  }}
                >
                  NEXT
                  <i class='fa fa-angle-double-right' aria-hidden='true'></i>
                </button>
              ) : (
                <button
                  className='btn btn-default'
                  onClick={() => {
                    handleNextPrevClick('next')
                    // handleSubmit(qData[index].id)
                    handleFinish()
                  }}
                  style={{
                    backgroundColor: '#fff',
                    border: 'none',
                    color: 'black',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    float: 'right',
                  }}
                >
                  Finish
                  <i class='fa fa-hourglass-end"' aria-hidden='true'></i>
                </button>
              )}
            </span>
          </div>
        </div>
        :''}
      </div>

    </>
  )
}

export default VideoExam
