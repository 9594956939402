import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getAllCourses= async (pageNo)=>{
    var config = {
      method: 'get',
      url: `${API_URL}/get-all-cat?page=${pageNo}`,
    };

  const response=await axios(config)
  return response;
}



export const saveCategory= async (data)=>{
 
  var config = {
    method: 'post',
    url: `${API_URL}/save-cat`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const deleteCategory= async (data)=>{
 
  var config = {
    method: 'delete',
    url: `${API_URL}/delete-cat`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const editCategory= async (data,userInfo)=>{
 

  var config = {
    method: 'post',
    url: `${API_URL}/edit-cat`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const updateCategory= async (data,userInfo)=>{
  var config = {
    method: 'post',
    url: `${API_URL}/update-cat`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const getCourseForDropdown= async ()=>{
  
  var config = {
    method: 'get',
    url: `${API_URL}/get-course-dropdown`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
  };

const response=await axios(config)
return response;
}

export const saveCourseConfig= async (data)=>{
 
  var config = {
    method: 'post',
    url: `${API_URL}/save-course-config`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const getCourseConfigInfo= async (data,userInfo)=>{
 

  var config = {
    method: 'post',
    url: `${API_URL}/get-course-config-info`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const countAllAudioVidQuestions= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/count-course-audio-video-question`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}





