import React, { useEffect, useState } from 'react'
import { getPracticeExamResult } from '../apis/ExamApi'
import { toastError } from '../global/Global'
import { Link, useParams } from 'react-router-dom'
import { PaginationControl } from 'react-bootstrap-pagination-control'

const PracticeReportDetail = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [examResult, setExamResult] = useState([])
  const [totalQuestion, setTotalQuestion] = useState('')
  const [vidTotalQuestion, setVidTotalQuestion] = useState('')
  const [attemptId, setAttemptId] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(0)

  let {stdId} = useParams()


  const imageUrl = process.env.REACT_APP_IMAGE_URL



  const handleAttemptId = async (attempt) => {
    setAttemptId(attempt)
  }



  const handlePracticeExamResult = async (id) => {
    let data = JSON.stringify({
      std_id: stdId,
    })

    await getPracticeExamResult(data)
      .then((response) => {
        if (response.data.code != 206) {
          setVidTotalQuestion(response.data.data.videoTotalQuestion)
          setTotalQuestion(response.data.data.totalQuestion)
          setExamResult(response.data.data.resultData)
        } else {
          toastError('Content not available')
          setExamResult('')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    handlePracticeExamResult()

  }, [])

  return (
    <>
      <div>
      <h2>Practice List</h2>
        <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    class='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Exam'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

         
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>

              <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th className='min-w-125px'>Student</th>
                        <th className='min-w-125px'>Attempt#</th>
                        <th className='min-w-125px'>Attempt Date</th>
                        <th className='text-end min-w-100px'>Total Q</th>
                        <th className='text-end min-w-100px'>Correct Ans</th>
                        <th className='text-end min-w-100px'>Wrong Ans</th>
                        <th className='text-end min-w-100px'>Skip</th>
                        <th className='text-end min-w-100px'>Type</th>

                      </tr>
                    </thead>


                <tbody className='text-gray-600 fw-bold'>
                      {examResult
                        ? examResult.map((item,key) => {
                            return (
                              <tr>
                               <td class='d-flex align-items-center'>
                                <div class='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <a>
                                    <div class='symbol-label'>
                                      <img src={imageUrl + item.student.image} />
                                    </div>
                                  </a>
                                </div>

                                <div class='d-flex flex-column'>
                                  <a>{item.student.name}</a>
                                  <span>{item.student.tarffic_id}</span>
                                </div>
                              </td>
                                <td className='min-w-125px'>{key+1}</td>
                                <td className='min-w-125px'>{item.created_at}</td>
                                {item.exam_type == 1 ? (
                                  <td className='text-end min-w-100px'>{totalQuestion}</td>
                                ) : (
                                  ''
                                )}

                                {item.exam_type == 2 ? (
                                  <td className='text-end min-w-100px'>{vidTotalQuestion}</td>
                                ) : (
                                  ''
                                )}

                                <td className='text-end min-w-100px'>{item.correct}</td>
                                <td className='text-end min-w-100px'>{item.wrong}</td>

                                {item.exam_type == 1 ? (
                                  <td className='text-end min-w-100px'>
                                    {parseInt(totalQuestion) -
                                      (parseInt(item.correct) + parseInt(item.wrong))}
                                  </td>
                                ) : (
                                  <td className='text-end min-w-100px'>
                                    {parseInt(vidTotalQuestion) -
                                      (parseInt(item.correct) + parseInt(item.wrong))}
                                  </td>
                                )}

                                <td className='text-end min-w-100px'>
                                  {item.exam_type == 1 ? 'Standard' : 'Video'}
                                </td>

                           
                              </tr>
                            )
                          })
                        : ''}
                    </tbody>
              
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                   
                    handlePracticeExamResult(page)
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default PracticeReportDetail