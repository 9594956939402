import React, {useEffect, useState} from 'react'
import {getDashBoardStat} from '../apis/DashboardApi'
import topGreenImg from '../../components/assets/img/top-green.png'
import CoursesSection from './CoursesSection'
import ChartSection from './ChartSection'

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [totalCourse, setTotalCourse] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [totalStudents, setTotalStudents] = useState(0)
  const [totalResults, setTotalResults] = useState(0)
  const [courseInfo, setCourseInfo] = useState([])
  const [resultSection, setResultSection] = useState([])
  const [todayRecvResults, setTodayRecvResults] = useState(0)
  const [passResults, setPassResults] = useState(0)
  const [failResults, setFailResults] = useState(0)

  const handleDashBoardStat = async () => {
    setIsLoading(true)

    await getDashBoardStat()
      .then((response) => {
  
  if(response.data.code){

  setTotalCourse(response.data.data.totalCourse)
  setTotalQuestion(response.data.data.totalQuestion)
  setTotalStudents(response.data.data.totalStudent)
  setTotalResults(response.data.data.resultSection.results.totalRes)
  setCourseInfo(response.data.data.courseInfo)
  setResultSection(response.data.data.resultSection)
  setTodayRecvResults(response.data.data.resultSection.results.todayRes)
  setPassResults(response.data.data.resultSection.results.passRes)
  setFailResults(response.data.data.resultSection.results.failRes)

  }else{
    setTotalCourse(0)
    setTotalQuestion(0)
    setTotalStudents(0)
    setTotalResults(0)
    setCourseInfo([])
  }
     

        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    handleDashBoardStat()
  }, [])

  const courseProps = {
    totalQuestion: totalQuestion,
    totalCourse: totalCourse,
    totalResults: totalResults,
    courseInfo: courseInfo,
  }

  return (
    <>
		<div id="kt_content_container" className="container-xxl">

		<div className="row g-5 g-xl-12">
     	 <div className='col-xl-4 mb-5 mb-xl-12'>
	
        <div className='card card-flush h-xl-100'>
          <div
            className='card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px'
            style={{backgroundImage: `url(${topGreenImg})`}}
          >
            <h3 className='card-title align-items-start flex-column text-white pt-15'>
              <span className='fw-bolder fs-2x mb-3'>Hello, {window.localStorage.userName} </span>
              <div className='fs-4 text-white'>
                <span className='opacity-75'>You have received {todayRecvResults} results today</span>
                <span className='position-relative d-inline-block'>
               

                  <span class='position-absolute opacity-50 bottom-0 start-0 border-2 border-white border-bottom w-100'></span>
                </span>
                <span className='opacity-75'></span>
              </div>
            </h3>
          </div>

          <div className='card-body mt-n20'>
            <div className='mt-n20 position-relative'>
              <div className='row g-3 g-lg-6'>
                <div className='col-md-6'>
                  <div className='bg-light-warning bg-opacity-70 rounded-2 px-6 py-5'>
                    <span class="symbol-label bg-light-primary ms-15">
                        <span class="svg-icon svg-icon-4x svg-icon-warning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="black"/>
<path d="M8.70001 6C8.10001 5.7 7.39999 5.40001 6.79999 5.10001C7.79999 4.00001 8.90001 3 10.1 2.2C10.7 2.1 11.4 2 12 2C12.7 2 13.3 2.1 13.9 2.2C12 3.2 10.2 4.5 8.70001 6ZM12 8.39999C13.9 6.59999 16.2 5.30001 18.7 4.60001C18.1 4.00001 17.4 3.6 16.7 3.2C14.4 4.1 12.2 5.40001 10.5 7.10001C11 7.50001 11.5 7.89999 12 8.39999ZM7 20C7 20.2 7 20.4 7 20.6C6.2 20.1 5.49999 19.6 4.89999 19C4.59999 18 4.00001 17.2 3.20001 16.6C2.80001 15.8 2.49999 15 2.29999 14.1C4.99999 14.7 7 17.1 7 20ZM10.6 9.89999C8.70001 8.09999 6.39999 6.9 3.79999 6.3C3.39999 6.9 2.99999 7.5 2.79999 8.2C5.39999 8.6 7.7 9.80001 9.5 11.6C9.8 10.9 10.2 10.4 10.6 9.89999ZM2.20001 10.1C2.10001 10.7 2 11.4 2 12C2 12 2 12 2 12.1C4.3 12.4 6.40001 13.7 7.60001 15.6C7.80001 14.8 8.09999 14.1 8.39999 13.4C6.89999 11.6 4.70001 10.4 2.20001 10.1ZM11 20C11 14 15.4 9.00001 21.2 8.10001C20.9 7.40001 20.6 6.8 20.2 6.2C13.8 7.5 9 13.1 9 19.9C9 20.4 9.00001 21 9.10001 21.5C9.80001 21.7 10.5 21.8 11.2 21.9C11.1 21.3 11 20.7 11 20ZM19.1 19C19.4 18 20 17.2 20.8 16.6C21.2 15.8 21.5 15 21.7 14.1C19 14.7 16.9 17.1 16.9 20C16.9 20.2 16.9 20.4 16.9 20.6C17.8 20.2 18.5 19.6 19.1 19ZM15 20C15 15.9 18.1 12.6 22 12.1C22 12.1 22 12.1 22 12C22 11.3 21.9 10.7 21.8 10.1C16.8 10.7 13 14.9 13 20C13 20.7 13.1 21.3 13.2 21.9C13.9 21.8 14.5 21.7 15.2 21.5C15.1 21 15 20.5 15 20Z" fill="black"/>
</svg>
                        </span>
                    </span>
                  
                    <div className='m-0'>
                      <span className='text-gray-700 fw-boldest d-block fs-2qx lh-1 mb-1'>
                        {totalCourse}
                      </span>

                      <span className='text-gray-500 fw-bold fs-6'>Courses</span>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='bg-light-success bg-opacity-70 rounded-2 px-6 py-5'>
                  	<span class="symbol-label bg-light-success ms-20">
                      <span class="svg-icon svg-icon-success svg-icon-4x">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path opacity="0.3" d="M16.163 17.55C17.0515 16.6633 17.6785 15.5488 17.975 14.329C18.2389 13.1884 18.8119 12.1425 19.631 11.306L12.694 4.36902C11.8574 5.18796 10.8115 5.76088 9.67099 6.02502C8.15617 6.3947 6.81277 7.27001 5.86261 8.50635C4.91245 9.74268 4.41238 11.266 4.44501 12.825C4.46196 13.6211 4.31769 14.4125 4.0209 15.1515C3.72412 15.8905 3.28092 16.5617 2.71799 17.125L2.28699 17.556C2.10306 17.7402 1.99976 17.9897 1.99976 18.25C1.99976 18.5103 2.10306 18.7598 2.28699 18.944L5.06201 21.719C5.24614 21.9029 5.49575 22.0062 5.75601 22.0062C6.01627 22.0062 6.26588 21.9029 6.45001 21.719L6.88101 21.288C7.44427 20.725 8.11556 20.2819 8.85452 19.9851C9.59349 19.6883 10.3848 19.5441 11.181 19.561C12.1042 19.58 13.0217 19.4114 13.878 19.0658C14.7343 18.7201 15.5116 18.2046 16.163 17.55Z" fill="black"/>
<path d="M19.631 11.306L12.694 4.36902L14.775 2.28699C14.9591 2.10306 15.2087 1.99976 15.469 1.99976C15.7293 1.99976 15.9789 2.10306 16.163 2.28699L21.713 7.83704C21.8969 8.02117 22.0002 8.27075 22.0002 8.53101C22.0002 8.79126 21.8969 9.04085 21.713 9.22498L19.631 11.306ZM13.041 10.959C12.6427 10.5604 12.1194 10.3112 11.5589 10.2532C10.9985 10.1952 10.4352 10.332 9.96375 10.6405C9.4923 10.949 9.14148 11.4105 8.97034 11.9473C8.79919 12.4841 8.81813 13.0635 9.02399 13.588L2.98099 19.631L4.36899 21.019L10.412 14.975C10.9364 15.1816 11.5161 15.2011 12.0533 15.0303C12.5904 14.8594 13.0523 14.5086 13.361 14.037C13.6697 13.5654 13.8065 13.0018 13.7482 12.4412C13.6899 11.8805 13.4401 11.357 13.041 10.959Z" fill="black"/>
</svg></span>
																			
																		</span>																	
                    <div className='m-0'>
                      <span className='text-gray-700 fw-boldest d-block fs-2qx lh-1 mb-1'>
                        {totalQuestion}
                      </span>

                      <span className='text-gray-500 fw-bold fs-6'>Questions</span>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='bg-light-danger bg-opacity-70 rounded-2 px-6 py-5'>
                      <span className='symbol-label bg-light-danger ms-20'>
                        <span class="svg-icon svg-icon-danger svg-icon-4x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"/>
            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
        </g>
    </svg></span>
                      </span>
                 
                    <div className='m-0'>
                      <span className='text-gray-700 fw-boldest d-block fs-2qx lh-1 mb-1'>
                        {totalStudents}
                      </span>

                      <span className='text-gray-500 fw-bold fs-6'>Students</span>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='bg-light-primary bg-opacity-70 rounded-2 px-6 py-5'>
                      <span className='symbol-label bg-light-primary ms-20'>
                      <span class="svg-icon svg-icon-primary svg-icon-4x">
                     <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
        <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
        <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
    </g>
</svg>
</span>
                      </span>
                    

                    <div className='m-0'>
                      <span className='text-gray-700 fw-boldest d-block fs-2qx lh-1 mb-1'>
                        {totalResults}
                      </span>

                      <span className='text-gray-500 fw-bold fs-6'>Exam</span>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='bg-light-info bg-opacity-70 rounded-2 px-6 py-5'>
                      <span className='symbol-label bg-light-info ms-20'>

                      <span class="svg-icon svg-icon-info svg-icon-4x">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
        <path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000"/>
    </g>
</svg></span>
                      </span>
                    

                    <div className='m-0'>
                      <span className='text-gray-700 fw-boldest d-block fs-2qx lh-1 mb-1'>
                        {failResults}
                      </span>

                      <span className='text-gray-500 fw-bold fs-6'>Student Fail</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='bg-gray-200 bg-opacity-70 rounded-2 px-6 py-5'>
                      <span className='symbol-label bg-light-200 ms-20'>
                      <span class="svg-icon svg-icon svg-icon-4x">
                   <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
        <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
    </g>
</svg></span>
                      </span>
                    

                    <div className='m-0'>
                      <span className='text-gray-700 fw-boldest d-block fs-2qx lh-1 mb-1'>
                        {passResults}
                      </span>

                      <span className='text-gray-500 fw-bold fs-6'>Student Pass</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

	  <div class='col-xl-8 mb-5 mb-xl-10'>
        <div class='row g-5 g-xl-10'>

   <CoursesSection courseProps={courseProps} resultProps={resultSection}/>
   <ChartSection/>

        </div>
      </div>

   


	
	  </div>
	  </div>
    </>
  )
}

export default AdminDashboard
