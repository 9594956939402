import React from 'react'
import { useTranslation } from 'react-i18next';

const ReviewInstruction = (props) => {
  const { t, i18n } = useTranslation();
  const {direction} = props;
  return (
    <div>
          <div id='kt_app_content' className='app-content  flex-column-fluid ' dir={direction==2?'rtl':'ltr'}>
          <div id='kt_app_content_container' className='app-container mx-10'>
            <div className='card mb-5 mb-xxl-8'>
              <div className='card-header bg-warning' style={{minHeight: '45px'}}>
                <div className='card-title'>
                  <h3 className='card-label'>  {t('rev_instruction')} </h3>
                </div>
              </div>
              <div class='card-body pt-9'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s,
                <ol type='1'>
                  <li> Lorem Ipsum is simply dummy text of</li>
                  <li> Lorem Ipsum is simply dummy text of</li>
                  <li> Lorem Ipsum is simply dummy text of</li>
                  <li> Lorem Ipsum is simply dummy text of</li>
                  <li> Lorem Ipsum is simply dummy text of</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ReviewInstruction
