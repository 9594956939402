

async function importLocalization(locale) {
    try {
      const localeData = await import(`../../lang/${locale}/homepage.json`);
      return localeData.default;
    } catch (error) {
      console.error(`Error importing localization for ${locale}:`, error);
    }
}

export { importLocalization };
