import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

let homepageEn = require('./lang/en/homepage.json');
let homePageUr=require('./lang/ur/homepage.json');
let homePageAE=require('./lang/ae/homepage.json');

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translations: { ...homepageEn }
			},
			ur: {
				translations: { ...homePageUr }
			},
			ae: {
				translations: { ...homePageAE }
			}
		},
		fallbackLng: "en",
		debug: true,
		ns: ["translations"],
		defaultNS: "translations",
		keySeparator: false,
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
