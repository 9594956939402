import React, { useState,useEffect } from 'react'
import { getConfigInfo, saveConfig } from '../apis/ConfigApi';
import { title } from 'process';
import { swalSuccess, toastError, toastSuccess } from '../global/Global';

const Configration = () => {

	const imageUrl=process.env.REACT_APP_IMAGE_URL;

	const [isLoading, setIsLoading] = useState(false)
	const [isCheckedEmail, setIsCheckedEmail] = useState(false);
	const [isCheckedSms, setIsCheckedSms] = useState(false);
	const [enableEmail, setEnableEmail] = useState(0);
	const [enableSms, setEnableSms] = useState(0);
	const [logo, setLogo] = useState('');
	const [adminBgImg, setAdminBgImg] = useState('');
	const [stdBgImg, setStdBgImg] = useState('');
	const [resLogo, setResLogo] = useState('');
	const [logoFile, setLogoFile] = useState('');
	const [stdFile, setStdFile] = useState('');
	const [adminFile, setAdminFile] = useState('');
	const [resLogoFile, setResLogoFile] = useState('');


	

	const [data, setData] = useState({
		title: '',
		e_host: '',
		e_user_name: '',
		e_password: '',
		e_port: '',
		smtp_secure: '',
		email_template: '',
		sms_template: '',
	  })

	  
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }


  const handleEnabaleEmailChange=()=>{
    isCheckedEmail ? setEnableEmail(0) : setEnableEmail(1)
    setIsCheckedEmail((prev) => !prev)
  
   
  }
  const handleEnabaleSMSChange=()=>{
    isCheckedSms ? setEnableSms(0) : setEnableSms(1)
    setIsCheckedSms((prev) => !prev)
  
  }


  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);


    const FormData = require("form-data");
    let newData = new FormData();
    newData.append("id",1);
	newData.append("title", data.title);
	newData.append("enable_email", enableEmail);
	newData.append("e_host", data.e_host);
	newData.append("e_user_name", data.e_user_name);
	newData.append("e_password", data.e_password);
	newData.append("e_port", data.e_port);
	newData.append("smtp_secure", data.smtp_secure);
	newData.append("email_template", data.email_template);
	newData.append("enable_sms", enableSms);
	newData.append("sms_template", data.sms_template);
	newData.append("logo", logo);
	newData.append("admin_bg_img", adminBgImg);
	newData.append("std_bg_img", stdBgImg);
	newData.append("res_logo", resLogo);


    await saveConfig(newData)
      .then((response) => {
        setIsLoading(false);
       
        if (response.data.code==200) {
			toastSuccess('Record save successfully');
        }
     
      })
      .catch((err) => {
        setIsLoading(false);
		toastError(err);
        console.log(err);
      });
  };

  const handleGetConfigInfo = async () => {

 
    await getConfigInfo()
      .then((response) => {
      

      if(response.data.code!=206){

   setData({

		title: response.data.data.title,
		e_host: response.data.data.e_host,
		e_user_name: response.data.data.e_user_name,
		e_password: response.data.data.e_password,
		e_port: response.data.data.e_port,
		smtp_secure: response.data.data.smtp_secure,
		email_template: response.data.data.email_template,
		sms_template: response.data.data.sms_template,
   });

   setEnableEmail(response.data.data.enable_email);
   setEnableSms(response.data.data.enable_sms);
   setLogoFile(response.data.data.logo);
   setAdminFile(response.data.data.admin_bg_img);
   setStdFile(response.data.data.std_bg_img);
   setResLogoFile(response.data.data.res_page_logo);
  
  
	  }else{
		toastError('Content not available');
	  }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  useEffect(() => {
    handleGetConfigInfo();
  }, []);

  return (
    <>
	<div className="card-title fs-3 fw-bolder">Application Configuration</div>
    <div className="card">
	<div className="card-header">
		<div className="card-title fs-5 fw-bolder text-mute">General Settings</div>
	</div>

	<form id="kt_project_settings_form" className="form" onSubmit={handleSubmitForm}>
		<div className="card-body p-9">

			<div className="row mb-8">
			
				<div class="col-xl-3">
					<div class="fs-6 fw-bold mt-2 mb-3">Application Title</div>
				</div>
			
				<div class="col-xl-9 fv-row">
					<input type="text" class="form-control form-control-solid" name="title" placeholder='Title' value={data.title}
                      onChange={handleChange} />
				</div>
			</div>

			<div className="row mb-8">
			
			<div className="col-xl-3">
				<div className="fs-6 fw-bold mt-2 mb-3">Application Logo</div>
			</div>
		
			<div className="col-xl-3 fv-row">
				<input type="file" className="form-control form-control-solid" name="logo" 
					  onChange={(e) => {
                            setLogo(e.target.files[0])
                          }}
				/>
			</div>

			<div className="col-xl-6 fv-row">
			<div className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${logo})`}}
                    >

                    
                      {logo ? (
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={URL.createObjectURL(logo)}
                          alt='Thumb'
                        />
                      ) : (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${imageUrl + logoFile})`}}

                        ></div>
                      )}

                 
                 
                    </div>
        
			</div>
		</div>

		
		<div className="row mb-8">
			
			<div className="col-xl-3">
				<div className="fs-6 fw-bold mt-2 mb-3">Admin Login Background Image</div>
			</div>
		
			<div className="col-xl-3 fv-row">
				<input type="file" className="form-control form-control-solid" name="admin_bg"
				 onChange={(e) => {
                            setAdminBgImg(e.target.files[0])
                          }} />
			</div>

			<div className="col-xl-6 fv-row">
			<div className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${logo})`}}
                    >

                    
                      {adminBgImg ? (
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={URL.createObjectURL(adminBgImg)}
                          alt='Thumb'
                        />
                      ) : (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${imageUrl + adminFile})`}}

                        ></div>
                      )}

                 
                 
                    </div>
        
			</div>
		</div>

		<div className="row mb-8">
			
			<div className="col-xl-3">
				<div className="fs-6 fw-bold mt-2 mb-3">Student Login Background Image</div>
			</div>
		
			<div className="col-xl-3 fv-row">
				<input type="file" className="form-control form-control-solid" name="std_bg" 
					 onChange={(e) => {
                            setStdBgImg(e.target.files[0])
                          }}
				/>
			</div>

			<div className="col-xl-6 fv-row">
			<div className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${logo})`}}
                    >

                    
                      {stdBgImg ? (
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={URL.createObjectURL(stdBgImg)}
                          alt='Thumb'
                        />
                      ) : (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${imageUrl + stdFile})`}}

                        ></div>
                      )}

                 
                 
                    </div>
        
			</div>
		</div>

		<div className="row mb-8">
			
			<div className="col-xl-3">
				<div className="fs-6 fw-bold mt-2 mb-3">Result Page Logo</div>
			</div>
		
			<div className="col-xl-3 fv-row">
				<input type="file" className="form-control form-control-solid" name="res_logo" 
					 onChange={(e) => {
                            setResLogo(e.target.files[0])
                          }}
				/>
			</div>

			<div className="col-xl-6 fv-row">
			<div className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${logo})`}}
                    >

                    
                      {resLogo ? (
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={URL.createObjectURL(resLogo)}
                          alt='Thumb'
                        />
                      ) : (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${imageUrl + resLogoFile})`}}

                        ></div>
                      )}

                 
                 
                    </div>
        
			</div>
		</div>
		

		<div className="row mb-8">
			
			<div className="col-xl-3">
				<div className="fs-6 fw-bold mt-2 mb-3">Email Configuration</div>
			</div>
		
		
		</div>

		<div className="row">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Enable Email</div>
				</div>

				<div class="col-xl-9">
					<div className="form-check form-switch form-check-custom form-check-solid">
						<input className="form-check-input" type="checkbox" v id="status" name="enable_email" 
						onChange={(e) =>{handleEnabaleEmailChange()}} value={enableEmail} 

						checked={enableEmail==1?'checked':""}
						/>
						<label className="form-check-label fw-bold text-gray-400 ms-3" for="status" >Active</label>
					</div>
				</div>
			</div>

	
			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Email Host</div>
				</div>

				<div className="col-xl-3 fv-row">
				<input type="text" className="form-control form-control-solid" name="e_host" placeholder='Email Host'
				 value={data.e_host}
                      onChange={handleChange}/>
				</div>
			</div>

			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Email Username</div>
				</div>

				<div className="col-xl-3 fv-row">
				<input type="text" className="form-control form-control-solid" name="e_user_name" placeholder='Email User Name'   
				  value={data.e_user_name}
                      onChange={handleChange} />
				</div>
			</div>

			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Email Password</div>
				</div>

				<div className="col-xl-3 fv-row">
				<input type="password" className="form-control form-control-solid" name="e_password" 
				placeholder='Email Password' 
				autoComplete='new-password'
				    value={data.e_password}
                      onChange={handleChange}/>
				</div>
			</div>


			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Email Sender Port</div>
				</div>

				<div className="col-xl-3 fv-row">
				<input type="text" className="form-control form-control-solid" name="e_port" placeholder='Email Sender Port'  
				   value={data.e_port}
                      onChange={handleChange} />
				</div>
			</div>

			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">SMTP Secure</div>
				</div>

				<div className="col-xl-3 fv-row">
				<input type="text" className="form-control form-control-solid" name="smtp_secure" placeholder='SMTP Secure'  
				   value={data.smtp_secure}
                      onChange={handleChange}/>
				</div>
			</div>
	
			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Email Template</div>
				</div>

				<div className="col-xl-9 fv-row">
				<textarea name="email_template" className="form-control form-control-solid h-100px"     value={data.email_template}
                      onChange={handleChange} ></textarea>
				</div>
			</div>

			
			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">SMS Configuration</div>
				</div>

		
			</div>

			
		<div className="row">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">Enable SMS</div>
				</div>

				<div className="col-xl-9">
					<div className="form-check form-switch form-check-custom form-check-solid">
						<input className="form-check-input" type="checkbox"  name="enable_sms" 
						value={enableSms}
						checked={enableSms==1?'checked':""}
						 onChange={(e) =>{handleEnabaleSMSChange()}} 
						 />
						<label className="form-check-label fw-bold text-gray-400 ms-3" for="status">Active</label>
					</div>
				</div>
			</div>

			<div className="row mb-8">
				<div className="col-xl-3">
					<div className="fs-6 fw-bold mt-2 mb-3">SMS Template</div>
				</div>

				<div className="col-xl-9 fv-row">
				<textarea name="sms_template" className="form-control form-control-solid h-100px"     value={data.sms_template}
                      onChange={handleChange}></textarea>
				</div>
			</div>
		</div>

		<div className="card-footer d-flex justify-content-end py-6 px-9">
			<button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button>
			<button type="submit" class="btn btn-primary">
                {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Save Changes"
                )}
              </button>
		</div>
	</form>
</div>

    </>
  )
}

export default Configration
