import React, {useEffect, useState,useRef} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {KTSVG} from '../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {Link, useHref, useNavigate} from 'react-router-dom'

import {toastError, toastSuccess} from '../global/Global'
import {deleteQuestion, getAllQuestions, getAllQuestionsWithFilters} from '../apis/QuestionsBankApi'
import Swal from 'sweetalert2'
import {createStudent, importBulkQuestion} from '../apis/StudentApi.js'
import SamplePdf from '../assets/files/questionSample.xlsx'
import { getCourseForDropdown } from '../apis/CategoryApi'
const QuestionBank = () => {


  const saveButton = useRef(null)
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [liveSearch, setLiveSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(5)
  const [questionData, setQuestionData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState('')
  const [courseId, setCourseId] = useState('')
  const [courseList, setCourseList] = useState([])
  const [qType, setQtype] = useState('')
  




  const handleGetAllQuestions = async (pageNo) => {
    setIsLoading(true)

    await getAllQuestions(pageNo)
      .then((response) => {
  
        if (response.data.code != 206) {
          setPerPage(response.data.data.per_page)
          setTotalPage(response.data.data.total)
          setQuestionData(response.data.data.data)
        } else {
          setQuestionData('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }



   const handleQuestionFilter = async () => {
    setIsLoading(true);
    let postData = JSON.stringify({
      course_id:courseId,
      search_keyword:search,
      question_type:qType,
   
    })


         await getAllQuestionsWithFilters(postData)
      .then((response) => {
        if (response.data.code != 206) {
          setPerPage(response.data.data.per_page)
          setTotalPage(response.data.data.total)
          setQuestionData(response.data.data.data)
          setCourseId('');
          setSearch('');
          setQtype('');
        } else {
          setQuestionData('')
          toastError('Record not found')
        }
        setIsLoading(false)
      })
      .catch((err) => {
       
        setIsLoading(false)
      })
  }

  const deleteRecord = async (id) => {
    try {
      let data = JSON.stringify({
        id: id,
      })

      await deleteQuestion(data)
        .then((response) => {
          handleGetAllQuestions(page)
          toastSuccess('Record deleted successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      toastError('Record not deleted')
    }
  }

  const handleDelete = (id) => {
    Swal.fire({
      text: 'Are you sure you want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(id)
      }
    })
  }

  const handleImportFile = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const FormData = require('form-data')
    let newData = new FormData()

    newData.append('file', file)

    await importBulkQuestion(newData)
      .then((response) => {
        setIsLoading(false)
   

        if (response.data.code == 200) {
          handleGetAllQuestions(page);
          setFile('');
          toastSuccess('Record save successfully')
          autoButtonClick(saveButton)
        }

        if (response.data.code == 204) {
          toastError('This record already exist')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        toastError(err.data.error)
        console.log(err.message)
      })
  }

  const handleEditQuestions = (qId) => {
    navigate(`/edit-question/${qId}`)
  }


  //handleResetFilter
  const handleResetFilter = async () => {

    setSearch('');
    setQtype('');
    setCourseId('');
    handleGetAllQuestions(page)

  }



  const getAllCourseForDropdown = async () => {
    setIsLoading(true)

    await getCourseForDropdown()
      .then((response) => {
      
        if (response.data.code != 206) {
          setCourseList(response.data.data)
        } else {
          setCourseList('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleAddTranslation = (qId) => {
    navigate(`/question-translation/${qId}`)
  }

  const modalCloseEvent=()=>{
      setFile('');
  }

  useEffect(() => {
    handleGetAllQuestions(page)
  }, [])

  const [permArray, setPermArray] = useState([])

  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermArray(JSON.parse(storedArray))
    }
  }, [])

  useEffect(() => {
    getAllCourseForDropdown();

  }, [])


  const autoButtonClick = (buttonType) => {
    buttonType.current.addEventListener('click', handleGetAllQuestions(page))
    buttonType.current.click()
  }

  return (
    <>
      <h2>Question Bank</h2>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>

                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
              
                  </span>

                
                </div>
              </div>

              <div className='card'>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                  <div className='card-toolbar'>
                  {permArray.some(item => item.name === 'q-create')? 
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                 


             <a
        href={SamplePdf}
        download="Sample-Question-Format"
        target="_blank"
        rel="noreferrer"
      >
             <button
                        type='button'
                        className='btn btn-primary'
                        style={{marginRight: '10px'}}
                        
                      >
                        Sample Format
                      </button>
      </a>

                      <button
                        type='button'
                        className='btn btn-primary'
                        style={{marginRight: '10px'}}
                        data-bs-toggle='modal'
                        data-bs-target='#import_questions'
                      >
                        Import Questions
                      </button>

                      <Link to='/add-question'>
                        <button type='button' className='btn btn-primary'>
                          Add New Question
                        </button>
                      </Link>
                    </div>
                    :''}
                  </div>
                </div>
              </div>
            </div>

{/* custome filter start here */}
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                
                <div className='d-flex align-items-center position-relative my-1'>
                  
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Question'
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                </div>

                <select
                    name='gender'
                    className='form-control form-control-lg form-control-solid'
                    required
                    style={{borderRadius: '20px'}}
                    onChange={(e) => setCourseId(e.target.value)}
                    value={courseId}
                  >
                    <option value=''>Choose Course</option>
                    {courseList
                      ? courseList.map((item) => {
                          return <option value={item.id}>{item.cat_title}</option>
                        })
                      : ''}
                  </select>


                  <select
                    name='gender'
                    className='form-control form-control-lg form-control-solid'
                    required
                    style={{borderRadius: '20px'}}
                    onChange={(e) => setQtype(e.target.value)}
                    value={qType}
                  >
                    <option value=''>Choose Type</option>
                    <option value='1'>Standard</option>
                    <option value='2'>Video</option>
                  </select>
                    
              </div>

              <div className='card'>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                  <div className='card-toolbar'>
                  {permArray.some(item => item.name === 'q-create')? 
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                 


                 <button type="submit" class="btn btn-primary"  onClick={() => {handleResetFilter()}} style={{marginRight:'5px'}}>Clear</button>

                      <button type="submit" class="btn btn-primary"  onClick={() => {
                                        handleQuestionFilter()
                                      }}>
                {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Filter"
                )}
              </button>

                                        
                    </div>
                    :''}
                  </div>
                </div>
              </div>
            </div>

            {/* custome filter end here  */}
            <hr/>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Course</th>
                    <th className='text-end min-w-125px'>Code</th>
                    <th className='text-end min-w-125px'>Question</th>
                    <th className='text-end min-w-100px'>Choices</th>
                    <th className='text-end min-w-100px'>Answer</th>
                    <th className='text-end min-w-100px'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
                  {questionData
                    ? questionData
                        .filter((item) => {
                          return liveSearch.toLowerCase() === ''
                            ? item
                            : item.course.cat_title.toLowerCase().includes(liveSearch) ||
                                item.question_detail.q_content.toLowerCase().includes(liveSearch)
                        })
                        .map((item) => {
                          return (
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              <td className='d-flex align-items-center'>
                                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'></div>
                                <div className='d-flex flex-column'>

                              
                                {item.q_courses? item.q_courses.map((course) => {
                
                          return (
                                  <span>{(course)?course.course.cat_title:'-'},</span>
                       
                                  )
                                })
                            : ''}
                                </div>
                              </td>

                         

                              <td className='text-end'>
                              {(item.q_code)?item.q_code:''}
                              </td>
                              <td className='text-end'>
                                {item.question_detail ? item.question_detail.q_content : ''}
                              </td>
                              <td className='text-end'>
                                <span>
                                  {item.question_detail ? item.question_detail.opt_a : ''}{' '}
                                </span>
                                <br />
                                <span>
                                  {item.question_detail ? item.question_detail.opt_b : ''}{' '}
                                </span>
                                <br />
                                <span>
                                  {item.question_detail ? item.question_detail.opt_c : ''}{' '}
                                </span>
                              </td>

                              <td className='text-end'>
                                <span>
                                  {item.correct_opt && item.correct_opt == 'a' ? item.question_detail.opt_a : ''}
                                  {item.correct_opt && item.correct_opt == 'b' ? item.question_detail.opt_b : ''}
                                  {item.correct_opt && item.correct_opt == 'c' ? item.question_detail.opt_c : ''}
                                </span>
                              </td>

                              <td className='text-end'>
                                <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>

                                 
                                 
   
                                  <Dropdown.Menu>
                                  
                                  {permArray.some(item => item.name === 'q-edit')? 
                                    <Dropdown.Item
                                      data-bs-toggle='modal'
                                      data-bs-target='#edit_modal'
                                      onClick={() => {
                                        handleEditQuestions(item.id)
                                      }}
                                    >
                                      {' '}
                                      Edit
                                    </Dropdown.Item>
                                    :''}

                               
       

                                    {permArray.some(item => item.name === 'q-delete')? 
                                    <Dropdown.Item
                                      onClick={() => {
                                        handleDelete(item.id)
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    :''}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        })
                    : ''}
                </tbody>
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
               
                     handleGetAllQuestions(page);
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='kt_modal_create_app'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Add New Course</h1>
              </div>

              <form> </form>
            </div>
          </div>
        </div>
      </div>

      {/* Questions Import Modal */}

      <div className='modal fade' id='import_questions' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header flex-stack'>
              <h2>Upload Bulk Question File</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'  
              onClick={()=> modalCloseEvent()}
              ref={saveButton}>
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                 
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className='modal-body scroll-y pt-10 pb-15 px-lg-17'>

              <div data-kt-element='options'>
              <form onSubmit={handleImportFile}>
                {/* <p class='text-muted fs-5 fw-bold mb-10'>
                  Lorem ipsum is the most well known filler text and comes from various passages of
                  a book from Cicero, written in 45 BC
                </p> */}

                <div className='pb-10'>
                  <label
                    className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5'
                    for='kt_modal_two_factor_authentication_option_1'
                  >
                    <span className='svg-icon svg-icon-4x me-4'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          opacity='0.3'
                          d='M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z'
                          fill='black'
                        />
                        <path
                          d='M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z'
                          fill='black'
                        />
                      </svg>
                    </span>

                    <span className='d-block fw-bold text-start'>
                      <span className='text-dark fw-bolder d-block fs-3'>Choose File</span>
                      <span className='text-muted fw-bold fs-6'>Choose Excel/Csv file.</span>
                    </span>
                    <input
                      type='file'
                      name='file'
                      accept='.xlsx, .csv'
                      onChange={(e) => {
                        setFile(e.target.files[0])
                      }}
                    />
                  </label>
                </div>

                <button className='btn btn-primary w-100' data-kt-element='options-select' type='submit'>
                  
                  {isLoading ? (
                  <div>
                    <i className='fa fa-spinner fa-spin'></i>
                  </div>
                ) : (
                  'Continue'
                )}
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionBank
