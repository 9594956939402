import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getConfigInfo= async ()=>{
    var config = {
      method: 'get',
      url: `${API_URL}/get-config`,
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
       'Content-Type': 'application/json',
     },
    };

  const response=await axios(config)
  return response;
}



export const saveConfig= async (data)=>{
 
  var config = {
    method: 'post',
    url: `${API_URL}/save-config`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}





