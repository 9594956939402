import {lazy, FC, Suspense, useState, useEffect, ReactNode} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils";
import Language from '../../components/language/Language'
import Users from '../../components/users/Users'
import Course from '../../components/course/Course'
import AddUser from '../../components/users/AddUser'
import Roles from '../../components/roles/Roles'
import TestLayout from '../../components/test.-layout/TestLayout'
import Configration from '../../components/configration/Configration'
import QuestionBank from '../../components/questions/QuestionBank'
import Students from '../../components/students/Students'
import TestData from '../../components/TestData'
import MyProfile from '../../components/profile/MyProfile'
import AddQuestion from '../../components/questions/AddQuestion'
import EditQuestion from '../../components/questions/EditQuestion'
import AddStudent from '../../components/students/AddStudent'
import EditStudent from '../../components/students/EditStudent'
import EditUser from '../../components/users/EditUser'
import Exams from '../../components/exams/Exams'
import StdDashboard from '../../components/std-module/StdDashboard'
import PracticeTest from '../../components/std-module/exam/PracticeTest'
import StdArea from '../../components/std-module/std-area/StdArea'
import RtlPracticeExam from '../../components/std-module/exam/RtlPracticeExam'
import ExamType from '../../components/std-module/exam/ExamType'
import CourseConfig from '../../components/configration/CourseConfig'
import ActualExamLtr from '../../components/std-module/exam/ActualExamLtr'
import ActualRtlExam from '../../components/std-module/exam/ActualRtlExam'
import VideoExam from '../../components/std-module/exam/VideoExam'
import ReviewSection from '../../components/std-module/exam/ReviewSection'
import HeaderLatest from '../../components/HeaderLatest'
import ExamPage from '../../components/std-module/exam/exam-components/ExamPage'
import StdResults from '../../components/std-module/exam/results/StdResults'
import StdPracticeResult from '../../components/std-module/exam/results/StdPracticeResult'
import Results from '../../components/std-module/exam/results/Results'
import ViewResults from '../../components/std-module/exam/results/ViewResults'
import QuestionTranslation from '../../components/questions/QuestionTranslation'
import MailLogs from '../../components/logs/MailLogs'
import StdProfile from '../../components/profile/StdProfile'
import Profile from '../../components/profile/Profile'
import LanguageTranslation from '../../components/language/LanguageTranslation'
import Reports from '../../components/reports/Reports'
import PracticeReport from '../../components/reports/PracticeReport'
import PracticeReportDetail from '../../components/reports/PracticeReportDetail'


const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  
  const [permData, setPermData] = useState([])

  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermData(JSON.parse(storedArray))
    }
  }, [])

  interface Permission {
    name: string;
    // other properties if needed
  }

  const permArray: Permission[] = permData;
  
  

  return (
    <Routes>

    <Route element={<MasterLayout />}>
    <Route path='auth/*' element={<Navigate to='/dashboard' />} />
    <Route path='dashboard' element={<DashboardWrapper />} />
    <Route path='crafted/pages/profile*' element={<SuspensedView><MyProfile/></SuspensedView>}/>

    <Route path='profile*' element={<SuspensedView><Profile/></SuspensedView>}/>

    <Route path='languages/*' element={<IsAccess hasPermission={'language-view'}> <SuspensedView><Language/></SuspensedView>  </IsAccess>}/>
    <Route path='language-translate/:langSlug' element={<IsAccess hasPermission={'language-view'}> <SuspensedView><LanguageTranslation/></SuspensedView>  </IsAccess>}/>

    
    <Route path='courses/*' element={<IsAccess hasPermission={'course-view'}> <SuspensedView><Course/></SuspensedView>  </IsAccess>}/>
    <Route path='users/*' element={<IsAccess hasPermission={'user-view'}> <SuspensedView><Users/></SuspensedView>  </IsAccess>}/>
    <Route path='add-user/*' element={<SuspensedView><AddUser/></SuspensedView>}/>
    <Route path='configration/*' element={<IsAccess hasPermission={'config-create'}> <SuspensedView><Configration/></SuspensedView>  </IsAccess>}/>
    <Route path='roles/*' element={<IsAccess hasPermission={'role-view'}> <SuspensedView><Roles/></SuspensedView>  </IsAccess>}/>
    <Route path='test-layout/*' element={<SuspensedView><TestLayout/></SuspensedView>}/>
    <Route path='q-bank/*' element={<IsAccess hasPermission={'q-view'}> <SuspensedView><QuestionBank/></SuspensedView>  </IsAccess>}/>
    <Route path='add-question/*' element={<IsAccess hasPermission={'q-create'}> <SuspensedView><AddQuestion/></SuspensedView>  </IsAccess>}/>

    <Route path='/edit-question/:id/' element={<IsAccess hasPermission={'q-edit'}> <SuspensedView><EditQuestion/></SuspensedView>  </IsAccess>}/>


    <Route path='students/*' element={<IsAccess hasPermission={'std-view'}> <SuspensedView><Students/></SuspensedView>  </IsAccess>}/>
    <Route path='add-student/*' element={<IsAccess hasPermission={'std-create'}> <SuspensedView><AddStudent/></SuspensedView>  </IsAccess>}/>
    <Route path='/edit-student/:id*' element={<IsAccess hasPermission={'std-edit'}> <SuspensedView><EditStudent/></SuspensedView>  </IsAccess>}/>
    <Route path='/edit-user/:id*' element={<IsAccess hasPermission={'user-edit'}> <SuspensedView><EditUser/></SuspensedView>  </IsAccess>}/>
    <Route path='exams/*' element={<IsAccess hasPermission={'exam-view'}> <SuspensedView><Exams/></SuspensedView>  </IsAccess>}/>
    <Route path='/course-config/:id/:title*' element={<SuspensedView><CourseConfig/></SuspensedView>}/>

    <Route path='logs/*' element={<IsAccess hasPermission={'sms-logs'}> <SuspensedView><MailLogs/></SuspensedView>  </IsAccess>}/>


    {/* student area */}
    <Route path='std-dashboard/*' element={<SuspensedView><StdDashboard/></SuspensedView>}/>   
    <Route path='std-login/*' element={<SuspensedView><StdDashboard/></SuspensedView>}/>  
    <Route path='/exam-type' element={<ExamType/>}/> 

    <Route path='std-practice-result' element={<StdPracticeResult/>}/>
{/* 
    <Route path='results/:type/' element={<IsAccess hasPermission={'res-view'}> <SuspensedView><Results/></SuspensedView>  </IsAccess>}/> */}

    <Route path='results/:type/' element={<SuspensedView><Results/></SuspensedView> }/>
    <Route path='practice-report/' element={<SuspensedView><PracticeReport/></SuspensedView> }/>

    <Route path='practice-report-detail/:stdId' element={<SuspensedView><PracticeReportDetail/></SuspensedView> }/>

    

    
    <Route path='/view-result/:examId/:stdName' element={<ViewResults/>}/>



    <Route path='test/*' element={<TestData/>}/>
    

        {/* Page Not Found */}
        
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
      <Route path='/std-area' element={<StdArea/>}/>
      <Route path='/exam/:attempt/:langId/:audioLang/:testType/:direction' element={<ExamPage/>}/>
      <Route path='/practice-test/:attempt/:langId/:audioLang' element={<PracticeTest/>}/>
      <Route path='/rtl-exam/:attempt/:langId/:audioLang' element={<RtlPracticeExam/>}/>
      <Route path='/actual-exam-ltr/:langId/:audioLang' element={<ActualExamLtr/>}/>
      <Route path='/actual-exam-rtl/:langId/:audioLang' element={<ActualRtlExam/>}/>
      <Route path='/video-q/:attempt' element={<VideoExam/>}/>
      <Route path='review' element={<ReviewSection/>}/>
      {/* <Route path='std-results/:stdId' element={<StdResults/>}/> */}
      <Route path='print-result/:examId' element={<StdResults/>}/>
     
    </Routes>
  )

  

  function IsAccess({ children, hasPermission }: { children: ReactNode, hasPermission: string }) {
  if(permArray.some(item => item.name === hasPermission)){
   return (
     <>
       {children}
     </>
   );
 } else {
   return <div className='alert alert-danger'>Error: Permission denied</div>
 }
}



}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
