import React, { useEffect } from 'react'
// import car from '../../assets/img/car.jpg'
import car from '../../../../components/assets/img/car.jpg'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import { stdLogin } from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { toastError } from '../../../../components/global/Global'
import { getConfigInfo } from '../../../../components/apis/ConfigApi'

const StdLogin = () => {

  const navigate=useNavigate();

  
  const [stdBgImage, setStdBgImage] = useState(null)
  const imageUrl = process.env.REACT_APP_IMAGE_URL

  const handleGetConfigInfo = async () => {
    await getConfigInfo()
      .then((response) => {
      if(response.data.code!=206){
        setStdBgImage(response.data.data.std_bg_img)
        localStorage.setItem('appTitle',response.data.data.title);
        localStorage.setItem('appLogo',response.data.data.logo);
        localStorage.setItem('stdBgImage',response.data.data.std_bg_img);
  
	  }else{
      localStorage.setItem('stdBgImage','');
      localStorage.setItem('appLogo','');
      localStorage.setItem('appTitle','');
	  }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetConfigInfo();
  }, []);



   const loginSchema = Yup.object().shape({
      std_traffic_id: Yup.string()
        .min(1, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Traffic Id is required'),
        supervisor_id: Yup.string()
        .min(1, 'Minimum 1 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Supervisor Id is required'),
      password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    })
    
    const initialValues = {
      std_traffic_id: '',
      supervisor_id: '',
      password: '',
    }


    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
  
    const formik = useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        setTimeout(() => {
         stdLogin(values.std_traffic_id,values.supervisor_id, values.password)
            .then((response) => {
  
           
                // console.log('Login Response',response.data.data.stdInfo.image);

              if(response.data.code==404){
               toastError('This traffic id not exist in our record');
              }

              if(response.data.code==505){
                toastError('Exam not schedule for this student');
               }
  
              if(response.status==200){

                window.localStorage.setItem('stdName',response.data.data.stdInfo.name);
                window.localStorage.setItem('userType','2');

                window.localStorage.setItem('authToken',response.data.data.token);
                window.localStorage.setItem('userId',response.data.data.user_id);
     
              window.localStorage.setItem('stdId',JSON.stringify(response.data.data.stdInfo.id));
              window.localStorage.setItem('stdName',response.data.data.stdInfo.name);
              window.localStorage.setItem('stdEmail',JSON.stringify(response.data.data.stdInfo.email));
              window.localStorage.setItem('stdImage',JSON.stringify(response.data.data.stdInfo.image));
              window.localStorage.setItem('userImage',response.data.data.stdInfo.image);
              window.localStorage.setItem('userName',response.data.data.stdInfo.name);

       
           

              // navigate('/std-dashboard');
               dispatch(auth.actions.login(response.data.data))
               
              }else{
          
                setStatus('The login detail is incorrect')
              }
  
              setLoading(false);
            
            })
            .catch((errr) => {
        
              setLoading(false)
              setSubmitting(false)
              setStatus('The login detail is incorrect')
            })
        }, 1000)
      },
    })


  return (
    <>
      <div className='d-flex flex-column flex-root' style={{overflowX: 'hidden'}}>
        <div
          className='d-flex flex-column flex-column-fluid position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
        
            backgroundImage:stdBgImage?  "url(" + imageUrl+stdBgImage + ")":`url(${car})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
          }}
        >
          <div className='row '>
            <div className='col-md-7'> </div>
            <div className='col-md-5 overflow-hidden'>
              <div className='d-flex p-15 pb-lg-20'>
                <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 ml-auto'>



                <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
  
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>STUDENT AREA</h1>

      </div>
      {formik.status ? (
      <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
        ): ''}

      {/* begin::Form group */}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Student ID</label>
        <input
          placeholder='Student ID'
          {...formik.getFieldProps('std_traffic_id')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.std_traffic_id && formik.errors.std_traffic_id},
            {
              'is-valid': formik.touched.std_traffic_id && !formik.errors.std_traffic_id,
            }
          )}
          type='text'
          name='std_traffic_id'
          autoComplete='off'
        />
        {formik.touched.std_traffic_id && formik.errors.std_traffic_id && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.std_traffic_id}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Supervisor ID</label>
        <input
          placeholder='Supervisor ID'
          {...formik.getFieldProps('supervisor_id')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.supervisor_id && formik.errors.supervisor_id},
            {
              'is-valid': formik.touched.supervisor_id && !formik.errors.supervisor_id,
            }
          )}
          type='text'
          name='supervisor_id'
          autoComplete='off'
        />
        {formik.touched.supervisor_id && formik.errors.supervisor_id && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.supervisor_id}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>


      </div>
    
    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StdLogin
