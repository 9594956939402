import React, { useEffect, useState } from 'react'

import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
 
  const [isShowSettingTab, setIsShowSettingTab] = useState(false)

  const [permData, setPermData] = useState([])

  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermData(JSON.parse(storedArray))
    }
  }, [])

  interface Permission {
    name: string;
    // other properties if needed
  }

  const permArray: Permission[] = permData;


  return (
    <>

      <AsideMenuItem to='/dashboard' icon='/media/icons/duotune/art/art002.svg'  title='Dashboard'/>

      {permArray.some(item => item.name === 'course-view')? 
       <AsideMenuItem  to='/courses' icon='/media/icons/duotune/layouts/lay010.svg' title='Courses' fontIcon='bi-users'/>
       :''}

      {permArray.some(item => item.name === 'q-view')? 
        <AsideMenuItem to='/q-bank' icon='/media/icons/duotune/art/art003.svg'  title='Questions' fontIcon='bi-users'/>
       :''}


      {permArray.some(item => item.name === 'std-view')? 
      <AsideMenuItem to='/students' icon='/media/icons/duotune/art/art004.svg'  title='Students' fontIcon='bi-users'/>
       :''}

      {permArray.some(item => item.name === 'exam-view')? 
      <AsideMenuItem to='/exams' icon='/media/icons/duotune/art/art005.svg'  title='Exams' fontIcon='bi-users'/>
       :''}

      {permArray.some(item => item.name === 'res-view')? 
       <AsideMenuItem to='/results/admin' icon='/media/icons/duotune/art/art006.svg'  title='Results' fontIcon='bi-users'/>
       :''}


      {permArray.some(item => item.name === 'sms-logs')? 
        <AsideMenuItem to='/logs' icon='/media/icons/duotune/art/art007.svg'  title='Mail/Sms Log' fontIcon='bi-users'/>
       :''}

       
      {permArray.some(item => item.name === 'reports-view')? 
       <AsideMenuItem to='/reports' icon='/media/icons/duotune/art/art008.svg'  title='Reports' fontIcon='bi-users'/>
       :''}


      {permArray.some(item => item.name === 'sms-logs')? 
       <AsideMenuItem to='/sms-email' icon='/media/icons/duotune/art/art009.svg'  title='Send SMS/Email' fontIcon='bi-users'/>
       :''}


<AsideMenuItemWithSub
        to='/settings'
        title='Reports'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >

      
     <AsideMenuItem to='/practice-report' title='Practice Report' hasBullet={true} />
     

       
      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/settings'
        title='Settings'
        icon='/media/icons/duotune/general/gen026.svg'
        fontIcon='bi-layers'
      >

        {permArray.some(item => item.name === 'config-create')? 
             <AsideMenuItem to='/configration' title='Configuration' hasBullet={true} />
       :''}

        {permArray.some(item => item.name === 'language-view')? 
           <AsideMenuItem to='/languages' title='Languages' hasBullet={true} />
       :''}

        {permArray.some(item => item.name === 'role-view')? 
           <AsideMenuItem to='/roles' title='Roles' hasBullet={true} />
       :''}

      {permArray.some(item => item.name === 'user-view')? 
             <AsideMenuItem to='/users' title='User Management' hasBullet={true} />
       :''}

          {/* {permArray.some(item => item.name === 'config-create')? 
              <AsideMenuItem to='/test-layout' title='Test Layout Settings' hasBullet={true} />   
       :''} */}
 
      
       
     
       
       
      </AsideMenuItemWithSub>

   
 
    </>
  )
}
