import React, { useEffect, useRef, useState } from 'react'
import { getPracticeExamResult } from '../../../apis/ExamApi'
import { toastError } from '../../../global/Global'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { editLang, getAllLangForDropdown } from '../../../apis/LanguageApi'

const StdPracticeResult = () => {

  
  const [isLoading, setIsLoading] = useState(false)
  const [examResult, setExamResult] = useState([])
  const [totalQuestion, setTotalQuestion] = useState('')
  const [vidTotalQuestion, setVidTotalQuestion] = useState('')
  const [attemptId, setAttemptId] = useState(0)
  const [langId, setLangId] = useState('')
  const [langList, setLangList] = useState([])
  const [audioLang, setAudioLang] = useState(0)
  const [langDir, setLangDir] = useState('')

  const saveButton = useRef(null);

  const handlePracticeExamResult = async () => {
    let data = JSON.stringify({
      std_id: sessionStorage.getItem('stdId'),
    })

    await getPracticeExamResult(data)
      .then((response) => {

          // console.log(response.data.data.solvedQuestion);
        if (response.data.code != 206) {
          setVidTotalQuestion(response.data.data.videoTotalQuestion);
          setTotalQuestion(response.data.data.totalQuestion);
          setExamResult(response.data.data.solvedQuestion)
        } else {
          toastError('Content not available')
          setExamResult('')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const navigate=useNavigate();

  const modalCloseEvent=()=>{
    setLangId('');
    setAudioLang('');
    }

    const autoButtonClick=(buttonType)=>{

      buttonType.current.addEventListener('click',modalCloseEvent);
      buttonType.current.click();
    }

    const handleAttemptId = async (attempt) => {
      setAttemptId(attempt);
    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      setIsLoading(true)
      let data = JSON.stringify({
        id: langId,
      })
      
      await editLang(data)
        .then((response) => {
          setIsLoading(false)
          // console.log('Direction',response.data.data.direction)
  
          if (response.data.code == 200) {
          
          setLangDir(response.data.data.direction);
          if(response.data.data.direction=='LTR'){
            autoButtonClick(saveButton);
          navigate(`/exam/${attemptId}/${langId}/${audioLang}/2`)
          // navigate(`/practice-test/${attemptId}/${langId}/${audioLang}`)
          }else{
            
            navigate(`/rtl-exam/${attemptId}/${langId}/${audioLang}`)
          }
  
          }
          if (response.data.error) {
            setLangDir('');
            toastError('Content not available')
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }

    const getAllLangFunc = async (pageNo) => {
      await getAllLangForDropdown()
        .then((response) => {
          //  console.log(response.data.data)
          setLangList(response.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }

  useEffect(() => {
    handlePracticeExamResult()
    getAllLangFunc()

  }, [])


  return (
    <>
    <div>
         <div className='row g-5 g-xl-8'>
   
    

        <h4>Practice Test Result</h4>

        <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>

             
              </div>

             
            </div>
            
            <div className='card-body pt-0'>
           
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>

                    <th className='min-w-125px'>Attempt#</th>
                    <th className='min-w-125px'>Attempt Date</th>
                    <th className='text-end min-w-100px'>Total Q</th>
                    <th className='text-end min-w-100px'>Correct Ans</th>
                    <th className='text-end min-w-100px'>Wrong Ans</th>
                    <th className='text-end min-w-100px'>Skip</th>
                    <th className='text-end min-w-100px'>Type</th>
                    
                    <th className='text-end'>Action</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
                {examResult
                    ? examResult.map((item) => {

                
                      return (
                     
                     
                       
                        <tr key={item.id}>
                      
                              <td className='min-w-125px'>{item.attempt}</td>
                              <td className='min-w-125px'>{item.created_at}</td>
                              {item.q_type==1?
                              <td className='text-end min-w-100px'>{totalQuestion}</td>
                              :''}

                              {item.q_type==2?
                              <td className='text-end min-w-100px'>{vidTotalQuestion}</td>
                              :''}
                              

                              <td className='text-end min-w-100px'>{item.correct}</td>
                              <td className='text-end min-w-100px'>{item.wrong}</td>


                              {item.q_type==1?
                              <td className='text-end min-w-100px'>{parseInt(totalQuestion) - (parseInt(item.correct) + parseInt(item.wrong))}</td>
                              :
                              <td className='text-end min-w-100px'>{parseInt(vidTotalQuestion) - (parseInt(item.correct) + parseInt(item.wrong))}</td>
                              }


                              <td className='text-end min-w-100px'>{item.q_type==1?'Standard':'Video'}</td>

                              {item.q_type==1?
                    
                              <td className='text-end'>

                             { item.q_type==1 && (parseInt(totalQuestion) - (parseInt(item.correct) + parseInt(item.wrong))) == 0?
                              '-'
                              : <button className='btn btn-primary'  data-bs-toggle='modal' data-bs-target='#langModal'  onClick={() => {handleAttemptId(item.attempt)}}>Resume Test</button>
                              }
                    
                              </td>  
                              
                              :<td className='text-end'>

                      { item.q_type==2 && parseInt(vidTotalQuestion) - (parseInt(item.correct) + parseInt(item.wrong)) == 0?
      '-'
        :    <Link to={`/video-q/${item.attempt}`} className='btn btn-primary'>Resume Test</Link>
      }


 </td>   
                              }

                                            
                            </tr>
                            )
                        })
                    : ''}
                  
                </tbody>
         
              </table>

           
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>


      <div
        className='modal '
        id='langModal'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-close'
                data-bs-dismiss='modal'
                onClick={()=> modalCloseEvent()}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
       
              <form
            
                     class='form w-100'
                  
                    id='kt_sign_in_form'
                    onSubmit={handleSubmit}
                  >

                    <div class='text-center mb-10'>
                      <h1 class='text-dark mb-3'>Choose Desired Options</h1>
                    </div>

                    <div class='fv-row mb-10'>
                      <div class='d-flex flex-stack mb-2'>
                        <label class='form-label fw-bolder text-dark fs-6 mb-0'>
                          Screen Text Language
                        </label>
                      </div>
                      <select
                        name='langText'
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) =>{ 
                          setLangId(e.target.value);
                          }}
                      >
                        <option value=''>Text Language</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='fv-row mb-10'>
                      <label class='form-label fs-6 fw-bolder text-dark'>Audio Language</label>
                      <select
                        name='langAudio'
                        id=''
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) => setAudioLang(e.target.value)}
                      >
                        <option value='0'>No Audio</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        class='btn btn-lg btn-primary w-100 mb-5'
                      >
                         {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                      </button>
                    </div>
                  </form>

        
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default StdPracticeResult
