import React, {useState,useEffect} from 'react'
import userImage from '../assets/img/userImage.png'
import { getAllRoles } from '../apis/RolesApi'
import { createUsers } from '../apis/UserApi'
import { swalSuccess, toastError, toastSuccess } from '../global/Global'
import { ToastContainer } from 'react-toastify';

const UserInfo = () => {
  const [file, setFile] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [roleData, setRoleData] = useState([])
  const [status, setStatus] = useState(true)
  const [allowTest, setAllowTest] = useState(false)

  const [isStatusCheck, setIsStatusCheck] = useState(false)


  const [data, setData] = useState({
    fname: '',
    lname: '',
    userid: '',
    email: '',
    phone: '',
    password: '',
    confPassword: '',
    role: '',
    layout: '',
  })


  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    // console.log(name, value)
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }



  const handleStatusChange = () => {
    setStatus(!status);
  };


  const handleAllowTestChange = () => {
    setAllowTest(!allowTest);
  };



  const getAllRolesFunc = async () => {
    setIsLoading(true);
    await getAllRoles()
      .then((response) => {
        console.log('All Roles',response.data.data);
        setRoleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    getAllRolesFunc();

  }, []);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
   
if(data.password!=data.confPassword){
  toastError('Confirm Password error');
  return;
}

setIsLoading(true);

    const FormData = require("form-data");
    let newData = new FormData();

const stausVal=status?1:0;

const allowTakeTestVal=allowTest?1:0;
    newData.append("f_name", data.fname);
    newData.append("l_name", data.lname);
    newData.append("email", data.email);
    newData.append("password", data.password);
    newData.append("phone", data.phone);
    newData.append("user_id", data.userid);
    newData.append("role_id", data.role);
    newData.append("status", stausVal);
    newData.append("allowTest", allowTakeTestVal);
    newData.append("file",file);


    await createUsers(newData)
      .then((response) => {
        setIsLoading(false);
        console.log(response);
      

        if (response.data.code==200) {
         
         setData({
          fname: '',
          lname: '',
          userid: '',
          email: '',
          phone: '',
          password: '',
          confPassword: '',
          role: '',
          layout: '',
        
         });
         setFile('');
          toastSuccess('Record save successfully');
      
        }else{
          toastError('Record not save');
        }
     
      })
      .catch((err) => {
        toastError(err.data.error);
        setIsLoading(false);
  
      });
    
  };


  return (
   
    <div className='card mb-5 mb-xl-10'>
     <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add User</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form  className='form' onSubmit={handleSubmitForm}>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Avatar</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-8 fv-row'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${userImage})`}}
                    >
                      {file ? (
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={URL.createObjectURL(file)}
                          alt='Thumb'
                        />
                      ) : (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${userImage})`}}
                        ></div>
                      )}

                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title='Change avatar'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>
                        <input
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e) => {
                            setFile(e.target.files[0])
                          }}
                          required
                        />
                        <input type='hidden' name='avatar_remove' />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
                    <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      name='fname'
                      value={data.fname}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      name='lname'
                      value={data.lname}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>User ID</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='User ID'
                  name='userid'
                  value={data.userid}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  name='email'
                  value={data.email}
                  onChange={handleChange}
                  
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone'
                  name='phone'
                  value={data.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

              <div className='col-lg-4 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  name='password'
                  value={data.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='col-lg-4 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Confirm Password'
                  name='confPassword'
                  value={data.confPassword}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>User Role</label>

              <div className='col-lg-8 fv-row'>
              <select name="role" className="form-control" value={data.purpose} onChange={handleChange} required>
        <option value=''>Choose Role</option>
        {roleData?
            roleData.map((item) => {
                    return (
        <option value={item.id}>{item.name}</option>

        );
                  })
                  :''}
   
      </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Test Layout</label>

              <div className='col-lg-8 fv-row'>
                <select className='form-select form-select-solid form-select-lg' required>
                  <option value='1'>Layout-1</option>
                </select>
              </div>
            </div>

            <div className='row mb-0'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Allowed To Take Test</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input className='form-check-input w-45px h-30px' type='checkbox'
                   checked={allowTest}
                        onChange={handleAllowTestChange}
                   />
                  <label className='form-check-label'></label>
                </div>
              </div>
            </div>

            <div className='row mb-0'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input className='form-check-input w-45px h-30px' type='checkbox'  
            
                  checked={status}
                        onChange={handleStatusChange}
                   />
                  <label className='form-check-label'></label>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
          
            <button type="submit" class="btn btn-primary">
                {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Save"
                )}
              </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserInfo
