import React from 'react'

const TestLayout = () => {
  return (
    <div>
      <p>Test Layout</p>
    </div>
  )
}

export default TestLayout
