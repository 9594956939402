import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import { editLang, getAllLangForDropdown } from '../apis/LanguageApi';
import { toastError } from '../global/Global';
import { saveAttempt } from '../apis/QuestionsBankApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ActualExamStdModal = (props) => {

  const navigate=useNavigate();
  const saveButton = useRef(null);

  const [isLoading, setIsLoading] = useState(false)
  const [langList, setLangList] = useState([])
  const [langId, setLangId] = useState('')
  const [audioLang, setAudioLang] = useState(0)
  const [langDir, setLangDir] = useState('')
  const [stdName, setStdName] = useState('')
  const [stdId, setStdId] = useState('')
  const [attempNo, setAttempNo] = useState(0)



  const { i18n } = useTranslation();
  const handleLanguage = (lang) => { 
      i18n.changeLanguage(lang);
  }

  const getAllLangFunc = async (pageNo) => {
    await getAllLangForDropdown()
      .then((response) => {
        //  console.log(response.data.data)
        setLangList(response.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getStdInfo = () =>{
    setStdName(window.localStorage.getItem('stdName'));
    setStdId(window.localStorage.getItem('stdId'));
}

const autoButtonClick=(buttonType)=>{

  buttonType.current.addEventListener('click',getStdInfo);
  buttonType.current.click();
}


  useEffect(() => {
    getAllLangFunc(1);
    getStdInfo()
  }, []  )



  const modalCloseEvent=()=>{
    setLangId('');
    setAudioLang('');
    }


    const handleAttempt = async (e) => {

      setIsLoading(true)
      let data = JSON.stringify({
        std_id: stdId,
      })
      
      await saveAttempt(data)
        .then((response) => {
          setIsLoading(false)
          // console.log(response)

        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      setIsLoading(true)
      let data = JSON.stringify({
        id: langId,
      })
      
      await editLang(data)
        .then((response) => {
          setIsLoading(false)
             autoButtonClick(saveButton);
         //  console.log('Direction',response.data.data.direction)
  
          if (response.data.code == 200) {
            handleLanguage(response.data.data.lang_short)
            handleAttempt();
          setLangDir(response.data.data.direction);
          if(response.data.data.direction=='LTR'){
            autoButtonClick(saveButton);
            navigate(`/exam/0/${langId}/${audioLang}/1/1`)
          // navigate(`/actual-exam-ltr/${langId}/${audioLang}`)
          }else{
            navigate(`/exam/${attempNo}/${langId}/${audioLang}/1/2`)
            // navigate(`/actual-exam-rtl/${langId}/${audioLang}`)
          }
  
          }
          if (response.data.error) {
            setLangDir('');
            toastError('Content not available')
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }




  return (
    <>
            <div
        className='modal '
        id='actualExamModal'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-close'
                data-bs-dismiss='modal'
                onClick={()=> modalCloseEvent()}
                ref={saveButton}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
       
              <form
            
                     class='form w-100'
                  
                    id='kt_sign_in_form'
                    onSubmit={handleSubmit}
                  >

                    <div class='text-center mb-10'>
                      <h1 class='text-dark mb-3'>Choose Desired Options </h1>
                    </div>

                    <div class='fv-row mb-10'>
                      <div class='d-flex flex-stack mb-2'>
                        <label class='form-label fw-bolder text-dark fs-6 mb-0'>
                          Screen Text Language
                        </label>
                      </div>
                      <select
                        name='langText'
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) =>{ 
                          setLangId(e.target.value);
                          }}
                      >
                        <option value=''>Text Language</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='fv-row mb-10'>
                      <label class='form-label fs-6 fw-bolder text-dark'>Audio Language</label>
                      <select
                        name='langAudio'
                        id=''
                        class='form-control form-control-lg form-control-solid'
                        required
                        onChange={(e) => setAudioLang(e.target.value)}
                      >
                        <option value='0'>No Audio</option>

                        {langList
                          ? langList.map((langItem) => {
                              return <option value={langItem.id}>{langItem.lang}</option>
                            })
                          : ''}
                      </select>
                    </div>
                    <div class='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        class='btn btn-lg btn-primary w-100 mb-5'
                      >
                         {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                      </button>
                    </div>
                  </form>

        
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ActualExamStdModal
