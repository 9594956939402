import React from 'react'

import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function StudentMenus() {
 


  return (
    <>
  
      <AsideMenuItem to='/std-dashboard' icon='/media/icons/duotune/art/art002.svg'  title='DASHBOARD' fontIcon='bi-app-indicator'/>
      <AsideMenuItem  to='/exam-type' icon='/media/icons/duotune/layouts/lay010.svg' title='Practice Test' fontIcon='bi-users'/>
      <AsideMenuItem  to='/results/std' icon='/media/icons/duotune/layouts/lay010.svg' title='Result' fontIcon='bi-users'/>

    </>
  )
}
