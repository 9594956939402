import React from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <>
 

<div className='d-flex flex-column w-100 me-2'>
  {/* <div className='d-flex flex-stack mb-2'>
    <span className='text-muted me-2 fs-7 fw-bold'>{progress}%</span>
  </div> */}


  <div className='progress h-6px w-100'>
    <div
      className='progress-bar bg-success'
      role='progressbar'
      style={{ width: `${progress}%` }}
    ></div>
  </div>


</div>
</>
  );
};

export default ProgressBar;
