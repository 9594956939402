import React, { useEffect, useState } from 'react'
import { getExamResultForAdmin } from '../../../apis/ExamApi';
import { toastError } from '../../../global/Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const Results = () => {

  let {type} = useParams()



  const [search, setSearch] = useState('')
const [isLoading,setIsLoading]=useState();
const [resultData,setResultData]=useState([]);

const [page, setPage] = useState(1)
const [totalPage, setTotalPage] = useState(0)
const [perPage, setPerPage] = useState(5)

const [stdId, setStdId] = useState('')
const imageUrl = process.env.REACT_APP_IMAGE_URL

const getAllResultFunc = async (pageNo) => {
  setIsLoading(true)

  let data = JSON.stringify({
    std_id: type=='admin'?0:window.localStorage.getItem('stdId'),
    type:1

  })

  await getExamResultForAdmin(data,pageNo)
    .then((response) => {
      setIsLoading(false)
  

      if (response.data.data.data.length > 0) {
        setPerPage(response.data.data.per_page)
        setTotalPage(response.data.data.total)
        setResultData(response.data.data.data)
      } 
      else {
        toastError('Content not available')
        setResultData('')
      }

    })
    .catch((err) => {
      console.log(err)
      setIsLoading(false)
    })
}


const navigate=useNavigate();

useEffect(() => {
  setStdId(window.localStorage.getItem('stdId'))
  getAllResultFunc(page)
}, [])
  return (
    <>
        <h2>Result List</h2>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Student'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>


            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>STUDENT NAME</th>
                    <th className='text-end'>STUDENT ID</th>
                    <th className='text-end'>HELD ON</th>
                    <th className='text-end'>COURSE</th>
                    <th className='text-end'>STATUS</th>
                    <th className='text-end'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
               
                     
                {resultData
                    ? resultData.filter((item) => {
                      return search.toLowerCase() === ''
                        ? item
                        : item.student.name.toLowerCase().includes(search) ||
                            item.student.tarffic_id.includes(search)
                    }).map((item) => {
                   
                          return (

                            <tr key={item.id}>


                            <td className='d-flex align-items-center'>
                                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <a>
                                    <div className='symbol  symbol-40px symbol-circle'>
                                      
                          {
                            item.image?

                            <img src={imageUrl + item.student.image} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
                                    </div>
                                  </a>
                                </div>

                                <div className='d-flex flex-column'>
                                  <a>{item.student.name +' '+ item.student.l_name}  </a>
                                  <span>{item.email!='null'? item.student.email:''}</span>
                                </div>
                              </td>

                            <td className='text-end'>{item.student?item.student.tarffic_id:''}</td>
                            <td className='text-end'>{item.created_at}</td>
                            <td className='text-end'>{item.course?item.course.cat_title:''}</td>
                    
                            <td className='text-end'>
                            {item && item.correct_ans >= item.correct_ans_required?
                            <span className='badge badge-light-success'>Pass</span>
                            :
                            <span className='badge badge-light-danger'>Fail</span>
                            }
                            </td>
                            <td className='text-end'>
                            <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>

                                  <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => {navigate(`/view-result/${item.exam_id}/${item.student.name}`)}} >View</Dropdown.Item>
                                  <Dropdown.Item onClick={() => {navigate(`/print-result/${item.exam_id}`)}} >Print</Dropdown.Item>
                                  </Dropdown.Menu>
                             
                                </Dropdown>
                             
                             
                            </td>

                            </tr>

                            )
                        })
                    : ''}
                 
                </tbody>
              </table>
              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    getAllResultFunc(page)
                  }}
                  ellipsis={1}
                />
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Results
