import React, { useEffect, useState } from 'react'
import MyProfile from './MyProfile';
import StdProfile from './StdProfile';

const Profile = () => {

  const [userType, setUserType] = useState('')

  const handleUserInfo = async () => {
    setUserType(window.localStorage.getItem('userType'));

  }

  useEffect(() => {
    handleUserInfo()
  }, [])

  return (
    <>
    {
      (userType== 1) ?
    <MyProfile/>
    :
    <StdProfile/>
  }
  </>
  )
}

export default Profile