import React, {useEffect, useRef, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {KTSVG} from '../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {deleteCategory, editCategory, getAllCategory, getAllCourses, saveCategory, updateCategory} from '../apis/CategoryApi'
import {Link, useNavigate} from 'react-router-dom'
import { swalSuccess, toastError, toastSuccess } from '../global/Global'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify';

const Course = () => {

  const saveButton = useRef(null);
  const editButton = useRef(null);

  const [isLoading, setIsLoading] = useState(false)
  const [courseData, setCourseData] = useState([])

  const [title, setTitle] = useState('')
  const [code, setCode] = useState('')
  const [status, setStatus] = useState(1)
  const [id, setId] = useState("");


  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [isStatusCheck, setIsStatusCheck] = useState(false);

  const navigate=useNavigate();


  const autoButtonClick=(buttonType)=>{

    buttonType.current.addEventListener('click',handleGetAllCourses);
    buttonType.current.click();
  }

  const handleGetAllCourses = async (pageNo) => {
    setIsLoading(true)

    await getAllCourses(pageNo)
      .then((response) => {
        // console.log('All courses',response.data.code)
        if(response.data.code!=206){
      
        setPerPage(response.data.data.per_page)
        setTotalPage(response.data.data.total)
        setCourseData(response.data.data.data)
        
        }else{
          setCourseData('');
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleDelete = async (id) => {
  
    try {
      let data = JSON.stringify({
        id: id,
      });

      await deleteCategory(data)
        .then((response) => {
          handleGetAllCourses(page);
          toastSuccess('Record deleted successfully');
      
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
       toastError("Record not deleted");
    }
  };


  const deleteRecord = (id) => {
    Swal.fire({
      text: "Are you sure you want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F1416C",
      cancelButtonColor: "#181C32",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);


    let data = JSON.stringify({
      title: title,
      code: code,
      status: status,
      
    });

    await saveCategory(data)
      .then((response) => {
        setIsLoading(false);
        // console.log('Post Responce',response.data.code);

        if (response.data.code==200) {
         autoButtonClick(saveButton);
          setTitle('');
          setStatus(0);
          setCode('');
          getAllCourses();
    
           toastSuccess('Record save successfully');
      
        }
        if (response.data.error) {
           toastError(response.data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleEdit = async (id) => {

    let data = JSON.stringify({
      id: id,
    });

    await editCategory(data)
      .then((response) => {
        // console.log('Edit data',response);
        var statValue = 1;
        if (response.data.data.status != "Active") {
          statValue = 0;
        }
        setId(response.data.data.id);
        setTitle(response.data.data.cat_title);
        setCode(response.data.data.course_code);
        setStatus(statValue);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleUpdateCourse= async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let data = JSON.stringify({
      id: id,
      title: title,
      code: code,
      status: status,
    });

    await updateCategory(data)
      .then((response) => {
        setIsLoading(false);
        console.log(response);

        if (response.data.code==200) {
          autoButtonClick(editButton);
          setTitle();
          setStatus(0);
          setCode(0);
          handleGetAllCourses();
          toastSuccess('Record updated successfully');
      
        }

        if (response.data.error) {
          // toastError(response.data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };


  const modalCloseEvent=()=>{
    setTitle('');
    setCode('');
    setStatus(false);

  }



  useEffect(() => {
    handleGetAllCourses(page)
  }, [])


  const [permArray, setPermArray] = useState([])
  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermArray(JSON.parse(storedArray))
    }
  }, [])

  return (
    <>
      <h2>Courses</h2>
      <ToastContainer />

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    class='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Course'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div class='card'>

                <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                {permArray.some(item => item.name === 'course-create')? 
                  <button
                    className='btn btn-sm btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                    style={{marginRight: '-30px'}}
                  >
                    Add Course
                  </button>
                :''}
                </div>
              </div>
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>

                
                    <th className='min-w-125px'>Courses</th>
                    <th className='text-end min-w-125px'>STATUS</th>
                    <th className='text-end min-w-100px'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
                  {courseData
                    ? courseData
                        .filter((item) => {
                          return search.toLowerCase() === ''
                            ? item
                            : item.cat_title.toLowerCase().includes(search)||
                            item.course_code.toLowerCase().includes(search)
                        })
                        .map((item) => {
                          return (
                            <tr>
                       

                              <td className='d-flex align-items-center'>
                                {/* <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'></div> */}
                                <div className='d-flex flex-column'>
                                  <span className='text-gray-800 text-hover-primary mb-1'>
                                    {item.course_code}
                                  </span>
                                  <span>{item.cat_title}</span>
                                </div>
                              </td>

                              <td className='text-end'>
                              
                                <span className={`badge badge-light-${item.status=='Active' ? 'success' : 'danger'}`}>{item.status}</span>
                              </td>

                              <td className='text-end'>
                                <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>

                                  <Dropdown.Menu>


                                  <Dropdown.Item onClick={() => {navigate(`/course-config/${item.id}/${item.cat_title}`)}} >Configuration</Dropdown.Item>
                                   
                                  {permArray.some(item => item.name === 'course-edit')? 
                                  <Dropdown.Item
                                     data-bs-toggle='modal'
                                    data-bs-target='#edit_modal'
                                      onClick={() => {
                                        handleEdit(item.id);
                                      }}
                                    >
                                      {" "}
                                      Edit
                                    </Dropdown.Item>
                                    :''}
                                    {permArray.some(item => item.name === 'course-delete')? 
                                    <Dropdown.Item
                                      onClick={() => {
                                         deleteRecord(item.id);
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    :''}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        })
                    : ''}
                </tbody>
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    // console.log(page)
                     handleGetAllCourses(page);
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='kt_modal_create_app'
        aria-hidden='true'
        style={{marginTop: '100px'}}
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'  onClick={()=> modalCloseEvent()}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>Add New Course</h1>
              </div>


              <form onSubmit={handleSubmit}>

            
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Course Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Course Name'
                    ></i>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='appName'
                    placeholder='Course Title'
                    required
                    onChange={(e)=>setTitle(e.target.value)}
                  />
                  <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Course Short Title</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Course Short Title'
                    ></i>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='appName'
                    placeholder='Course Short Title'
                    required
                    onChange={(e)=>setCode(e.target.value)}
                  />
                  <div className='text-danger'></div>
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-5 fw-bold'>
                    <label className='fs-6'>Course Status</label>
                 
                  </div>

                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' 
                 
                     checked={status}
                        onChange={handleStatusChange}
                     />

                    <span className='form-check-label fw-bold text-muted'>Active</span>
                  </label>
                </div>

                <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='reset' className='btn btn-light me-3' data-bs-dismiss='modal' id='modalCancel' ref={saveButton}
               onClick={()=> modalCloseEvent()}>
                Cancel
              </button>

              <button type="submit" class="btn btn-primary">
                {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>

              </form>
            </div>
          </div>
        </div>
      </div>



         {/* edit modal */}
    <div className='modal fade' id='edit_modal' aria-hidden='true' style={{marginTop:"100px"}}>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary btn-close' data-bs-dismiss='modal'  onClick={()=> modalCloseEvent()}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Edit Course</h1>
            </div>


         
              <form onSubmit={handleUpdateCourse}>

             
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Course Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='appName'
                    placeholder='Course Title'
                    required
                    onChange={(e)=>setTitle(e.target.value)}
                    value={title}
                    
                  />
                  <div className='text-danger'></div>
                </div>

                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Course Short Title</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='appName'
                    placeholder='Course Code'
                    required
                    onChange={(e)=>setCode(e.target.value)}
                    value={code}
                  />
                  <div className='text-danger'></div>
                </div>

                <div className='d-flex flex-stack'>
                  <div className='me-5 fw-bold'>
                    <label className='fs-6'>Course Status</label>
                 
                  </div>

                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' 
                 checked={status}
                        onChange={handleStatusChange}
                      />

                    <span className='form-check-label fw-bold text-muted'>Active</span>
                  </label>
                </div>

                <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='reset' className='btn btn-light me-3' data-bs-dismiss='modal' id='modalCancel' ref={editButton}  onClick={()=> modalCloseEvent()}>
                Cancel
              </button>

              <button type="submit" class="btn btn-primary">
                {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>

              </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Course
