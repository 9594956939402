import React, {useEffect, useRef, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {deleteLang, editLang, getAllLang, saveLang, updateLang} from '../apis/LanguageApi'
import {swalSuccess, toastError, toastSuccess} from '../global/Global'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import {deleteStudent, getAllStudents} from '../apis/StudentApi.js'
import {Link, Router, useNavigate} from 'react-router-dom'

const Students = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const navigate=useNavigate();


  const [isLoading, setIsLoading] = useState(false)
  const [stdData, setStdData] = useState([])
  const [lang, setLang] = useState('')
  const [status, setStatus] = useState(0)
  const [direction, setDirection] = useState(0)
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(5)
  const [isDirectionCheck, setIsDirectionCheck] = useState(false)
  const [isStatusCheck, setIsStatusCheck] = useState(false)

  const saveButton = useRef(null)
  const editButton = useRef(null)

  useEffect(() => {
    getAllStdFunc(page)
  }, [])

  const getAllStdFunc = async (pageNo) => {
    setIsLoading(true)

    await getAllStudents(pageNo)
      .then((response) => {
        setIsLoading(false)
     
       if(response.data.code!=204){
        setPerPage(response.data.data.per_page)
        setTotalPage(response.data.data.total)
        setStdData(response.data.data.data)
   
       }else{
        setStdData('')
        toastError('Content not available');
       }

      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleDelete = async (id) => {
    try {
      let data = JSON.stringify({
        id: id,
      })

      await deleteStudent(data)
        .then((response) => {
          getAllStdFunc()
          toastSuccess('Record deleted successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      toastError('Record not deleted')
    }
  }

  const deleteRecord = (id) => {
    Swal.fire({
      text: 'Are you sure you want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id)
      }
    })
  }

  const handleEditNav=(eUserId)=>{
    
    navigate(`/edit-student/${eUserId}`);
   }

   const [permArray, setPermArray] = useState([])
   useEffect(() => {
     const storedArray = window.localStorage.getItem('permissions')
     if (storedArray) {
       setPermArray(JSON.parse(storedArray))
     }
   }, [])
 



  return (
    <>
      <h2>Students List</h2>
      <ToastContainer />

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    class='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Student'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className='card'>
              {permArray.some(item => item.name === 'std-create')? 
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <Link to="/add-student">
                    <button type="button" className="btn btn-primary"  style={{marginRight:'-30px'}}> Add Student </button>
                  </Link>
                </div>
                :''}


              </div>
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>

             
                    <th className='min-w-125px'>Name/Email</th>
                    <th className='min-w-125px'>TRAFFIC ID</th>
                    <th className='min-w-125px'>LAST LOGIN</th>
                    <th className='min-w-125px'>LAST TEST STATUS</th>
                    <th className='min-w-125px'>JOINED DATE</th>
                    <th className='min-w-125px'>STATUS</th>
                    <th className='text-end min-w-100px'>ACTIONS</th>
                  </tr>
                </thead>

                <tbody class='text-gray-600 fw-bold'>
                  
                  {stdData
                    ? stdData
                        .filter((item) => {
                         
                          return search.toLowerCase() === ''
                            ? item
                            : item.name.toLowerCase().includes(search)
                            ||
                            item.tarffic_id.includes(search)
                        })
                        .map((item) => {
                          return (
                            <tr key={item.id}>

                         
                              <td class='d-flex align-items-center'>
                                <div class='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <a>
                                    <div class='symbol  symbol-40px symbol-circle'>
                                      
                          {
                            item.image?

                            <img src={imageUrl + item.image} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
                                    </div>
                                  </a>
                                </div>

                                <div class='d-flex flex-column'>
                                  <a>{item.name +' '+ item.l_name}  </a>
                                  <span>{item.email!='null'? item.email:''}</span>
                                </div>
                              </td>

                              <td>{item.tarffic_id}</td>

                              <td>
                                <div className='badge badge-light-success'>{item.history?item.history.created_at:'-'}</div>
                              </td>

                              <td>
                                <span>{item.result?item.result.status:'-'}</span>
                              </td>

                              <td>{item.created_at}</td>
                              <td> 
                              {item.status=='Active'?
                              <span className='badge badge-light-success'>{item.status}</span>
                              :
                              <span className='badge badge-light-danger'>{item.status}</span>
                              }
                              </td>

                              <td className='text-end'>
                        
                                <Dropdown>
                                
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>

                                  <Dropdown.Menu>
                                  {permArray.some(item => item.name === 'std-edit')? 
                                    <Dropdown.Item 
                                     onClick={() => {
                                        handleEditNav(item.id)
                                      }}
                                    >
                              Edit
                                    </Dropdown.Item>
                                    :''}
                                    {permArray.some(item => item.name === 'std-delete')? 
                                    <Dropdown.Item
                                      onClick={() => {
                                        deleteRecord(item.id)
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    :''}
                                  </Dropdown.Menu>
                                </Dropdown>
                           
                              </td>
                            </tr>
                          )
                        })
                    : ''}
                </tbody>
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                   
                    getAllStdFunc(page)
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Students
