import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import { toastError, toastSuccess } from '../global/Global'
import { getCourseConfigInfo, saveCourseConfig } from '../apis/CategoryApi'
import { exit } from 'process'

const CourseConfig = () => {
  let {id,title} = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [courseId, setCourseId] = useState(id)
  const [totalQuestion, setTotalQuestion] = useState('')
  const [correctAns, setCorrectAns] = useState('')
  const [testTime, setTestTime] = useState('')
  const [videoTime, setVideoTime] = useState('')
  const [videoTotalQuestion, setVideoTotalQuestion] = useState('')
  const [videoCorrect, setVideoCorrect] = useState('')



  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);


    let data = JSON.stringify({

	  courseId: id,
      totalQuestion: totalQuestion,
      correctAns: correctAns,
	  testTime: testTime,
	  videoTime: videoTime,
	  videoTotalQuestion: videoTotalQuestion,
	  videoCorrect: videoCorrect,
      
    });



    await saveCourseConfig(data)
      .then((response) => {
		
        setIsLoading(false);

        if (response.data.code==200) {
      //     setTotalQuestion('');
		  // setCorrectAns('');
		  // setTestTime('');
		  // setVideoTime('');
		  // setVideoTotalQuestion('');
		  // setVideoCorrect('');
           toastSuccess('Record save successfully');
      
        }

		if (response.data.code==404) {
			toastError(response.data.message);
		
		  }
      
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };




  const handleGetCourseConfigInfo = async () => {

    let data = JSON.stringify({
      id: id,
    });

    await getCourseConfigInfo(data)
      .then((response) => {

        if(response.data.code==200){
          setCourseId(response.data.data.id);
          setTotalQuestion(response.data.data.total_question);
          setCorrectAns(response.data.data.correct_ans);
          setTestTime(response.data.data.standard_time);
          setVideoTime(response.data.data.video_time);
          setVideoCorrect(response.data.data.video_correct);
          setVideoTotalQuestion(response.data.data.video_question);
        }else{
          setCourseId('');
          setTotalQuestion('');
          setCorrectAns('');
          setTestTime('');
          setVideoTime('');
          setVideoCorrect('');
          setVideoTotalQuestion('');
        }
    
 
      
   
        
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    handleGetCourseConfigInfo()
  }, [])



  return (
    <>
      <h3>{title} Course Configuration</h3>
      <form className='form' onSubmit={handleSubmit}>
        <div className='card mb-5 mb-xl-10'>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6 '>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                  <span class='form-check form-check-custom form-check-solid'></span>
                </label>

                <div className='col-lg-4 '>
                  <span class='form-check form-check-custom form-check-solid'>
                    Standard Questions
                  </span>
                </div>

                <label className='col-lg-1 col-form-label  fw-bold fs-6'></label>
                <div className='col-lg-4 fv-row'>
                  <span class='form-check form-check-custom form-check-solid'>Video Questions</span>
                </div>
              </div>

              <div className='row mb-6 '>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                  <span class='form-check form-check-custom form-check-solid'>
                    No Of Questions For Test
                  </span>
                </label>

                <div className='col-lg-4 '>
                  <input
                    type='number'
                    name='opt_a'
                    className='form-control form-control-solid'
                    placeholder=' No Of Questions For Test'
                    onChange={(e) => setTotalQuestion(e.target.value)}
                    value={totalQuestion}
                  />
                </div>

                <label className='col-lg-1 col-form-label  fw-bold fs-6'></label>
                <div className='col-lg-4 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-solid'
                    onChange={(e) => setVideoTotalQuestion(e.target.value)}
                    value={videoTotalQuestion}
					placeholder='Video Total Questions'
                  />
                </div>
              </div>

              <div className='row mb-6 '>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                  <span class='form-check form-check-custom form-check-solid'>
                    No Of Correct Answers
                  </span>
                </label>

                <div className='col-lg-4 '>
                  <input
                    type='number'
                    className='form-control form-control-solid'
                    placeholder='   No Of Correct Answers'
                    onChange={(e) => setCorrectAns(e.target.value)}
                    value={correctAns}
                  />
                </div>

                <label className='col-lg-1 col-form-label  fw-bold fs-6'></label>
                <div className='col-lg-4 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-solid'
                    onChange={(e) => setVideoCorrect(e.target.value)}
                    value={videoCorrect}
					placeholder='Video Correct Answer'
                  />
                </div>
              </div>

              <div className='row mb-6 '>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                  <span class='form-check form-check-custom form-check-solid'>
                    Time for Test (Minutes)
                  </span>
                </label>

                <div className='col-lg-4 '>
                  <input
                    type='number'
                    className='form-control form-control-solid'
                    placeholder='Time for Test (Minutes)'
                    onChange={(e) => setTestTime(e.target.value)}
                    value={testTime}
                  />
                </div>
              </div>

              <div className='row mb-6 '>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                  <span class='form-check form-check-custom form-check-solid'>
                    Time For One Video Question (Seconds)
                  </span>
                </label>

                <div className='col-lg-4 '>
                  <input
                    type='number'
                    className='form-control form-control-solid'
                    placeholder='Time For One Video Question (Seconds)'
                    onChange={(e) => setVideoTime(e.target.value)}
                    value={videoTime}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end py-6 px-9'>
          <Link to='/courses'>
            <button type='button' class='btn btn-default'>
              Cancel
            </button>
          </Link>
          <button type='submit' class='btn btn-primary'>
		  {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Save"
                )}
            
          </button>
        </div>
      </form>
    </>
  )
}

export default CourseConfig
