import React, {useEffect, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {KTSVG} from '../../_metronic/helpers'
import {Dropdown} from 'react-bootstrap'
import {Link, Router, useNavigate} from 'react-router-dom'
import {deleteUser, getAllUsers} from '../apis/UserApi'
import { swalSuccess, toastError, toastSuccess } from '../global/Global'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify';
import { toAbsoluteUrl } from '../../_metronic/helpers'

const Users = () => {

const imageUrl=process.env.REACT_APP_IMAGE_URL;


  const [isLoading, setIsLoading] = useState(false)
  const navigate=useNavigate();


  const [search, setSearch] = useState('')
  const [userData, setUserData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(0)

  const handleGetAllUser = async (pageNo) => {
    setIsLoading(true)
    await getAllUsers(pageNo)
      .then((response) => {
        // console.log('Get All User', response.data.data)
        setUserData(response.data.data.data)
        setPerPage(response.data.data.per_page)
        setTotalPage(response.data.data.total)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleEditNav=(eUserId)=>{
    
   navigate(`/edit-user/${eUserId}`);
  }



  const deleteUserNow = async (id) => {
  
    try {
      let data = JSON.stringify({
        id: id,
      });

      await deleteUser(data)
        .then((response) => {
          handleGetAllUser(page);
          toastSuccess('Record deleted successfully');
      
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
       toastError("Record not deleted");
    }
  };


  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F1416C",
      cancelButtonColor: "#181C32",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserNow(id);
      }
    });
  };


  const [permArray, setPermArray] = useState([])
  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermArray(JSON.parse(storedArray))
    }
  }, [])

  useEffect(() => {
    handleGetAllUser(page);
  }, []);


  return (
    <>
       <ToastContainer />
      <h2>Users List</h2>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>

                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    class='form-control form-control-solid w-250px ps-14'
                    placeholder='Search User'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div class='card'>
                <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  <div class='card-toolbar'>
                    <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                   
                      {permArray.some(item => item.name === 'user-create')? 
                      <Link to='/add-user'>
                        <button
                          type='button'
                          class='btn btn-primary'
                          style={{marginRight: '-30px'}}
                        >
                          Add User
                        </button>
                      </Link>
                      :''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body pt-0'>
              <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr class='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>

                
                    <th class='min-w-125px'>User</th>
                    <th class='min-w-125px'>Role</th>
                    {/* <th class='min-w-125px'>Last login</th> */}
                    <th class='min-w-125px'>Status</th>
                    <th class='min-w-125px'>Joined Date</th>
                    <th class='text-end min-w-100px'>Actions</th>
                  </tr>
                </thead>

                <tbody class='text-gray-600 fw-bold'>

                {userData
                    ? userData.filter((item) => {
                  return search.toLowerCase() === ''
                  ? item
                  : item.name.toLowerCase().includes(search) 
                  || item.email.toLowerCase().includes(search)
                  || item.status.toLowerCase().includes(search);
              }).map((item) => {
                        return (
                  <tr>

             

                    <td class='d-flex align-items-center'>
                      <div class='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                        <a>
                          <div class='symbol  symbol-40px symbol-circle'>
                        
                           
                         

                          {
                            item.image?

                            <img src={imageUrl + item.image} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
                            
                          </div>
                        </a>
                      </div>

                      <div class='d-flex flex-column'>
                        <a>{item.name}</a>
                        <span>{item.email}</span>
                      </div>
                    </td>

                    <td>{item.role && item.role.name}</td>

                    {/* <td>
                      <div class='badge badge-light fw-bolder'>20 min ago</div>
                    </td> */}
                    
                      <td>
                     
                      <span className={`badge badge-light-${item.status=='Active' ? 'success' : 'danger'}`}>{item.status}</span>
                    
                    </td>

                    <td>{item.created_at}</td>
                    
                    <td className='text-end'>
                  
                                <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>
                       
                                  <Dropdown.Menu>
                                  {permArray.some(item => item.name === 'user-edit')? 
                             <Dropdown.Item   onClick={() => {
                                         handleEditNav(item.id);
                                      }}>Edit </Dropdown.Item>
                                      :''}
                                 
                               
                                      {permArray.some(item => item.name === 'user-delete')? 
                                    <Dropdown.Item
                                      onClick={() => {
                                         handleDelete(item.id);
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    :''}
                                  </Dropdown.Menu>
                                
                                </Dropdown>
                               
                              </td>
                  </tr>

                  );
                      })
                    : ""}
                </tbody>
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    
                   handleGetAllUser(page);
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Users
