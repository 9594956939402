import React from 'react'
import { Link } from 'react-router-dom';

const CoursesSection = (props) => {

    const {courseProps,resultProps} = props;

  return (
    <>
           <div class='col-xl-6'>
            <div
              id='kt_sliders_widget_1_slider'
              class='card card-flush carousel carousel-custom carousel-stretch slide h-xl-100'
              data-bs-ride='carousel'
              data-bs-interval='5000'
            >
              <div class='card-header pt-5 mb-6'>
                <h4 class='card-title d-flex align-items-start flex-column'>
                  <span class='card-label fw-bolder text-gray-800'>Courses</span>
                  <span class='text-gray-400 mt-1 fw-bolder fs-7'>
                 {courseProps.totalCourse} Total Courses,   {courseProps.totalQuestion}  Total Questions
                  </span>
                </h4>

                {/* <div class='card-toolbar'>
                  <ol class='p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary'>
                    <li
                      data-bs-target='#kt_sliders_widget_1_slider'
                      data-bs-slide-to='0'
                      class='active ms-1'
                    ></li>
                    <li
                      data-bs-target='#kt_sliders_widget_1_slider'
                      data-bs-slide-to='1'
                      class='ms-1'
                    ></li>
                    <li
                      data-bs-target='#kt_sliders_widget_1_slider'
                      data-bs-slide-to='2'
                      class='ms-1'
                    ></li>
                  </ol>
                </div> */}
              </div>

              <div class='card-body pt-6'>

                <div class='carousel-inner mt-n5'>

                  <div class=''>

                    <div class='d-flex align-items-center mb-5'>
                      <div class='w-80px flex-shrink-0 me-2'>

                      <div class="symbol symbol-70px symbol-circle ">
   <span class="symbol-label bg-light-success">
      <span class="svg-icon svg-icon-3x svg-icon-success">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z" fill="black"></path>
            <path opacity="0.3" d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z" fill="black"></path>
         </svg>
      </span>
   </span>
</div>
                      </div>

                      <div class='m-0'>
                        <h4 class='fw-bolder text-gray-800 mb-3'>{courseProps.courseInfo ? courseProps.courseInfo.course_code:''} - {courseProps.courseInfo ? courseProps.courseInfo.cat_title:''}</h4>

                        <div class='d-flex d-grid gap-5'>
                          <div class='d-flex flex-column flex-shrink-0 me-4'>
                            <span class='d-flex align-items-center fs-7 fw-bolder text-gray-400 mb-2'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {courseProps.courseInfo && courseProps.courseInfo.course_config ? courseProps.courseInfo.course_config.total_question:''}  Questions
                            </span>

                            <span class='d-flex align-items-center text-gray-400 fw-bolder fs-7'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {courseProps.courseInfo && courseProps.courseInfo.course_config ? courseProps.courseInfo.course_config.video_question:''} Video
                            </span>
                          </div>

                          <div class='d-flex flex-column flex-shrink-0'>
                            <span class='d-flex align-items-center fs-7 fw-bolder text-gray-400 mb-2'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {courseProps.courseInfo && courseProps.courseInfo.course_config ? courseProps.courseInfo.course_config.standard_time:''} Min
                            </span>

                            <span class='d-flex align-items-center text-gray-400 fw-bolder fs-7'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {courseProps.totalResults} Exam
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mb-1'>
                      <Link to="/q-bank"  className='btn btn-sm btn-light me-2'>  Add Question</Link>
                      <Link to="/exams"   className='btn btn-sm btn-primary'>Schedule Exams</Link>        
                    </div>
                  </div>

              
                </div>
              </div>
            </div>
          </div>

          <div class='col-xl-6'>
            <div
              id='kt_sliders_widget_1_slider'
              class='card card-flush carousel carousel-custom carousel-stretch slide h-xl-100'
              data-bs-ride='carousel'
              data-bs-interval='5000'
            >
              <div class='card-header pt-5 mb-6'>
                <h4 class='card-title d-flex align-items-start flex-column'>
                  <span class='card-label fw-bolder text-gray-800'>Result Overview</span>
                  <span class='text-gray-400 mt-1 fw-bolder fs-7'>
                 {resultProps.totalResult} Exams so far,
                  </span>
                </h4>

                {/* <div class='card-toolbar'>
                  <ol class='p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary'>
                    <li
                      data-bs-target='#kt_sliders_widget_1_slider'
                      data-bs-slide-to='0'
                      class='active ms-1'
                    ></li>
                    <li
                      data-bs-target='#kt_sliders_widget_1_slider'
                      data-bs-slide-to='1'
                      class='ms-1'
                    ></li>
                    <li
                      data-bs-target='#kt_sliders_widget_1_slider'
                      data-bs-slide-to='2'
                      class='ms-1'
                    ></li>
                  </ol>
                </div> */}
              </div>

              <div class='card-body pt-6'>

                <div class='carousel-inner mt-n5'>

                  <div class=''>

                    <div class='d-flex align-items-center mb-5'>
                      <div class='w-80px flex-shrink-0 me-2'>

                      <div class="symbol symbol-70px symbol-circle ">
   <span class="symbol-label bg-light-primary">
      <span class="svg-icon svg-icon-3x svg-icon-primary">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path opacity="0.3" d="M11.425 7.325C12.925 5.825 15.225 5.825 16.725 7.325C18.225 8.825 18.225 11.125 16.725 12.625C15.225 14.125 12.925 14.125 11.425 12.625C9.92501 11.225 9.92501 8.825 11.425 7.325ZM8.42501 4.325C5.32501 7.425 5.32501 12.525 8.42501 15.625C11.525 18.725 16.625 18.725 19.725 15.625C22.825 12.525 22.825 7.425 19.725 4.325C16.525 1.225 11.525 1.225 8.42501 4.325Z" fill="black"/>
<path d="M11.325 17.525C10.025 18.025 8.425 17.725 7.325 16.725C5.825 15.225 5.825 12.925 7.325 11.425C8.825 9.92498 11.125 9.92498 12.625 11.425C13.225 12.025 13.625 12.925 13.725 13.725C14.825 13.825 15.925 13.525 16.725 12.625C17.125 12.225 17.425 11.825 17.525 11.325C17.125 10.225 16.525 9.22498 15.625 8.42498C12.525 5.32498 7.425 5.32498 4.325 8.42498C1.225 11.525 1.225 16.625 4.325 19.725C7.425 22.825 12.525 22.825 15.625 19.725C16.325 19.025 16.925 18.225 17.225 17.325C15.425 18.125 13.225 18.225 11.325 17.525Z" fill="black"/>
</svg>
      </span>
   </span>
</div>
                      </div>

                      <div class='m-0'>
                        <h4 class='fw-bolder text-gray-800 mb-3'>{resultProps && resultProps.course_code}  - {resultProps && resultProps.course_name}</h4>

                        <div class='d-flex d-grid gap-5'>
                          <div class='d-flex flex-column flex-shrink-0 me-4'>
                            <span class='d-flex align-items-center fs-7 fw-bolder text-gray-400 mb-2'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {resultProps && resultProps.totalResult}  Results
                            </span>

                            <span class='d-flex align-items-center text-gray-400 fw-bolder fs-7'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {resultProps && resultProps.passResult} Passed
                            </span>
                          </div>

                          <div class='d-flex flex-column flex-shrink-0'>
                            <span class='d-flex align-items-center fs-7 fw-bolder text-gray-400 mb-2'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              {resultProps && resultProps.todayResult} Today Result
                            </span>

                            <span class='d-flex align-items-center text-gray-400 fw-bolder fs-7'>
                              <span class='svg-icon svg-icon-6 svg-icon-gray-600 me-2'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='5'
                                    fill='black'
                                  />
                                  <path
                                    d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                                    fill='black'
                                  />
                                </svg>
                              </span>
                              { resultProps.failResult}   Fail
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mb-1'>
                 
                      <Link to="/results/admin"   className='btn btn-sm btn-primary'>Results </Link>        
                    </div>
                  </div>

              
                </div>
              </div>
            </div>
          </div>

     
    </>
  )
}

export default CoursesSection
