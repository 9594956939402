import React, {useState,useEffect} from 'react'
import userImage from '../assets/img/userImage.png'
import { getAllRoles } from '../apis/RolesApi'
import { createUsers, editUser, updateUser } from '../apis/UserApi'
import { swalSuccess, toastSuccess } from '../global/Global'
import { useParams } from 'react-router-dom'

const MyProfile = () => {

  const userId  = localStorage.getItem('userId');

  const imageUrl=process.env.REACT_APP_IMAGE_URL;

  const [isLoading, setIsLoading] = useState(false)
  const [roleData, setRoleData] = useState([])
  const [status, setStatus] = useState(0)
  const [userAvatar, setUserAvatar] = useState('')
  const [file, setFile] = useState('')


  const [isStatusCheck, setIsStatusCheck] = useState(false)

  const [data, setData] = useState({
    id: '',
    fname: '',
    lname: '',
    userid: '',
    email: '',
    phone: '',
    password: '',
    confPassword: '',
    role: '',
    layout: '',
    allowTest: 0,
  })


  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    // console.log(name, value)
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const handleStatusChange=()=>{
    data.isStatusCheck ? setStatus(0) : setStatus(1)
    setIsStatusCheck((prev) => !prev)
  
   
  }

  const getAllRolesFunc = async () => {
    setIsLoading(true);
    await getAllRoles()
      .then((response) => {
      
        setRoleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleEditUser = async (id) => {

    let data = JSON.stringify({
      user_id:id,
    });

    await editUser(data)
      .then((response) => {
        // console.log('Edit data',response.data.data);
        var statValue = 1;
        if (response.data.data.status != "Active") {
          statValue = 0;
        }

   setData({
    id:response.data.data.id ,
    fname: response.data.data.name,
    lname: response.data.data.l_name,
    userid: response.data.data.user_id,
    email: response.data.data.email,
    phone: response.data.data.phone,
    role: response.data.data.role_id,
    layout: 1,
   });
    setStatus(statValue);
  //  setFile(response.data.data.image);
   setUserAvatar(response.data.data.image);

      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    getAllRolesFunc();
    handleEditUser(userId);
 

  }, []);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const FormData = require("form-data");
    let newData = new FormData();
    

    newData.append("id", data.id);
    newData.append("f_name", data.fname);
    newData.append("l_name", data.lname);
    newData.append("email", data.email);
    newData.append("phone", data.phone);
    newData.append("user_id", data.userid);
    newData.append("role_id", data.role);
    newData.append("status", status);
    newData.append("file",file);
    newData.append("password",data.password);


    await updateUser(newData)
      .then((response) => {
        console.log('response',response);
        setIsLoading(false);
        // console.log(response.data.code);
        if (response.data.code==200) {
         toastSuccess('Record updated successfully');
        }
     
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };




  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Update Profile</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form  className='form' onSubmit={handleSubmitForm}>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Avatar</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-8 fv-row'>

                    
                  <div className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${userImage})`}}
                    >

                    
                      {(file) ? (
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={URL.createObjectURL(file)}
                          alt='Thumb'
                        />
                      ) : (
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: `url(${imageUrl + userAvatar})`}}

                        ></div>
                      )}

                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title='Change avatar'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>
                        <input
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e) => {
                            setFile(e.target.files[0])
                          }}
                        />
                        <input type='hidden' name='avatar_remove' />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
        
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      name='fname'
                      value={data.fname}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      name='lname'
                      value={data.lname}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>User ID</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='User ID'
                  name='userid'
                  value={data.userid}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  name='email'
                  value={data.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone'
                  name='phone'
                  value={data.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  name='password'
                  value={data.password}
                  onChange={handleChange}
                  autocomplete="new-password"
                />
              </div>

            
            </div>


      

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>User Role</label>

              <div className='col-lg-8 fv-row'>
              <select name="role" className="form-control" value={data.role} onChange={handleChange} required>
        <option value=''>Choose Role</option>
        {roleData?
            roleData.map((item) => {
                    return (
        <option value={item.id}>{item.name}</option>

        );
                  })
                  :''}
   
      </select>
              </div>
            </div> */}

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Test Layout</label>

              <div className='col-lg-8 fv-row'>
                <select className='form-select form-select-solid form-select-lg' required>
                  <option value='1'>Layout-1</option>
                </select>
              </div>
            </div> */}

      

            {/* <div className='row mb-0'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input className='form-check-input w-45px h-30px' type='checkbox'  
                  onChange={(e) => handleStatusChange()}
                  checked={status==1?'checked':""}
                   />
                  <label className='form-check-label'></label>
                </div>
              </div>
            </div> */}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="submit" class="btn btn-primary">
                {isLoading ? (
                  <div><i className="fa fa-spinner fa-spin"></i></div>
                ) : (
                  "Save Changes"
                )}
              </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MyProfile
