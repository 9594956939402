import React, {useEffect, useRef, useState} from 'react'
import {MixedWidget7} from '../../../_metronic/partials/widgets'
import {VideoExamWidgets} from '../../../_metronic/partials/widgets/mixed/VideoExamWidgets'
import {getPracticeExamResult} from '../../apis/ExamApi'
import {toastError} from '../../global/Global'

import {editLang, getAllLangForDropdown} from '../../apis/LanguageApi'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import { countAllAudioVidQuestions } from '../../apis/CategoryApi'
import { useTranslation } from 'react-i18next';

const ExamType = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [examResult, setExamResult] = useState([])
  const [totalQuestion, setTotalQuestion] = useState('')
  const [vidTotalQuestion, setVidTotalQuestion] = useState('')
  const [attemptId, setAttemptId] = useState(0)
  const [langId, setLangId] = useState('')
  const [langList, setLangList] = useState([])
  const [audioLang, setAudioLang] = useState(0)
  const [langDir, setLangDir] = useState('')

  const [courseTotalQ, setCourseTotalQ] = useState(0)
  const [courseVideoTotalQ, setCourseVideoTotalQ] = useState(0)
  const [courseName, setCourseName] = useState('')
  const { t, i18n } = useTranslation();


  const saveButton = useRef(null)

  const navigate = useNavigate()

  const handlePracticeExamResult = async (id) => {
    let data = JSON.stringify({
      std_id: window.localStorage.getItem('stdId'),
    })

    await getPracticeExamResult(data)
      .then((response) => {
          console.log('response.data',response.data.data.resultData.length);
          if(response.data.data.resultData.length > 0){
        if (response.data.code != 206) {
          setVidTotalQuestion(response.data.data.videoTotalQuestion)
          setTotalQuestion(response.data.data.totalQuestion)
          setExamResult(response.data.data.resultData)
        } else {
          toastError('Content not available')
          setExamResult('')
        }
      }else{
        toastError('Result not found')
      }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const autoButtonClick = (buttonType) => {
    buttonType.current.addEventListener('click', modalCloseEvent)
    buttonType.current.click()
  }

  const handleAttemptId = async (attempt) => {
    setAttemptId(attempt)
  }

  const modalCloseEvent = () => {
    setLangId('')
    setAudioLang('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    let data = JSON.stringify({
      id: langId,
    })


    await editLang(data)
      .then((response) => {
        setIsLoading(false)
        // console.log('Direction',response.data.data.direction)

        if (response.data.code == 200) {
          autoButtonClick(saveButton)

          i18n.changeLanguage(response.data.data.lang_short);

          setLangDir(response.data.data.direction)
          if (response.data.data.direction == 'LTR') {
            navigate(`/exam/${attemptId}/${langId}/${audioLang}/2/1`)
            // navigate(`/practice-test/${attemptId}/${langId}/${audioLang}`)
          } else {
            navigate(`/exam/${attemptId}/${langId}/${audioLang}/2/2`)
            // navigate(`/rtl-exam/${attemptId}/${langId}/${audioLang}`)
          }
        }
        if (response.data.error) {
          setLangDir('')
          toastError('Content not available')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const getAllLangFunc = async (pageNo) => {
    await getAllLangForDropdown()
      .then((response) => {
        //  console.log(response.data.data)
        setLangList(response.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  
  const handleCountAllQuestion = async () => {
    let data = JSON.stringify({
      std_id: window.localStorage.getItem('stdId'),
    })

    await countAllAudioVidQuestions(data)
      .then((response) => {
        if (response.data.code != 206) {

          setCourseTotalQ(response.data.data.audioQuestion)
          setCourseVideoTotalQ(response.data.data.videoTotalQuestion)
          setCourseName(response.data.data.courseName)
         
        } else {
          toastError('Content not available')
          setExamResult('')
        }


      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    handlePracticeExamResult()
    getAllLangFunc(1)
    handleCountAllQuestion()
  }, [])

  return (
    <>
      <div>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <MixedWidget7
              className='card-xl-stretch mb-xl-8'
              chartColor='primary'
              chartHeight='200px'
              courseName={courseName}
              totalQ={courseTotalQ}
              
            />
          </div>

          <div className='col-xl-6'>
            <VideoExamWidgets
              className='card-xl-stretch mb-xl-8'
              chartColor='success'
              chartHeight='200px'
              courseName={courseName}
              totalQ={courseVideoTotalQ}
            />
          </div>

          <h4>Practice Test Result</h4>

          <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
              <div className='card' style={{width: '100%'}}>
                <div className='card-header border-0 pt-6'>
                  <div className='card-title'></div>
                </div>

                <div className='card-body pt-0'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='kt_table_users'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th className='min-w-125px'>Attempt#</th>
                        <th className='min-w-125px'>Attempt Date</th>
                        <th className='text-end min-w-100px'>Total Q</th>
                        <th className='text-end min-w-100px'>Correct Ans</th>
                        <th className='text-end min-w-100px'>Wrong Ans</th>
                        <th className='text-end min-w-100px'>Skip</th>
                        <th className='text-end min-w-100px'>Type</th>

                        <th className='text-end'>Action</th>
                      </tr>
                    </thead>

                    <tbody className='text-gray-600 fw-bold'>
                      {examResult
                        ? examResult.map((item,index) => {
                            return (
                              <tr>
                                <td className='min-w-125px'>{index+1}</td>
                                <td className='min-w-125px'>{item.created_at}</td>
                                {item.exam_type == 1 ? (
                                  <td className='text-end min-w-100px'>{totalQuestion}</td>
                                ) : (
                                  ''
                                )}

                                {item.exam_type == 2 ? (
                                  <td className='text-end min-w-100px'>{vidTotalQuestion}</td>
                                ) : (
                                  ''
                                )}

                                <td className='text-end min-w-100px'>{item.correct}</td>
                                <td className='text-end min-w-100px'>{item.wrong}</td>

                              
                                  <td className='text-end min-w-100px'>{item.skip}</td>
                                   
                                  
                             

                                <td className='text-end min-w-100px'>
                                  {item.exam_type == 1 ? 'Standard' : 'Video'}
                                </td>

                                {item.exam_type == 1 ? (
                                  <td className='text-end'>
                                    {item.exam_type == 1 && item.skip==0? (
                                      '-'
                                    ) : (
                                      <button
                                        className='btn btn-primary'
                                        data-bs-toggle='modal'
                                        data-bs-target='#langModal'
                                        onClick={() => {
                                          handleAttemptId(item.attempt_id)
                                        }}
                                      >
                                        Resume Test
                                      </button>
                                    )}
                                  </td>
                                ) : (
                                  <td className='text-end'>
                                    {item.exam_type == 2 && item.skip==0 ? (
                                      '-'
                                    ) : (
                                      <Link
                              
                                        to={`/exam/${item.attempt_id}/0/0/2/1`}
                                       
                                        className='btn btn-primary'
                                      >
                                        Resume Test
                                      </Link>

                                    )}
                                  </td>
                                )}
                              </tr>
                            )
                          })
                        : ''}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal ' id='langModal' aria-hidden='true' style={{marginTop: '100px'}}>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-close'
                data-bs-dismiss='modal'
                onClick={() => modalCloseEvent()}
                ref={saveButton}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <form class='form w-100' id='kt_sign_in_form' onSubmit={handleSubmit}>
                <div class='text-center mb-10'>
                  <h1 class='text-dark mb-3'>Choose Desired Options</h1>
                </div>

                <div class='fv-row mb-10'>
                  <div class='d-flex flex-stack mb-2'>
                    <label class='form-label fw-bolder text-dark fs-6 mb-0'>
                      Screen Text Language
                    </label>
                  </div>
                  <select
                    name='langText'
                    class='form-control form-control-lg form-control-solid'
                    required
                    onChange={(e) => {
                      setLangId(e.target.value)
                    }}
                  >
                    <option value=''>Text Language</option>

                    {langList
                      ? langList.map((langItem) => {
                          return <option value={langItem.id}>{langItem.lang}</option>
                        })
                      : ''}
                  </select>
                </div>
                <div class='fv-row mb-10'>
                  <label class='form-label fs-6 fw-bolder text-dark'>Audio Language</label>
                  <select
                    name='langAudio'
                    id=''
                    class='form-control form-control-lg form-control-solid'
                    required
                    onChange={(e) => setAudioLang(e.target.value)}
                  >
                    <option value='0'>No Audio</option>

                    {langList
                      ? langList.map((langItem) => {
                          return <option value={langItem.id}>{langItem.lang}</option>
                        })
                      : ''}
                  </select>
                </div>
                <div class='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    class='btn btn-lg btn-primary w-100 mb-5'
                  >
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExamType
