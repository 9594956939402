import React, { useEffect, useState } from 'react'
import { getPracticeExamResult, getPracticeExamStudent } from '../apis/ExamApi'
import { toastError } from '../global/Global'
import { Link, useNavigate } from 'react-router-dom'
import { PaginationControl } from 'react-bootstrap-pagination-control'
import { Dropdown } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../_metronic/helpers'

const PracticeReport = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [examResult, setExamResult] = useState([])
  const [totalQuestion, setTotalQuestion] = useState('')
  const [vidTotalQuestion, setVidTotalQuestion] = useState('')
  const [attemptId, setAttemptId] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [stdData, setStdData] = useState([])

  const navigate = useNavigate()


  const imageUrl = process.env.REACT_APP_IMAGE_URL



  const handleAttemptId = async (attempt) => {
    setAttemptId(attempt)
  }





  const handlePracticeExamResult = async (pageNo) => {
    setIsLoading(true)

    await getPracticeExamStudent(pageNo)
      .then((response) => {
        setIsLoading(false)
        console.log(response.data.data[0].attempts_count)
       if(response.data.code!=204){
        setPerPage(response.data.per_page)
        setTotalPage(response.data.total)
        setStdData(response.data.data)
   
       }else{
        setStdData('')
        toastError('Content not available');
       }

      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handlePracticeReport = (stdId) => {
    navigate(`/practice-report-detail/${stdId}`)
  }



  useEffect(() => {
    handlePracticeExamResult()

  }, [])

  return (
    <>
      <div>
      <h2>Practice List</h2>
        <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <div className='card' style={{width: '100%'}}>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='black'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='black'
                      />
                    </svg>
                  </span>

                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    class='form-control form-control-solid w-250px ps-14'
                    placeholder='Search Student'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

         
            </div>
            <div className='card-body pt-0'>
              <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>

              <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th className='min-w-125px'>Student</th>
                        <th className='min-w-125px'>Traffic ID</th>
                        <th className='min-w-125px'>Total Attempts</th>
      
                        <th className='text-end'>Action</th>
                      </tr>
                    </thead>

                    <tbody class='text-gray-600 fw-bold'>
                  
                  {stdData
                    ? stdData
                        .filter((item) => {
                         
                          return search.toLowerCase() === ''
                            ? item
                            : item.name.toLowerCase().includes(search) ||
                                // item.email.toLowerCase().includes(search) ||
                                item.tarffic_id.includes(search)
                        })
                        .map((item) => {
                          return (
                            <tr>

                         
                              <td class='d-flex align-items-center'>
                                <div class='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <a>
                                    <div class='symbol  symbol-40px symbol-circle'>
                                      
                          {
                            item.image?

                            <img src={imageUrl + item.image} />
          :     <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
           }
                                    </div>
                                  </a>
                                </div>

                                <div class='d-flex flex-column'>
                                  <a>{item.name +' '+ item.l_name}  </a>
                                  <span>{item.email!='null'? item.email:''}</span>
                                </div>
                              </td>

                              <td>{item.tarffic_id}</td>
                              <td>{item.attempts_count}</td>

                        

                              <td className='text-end'>
                                <Dropdown>
                                  <Dropdown.Toggle variant='light'>Actions</Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        handlePracticeReport(item.id)
                                      }}
                                    >
                                      Detail
                                    </Dropdown.Item>
                        
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                        
                            </tr>
                          )
                        })
                    : ''}
                </tbody>


      
              </table>

              <div style={{float: 'right'}}>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalPage}
                  limit={perPage}
                  changePage={(page) => {
                    setPage(page)
                    console.log(page)
                    handlePracticeExamResult(page)
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default PracticeReport