import React, {useEffect, useRef, useState} from 'react'
import {
  deleteRole,
  editRole,
  getAllPermissions,
  getAllRoles,
  saveRole,
  updateRole,
} from '../apis/RolesApi'
import {toastError, toastSuccess} from '../global/Global'
import {KTSVG} from '../../_metronic/helpers'
import Swal from 'sweetalert2'

const Roles = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState(false)
  const [role, setRole] = useState([])
  const [roleData, setRoleData] = useState([])
  const saveButton = useRef(null)
  const editButton = useRef(null)
  const [permData, setpermData] = useState([])
  const [selectedPermission, setSelectedPermission] = useState([])
  const [editPermissionData, setEditPermissionData] = useState([])
  const [isChecked, setIsChecked] = useState(true);

  const getAllRoleFunc = async (pageNo) => {
    setIsLoading(true)

    await getAllRoles(pageNo)
      .then((response) => {
       

        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleGetPermissions = async () => {
    setIsLoading(true)

    await getAllPermissions()
      .then((response) => {

        setpermData(response.data.data)

        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const autoButtonClick = (buttonType) => {
    buttonType.current.addEventListener('click', getAllRoleFunc)
    buttonType.current.click()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    let data = JSON.stringify({
      role: role,
      permissions: selectedPermission,
    })

    await saveRole(data)
      .then((response) => {
        setIsLoading(false)
        

        if (response.data.code == 201) {
          getAllRolesFunc()
          autoButtonClick(saveButton)
          setRole('')

          toastSuccess('Record save successfully')
        }
        if (response.data.message) {
          toastError(response.data.message)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const getAllRolesFunc = async () => {
    setIsLoading(true)
    await getAllRoles()
      .then((response) => {
        setRoleData(response.data.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleEdit = async (id) => {
    let data = JSON.stringify({
      id: id,
    })

    await editRole(data)
      .then((response) => {

        setId(response.data.data.roleName.id)
        setEditPermissionData(response.data.data.permissions.map(item => item.id))

        setRole(response.data.data.roleName.name)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleUpdateRole = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    let data = JSON.stringify({
      id: id,
      role: role,
      permissions: editPermissionData,
    })

    await updateRole(data)
      .then((response) => {
        setIsLoading(false)
        if (response.data.code == 200) {
          autoButtonClick(editButton)
          setRole('')
          toastSuccess('Record updated successfully')
          getAllRolesFunc()
        }

        if (response.data.error) {
          toastError(response.data.error)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        toastError(err)
      })
  }

  const deleteRecord = async (id) => {
    try {
      let data = JSON.stringify({
        id: id,
      })

      await deleteRole(data)
        .then((response) => {
          getAllRolesFunc()
          toastSuccess('Record deleted successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      toastError('Record not deleted')
    }
  }

  const handleDelete = (id) => {
    Swal.fire({
      text: 'Are you sure you want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(id)
      }
    })
  }

  const handlePermissionChange = (e) => {
    const {checked} = e.target
    if (checked) {
      setSelectedPermission([...selectedPermission, {id: e.target.value}])
    } else {
      setSelectedPermission(
        selectedPermission.filter((permission) => permission.id !== e.target.value)
      )
    }
  }

  const handleEditPermissionChange = (value) => {
    const newSelectedValues = editPermissionData.includes(value)
      ? editPermissionData.filter((item) => item !== value)
      : [...editPermissionData, value]

    setEditPermissionData(newSelectedValues)
  }
  

  useEffect(() => {
    getAllRolesFunc()
    handleGetPermissions()
   
  }, [])

  const [permArray, setPermArray] = useState([])
  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermArray(JSON.parse(storedArray))
    }
  }, [])

  return (
    <>
      <div class='post d-flex flex-column-fluid' id='kt_post'>
        <div id='kt_content_container' class='container-xxl'>
          <div class='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9'>
            {roleData
              ? roleData.map((item) => {
                  return (
                    <div class='col-md-4'>
                      <div class='card card-flush h-md-100'>
                        <div class='card-header'>
                          <div class='card-title'>
                            <h2>{item.name}</h2>
                          </div>
                        </div>

                        <div class='card-body pt-1'>
                          <div class='fw-bolder text-gray-600 mb-5'>
                            Total users with this role: 5
                          </div>

                          <div class='d-flex flex-column text-gray-600'>
                            <div class='d-flex align-items-center py-2'>
                              <span class='bullet bg-primary me-3'></span>All Admin Controls
                            </div>
                            <div class='d-flex align-items-center py-2'>
                              <span class='bullet bg-primary me-3'></span>View and Edit Financial
                              Summaries
                            </div>
                            <div class='d-flex align-items-center py-2'>
                              <span class='bullet bg-primary me-3'></span>Enabled Bulk Reports
                            </div>
                            <div class='d-flex align-items-center py-2'>
                              <span class='bullet bg-primary me-3'></span>View and Edit Payouts
                            </div>
                            <div class='d-flex align-items-center py-2'>
                              <span class='bullet bg-primary me-3'></span>View and Edit Disputes
                            </div>
                            <div class='d-flex align-items-center py-2'>
                              <span class='bullet bg-primary me-3'></span>
                              <em>and 7 more...</em>
                            </div>
                          </div>
                        </div>

                        <div class='card-footer flex-wrap pt-0'>
                        {permArray.some(item => item.name === 'role-delete')? 
                          <button
                            class='btn btn-light btn-active-primary my-1 me-2'
                            onClick={() => {
                              handleDelete(item.id)
                            }}
                          >
                            Delete Role
                          </button>
:''}
{permArray.some(item => item.name === 'role-edit')? 
                          <button
                            type='button'
                            class='btn btn-light btn-active-primary my-1 me-2'
                            data-bs-toggle='modal'
                            data-bs-target='#edit_role'
                            onClick={() => {
                              handleEdit(item.id)
                            }}
                          >
                            Edit Role
                          </button>
                          :''}
                        </div>
                      </div>
                    </div>
                  )
                })
              : ''}

              {permArray.some(item => item.name === 'role-create')? 
            <div class='ol-md-4'>
              <div class='card h-md-100'>
                <div class='card-body d-flex flex-center'>
                  <button
                    type='button'
                    class='btn btn-clear d-flex flex-column flex-center'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_add_role'
                  >
                    <img
                      src='/media/illustrations/sketchy-1/4.png'
                      alt='No Image'
                      className='mw-100 mh-150px mb-7'
                    />

                    <div class='fw-bolder fs-3 text-gray-600 text-hover-primary'>Add New Role</div>
                  </button>
                </div>
              </div>
            </div>
            :''}
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_add_role' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Add a Role</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-roles-modal-action='close'
                data-bs-dismiss='modal'
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-lg-5 my-7'>
              <form id='kt_modal_add_role_form' className='form' onSubmit={handleSubmit}>
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_role_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_role_header'
                  data-kt-scroll-wrappers='#kt_modal_add_role_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-10'>
                    <label className='fs-5 fw-bolder form-label mb-2'>
                      <span className='required'>Role name</span>
                    </label>
                    <input
                      className='form-control form-control-solid'
                      placeholder='Enter a role name'
                      name='role_name'
                      onChange={(e) => setRole(e.target.value)}
                      required
                    />
                  </div>

            
      {/* <ul>
        {selectedPermission.map(permission => (
          <li key={`${permission.moduleId}-${permission.permission_id}`}>
           Permission: {permission.id}
          </li>
        ))}
      </ul>  */}

                  <div className='fv-row'>
                    <label className='fs-5 fw-bolder form-label mb-2'>Role Permissions</label>
                    <div class='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5'>

                        <tbody className='text-gray-600 fw-bold'>
                          <tr>
                            <td></td>

                            <td>
                              <div className='d-flex'>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>View</span>
                                </label>

                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>Create</span>
                                </label>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>Edit</span>
                                </label>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>Delete</span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          {permData
                            ? permData.map((item) => {
                                return (
                                  <tr>
                                    <td className='text-gray-800'>{item.title}</td>

                                    <td>
                                      <div className='d-flex'>
                                        {item.permissions
                                          ? item.permissions.map((perm) => {
                                              return (
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value={perm.id}
                                                    name='perm'
                                                
                                                    onChange={(event) =>
                                                      handlePermissionChange(event)
                                                    }
                                                  />
                                                </label>
                                              )
                                            })
                                          : ''}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            : ''}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={saveButton}
                  >
                    Discard
                  </button>

                  <button type='submit' class='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Role */}
      <div class='modal fade' id='edit_role' tabindex='-1' aria-hidden='true'>
        <div class='modal-dialog modal-dialog-centered mw-750px'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h2 class='fw-bolder'>Edit Role</h2>

                  {/* <ul>
        {editPermissionData.map(id => (
          <li>
           Permission: {id}
          </li>
        ))}
      </ul>  */}

              <div
                class='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-roles-modal-action='close'
                data-bs-dismiss='modal'
              >
                <span class='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class='modal-body scroll-y mx-lg-5 my-7'>
              <form id='kt_modal_add_role_form' class='form' onSubmit={handleUpdateRole}>
                <div
                  class='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_role_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_role_header'
                  data-kt-scroll-wrappers='#kt_modal_add_role_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div class='fv-row mb-10'>
                    <label class='fs-5 fw-bolder form-label mb-2'>
                      <span class='required'>Role name</span>
                    </label>
                    <input
                      class='form-control form-control-solid'
                      placeholder='Enter a role name'
                      name='role_name'
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    />
                  </div>

                  <div class='fv-row'>
                    <label class='fs-5 fw-bolder form-label mb-2'>Role Permissions</label>
                    <div class='table-responsive'>

                      <table class='table align-middle table-row-dashed fs-6 gy-5'>


                      <tbody className='text-gray-600 fw-bold'>
                          <tr>
                            <td></td>

                            <td>
                              <div className='d-flex'>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>View</span>
                                </label>

                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>Create</span>
                                </label>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>Edit</span>
                                </label>
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <span className='form-check-label'>Delete</span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          {permData
                            ? permData.map((item) => {
                                return (
                                  <tr>
                                    <td className='text-gray-800'>{item.title}</td>

                                    <td>
                                      <div className='d-flex'>
                                        {item.permissions
                                          ? item.permissions.map((perm) => {
                                              return (
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value={perm.id}
                                                    name='perm'
                                                    onChange={(event) =>
                                                      handleEditPermissionChange(perm.id)
                                                    }
                                                    checked={editPermissionData.includes(perm.id)}
                     />
                                                </label>
                                              )
                                            })
                                          : ''}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            : ''}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    id='modalCancel'
                    ref={editButton}
                  >
                    Discard
                  </button>

                  <button type='submit' class='btn btn-primary'>
                    {isLoading ? (
                      <div>
                        <i className='fa fa-spinner fa-spin'></i>
                      </div>
                    ) : (
                      'Save Changes'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Roles
