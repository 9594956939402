import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL



export const createQuestions = async (data) => {
 
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/create-questions`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


export const getAllQuestions= async (pageNo)=>{
  
  var config = {
    method: 'get',
    url: `${API_URL}/get-all-questions?page=${pageNo}`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
  };

const response=await axios(config)
return response;
}

export const getAllQuestionsWithFilters= async (data)=>{
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/get-all-questions`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  

const response=await axios(config)
return response;
}


export const deleteQuestion= async (data)=>{

  var config = {
    method: 'delete',
    url: `${API_URL}/delete-question`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const editQuestion= async (id)=>{
  var config = {
    method: 'post',
    url: `${API_URL}/edit-question`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${API_TOKEN}`
    },
    data: id,
  };

const response=await axios(config)
return response;
}
export const updateQuestion= async (data)=>{
  var config = {
    method: 'post',
    url: `${API_URL}/update-question`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${API_TOKEN}`
    },
    data: data,
  };

const response=await axios(config)
return response;
}


export const getAllQuestionsForPractice= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-practice-question`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
   data: data,
  };
const response=await axios(config)
return response;
}


export const saveMcqsForPractExam = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/save-practice-exam`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


export const saveAttempt = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/save-attempt`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};

export const getQuestionsForActualExam= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/get-actual-exam-question`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
   data: data,
  };
const response=await axios(config)
return response;
}


export const getQuestionsForExam= async (data)=>{
  

  var config = {
    method: 'post',
    url: `${API_URL}/get-questions-for-exam`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
   data: data,
  };
const response=await axios(config)
return response;
}


export const saveMcqsForActualExam = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/save-actual-exam`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};

export const saveSolvedQuestions = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/save-solved-questions`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


export const createVideoQuestions = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/create-video-questions`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


export const getVideoQuestionForExam = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/get-video-question`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};


//saveActualExam
export const saveActualExam = async (data) => {

  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/actual-exam-solved`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};








