import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getAllRoles= async ()=>{
    var config = {
      method: 'get',
      url: `${API_URL}/get-all-roles`,
    };

  const response=await axios(config)
  return response;
}


export const getAllPermissions= async ()=>{
  var config = {
    method: 'get',
    url: `${API_URL}/get-all-permissions`,
  };

const response=await axios(config)
return response;
}


export const saveRole= async (data)=>{


  var config = {
    method: 'post',
    url: `${API_URL}/save-role`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${API_TOKEN}`
    },
    data : data
  };
  const response=await axios(config)
  return response;
  
  
  }
export const deleteRole= async (data)=>{
 

  var config = {
    method: 'delete',
    url: `${API_URL}/delete-role`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}
export const editRole= async (data)=>{
 

  var config = {
    method: 'post',
    url: `${API_URL}/edit-role`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}
export const updateRole= async (data)=>{


  var config = {
    method: 'post',
    url: `${API_URL}/update-role`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}