import React, {useState, useEffect} from 'react'
import {getAllLang, getAllLangForDropdown} from '../apis/LanguageApi'
import {getCourseForDropdown} from '../apis/CategoryApi'
import {toastError, toastSuccess} from '../global/Global'
import {createQuestions, editQuestion, updateQuestion} from '../apis/QuestionsBankApi'
import {exit} from 'process'
import {Link, useParams} from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";

const EditQuestion = () => {
  let {id} = useParams()
  const imageUrl = process.env.REACT_APP_IMAGE_URL

  const [editQTransData, setEditQTransData] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [qId, setQId] = useState(id)
  const [courseId, setCourseId] = useState('')
  const [qType, setQtype] = useState('')
  const [courseList, setCourseList] = useState([])
  const [langList, setLangList] = useState([])

  const [optAImage, setOptAImage] = useState('')
  const [optBImage, setOptBImage] = useState('')
  const [optCImage, setOptCImage] = useState('')
  const [qImage, setQImage] = useState('')
  const [correctOpt, setCorrectOpt] = useState('')
  const [langDirection, setLangDirection] = useState('')
  const [qVid, setQVid] = useState('')
  const [selectedCoursesId, setSelectedCoursesId] = useState([]);
 
  const [editCourses, setEditCourses] = useState([]);
  const [editCourseList, setEditCourseList] = useState([]);
  const [dbSavedCourese, setDbSavedCourese] = useState([]);

  const [qCode, setQCode] = useState('')



  const updateObjectItem = (index, property, value) => {
    const newArray = [...editQTransData]
    const updatedObject = {...newArray[index].question_translation}
    updatedObject[property] = value
    newArray[index].question_translation = updatedObject
    setEditQTransData(newArray)
  }

  const handleEditQuestion = async (qId) => {
    let data = JSON.stringify({
      id: qId,
    })

    await editQuestion(data)
      .then((response) => {
       
        if(response.data.code!=404){
          
      //  console.log('response.data.data.qInfo.q_courses',response.data.data.qInfo.q_courses);

        setEditQTransData(response.data.data.qTranslation)
        setDbSavedCourese(response.data.data.qInfo.q_courses);
        setCourseId(response.data.data.qInfo.course_id)
        setQtype(response.data.data.qInfo.q_type)
        setQCode(response.data.data.qInfo.q_code)
        setCorrectOpt(response.data.data.qInfo.correct_opt)
        setQImage(response.data.data.qInfo.q_image);

        setOptAImage(response.data.data.qInfo.opt_a_image);
        setOptBImage(response.data.data.qInfo.opt_b_image);
        setOptCImage(response.data.data.qInfo.opt_c_image);



        }else{
          
          toastError('Record not found')
          setEditQTransData([])
          setCourseId('')
          setQtype([''])
          setCorrectOpt('')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllLangFunc = async () => {
    await getAllLangForDropdown()
      .then((response) => {
    
        setLangList(response.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllCourseForDropdown = async () => {
    setIsLoading(true)

    await getCourseForDropdown()
      .then((response) => {
        // console.log('getCourseForDropdown', response.data.code)
        if (response.data.code != 206) {
          setCourseList(response.data.data)
        } else {
          setCourseList('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const FormData = require('form-data')
    let newData = new FormData()

    newData.append('q_id', qId)
    newData.append('course_id',editCourseList.map((item)=>(item.value)))
    newData.append('correct_opt', correctOpt)
    newData.append('q_type', qType)
    newData.append('q_code', qCode)
    newData.append('q_image[]', qImage)
    newData.append('opt_a_image', optAImage)
    newData.append('opt_b_image', optBImage)
    newData.append('opt_c_image[]', optCImage)
    newData.append('correct_opt', correctOpt)
    newData.append('q_video', qVid)

    

    Object.entries(editQTransData).forEach(([key, value]) => {

      newData.append('id[]', value.question_translation.id)
      newData.append('q_content[]', value.question_translation.q_content)
      newData.append('opt_a[]', value.question_translation.opt_a)
      newData.append('opt_b[]', value.question_translation.opt_b)
      newData.append('opt_c[]', value.question_translation.opt_c)
      newData.append('q_audio[]', value.question_translation.q_audio)
      newData.append('opt_a_audio[]', value.question_translation.opt_a_audio)
      newData.append('opt_b_audio[]', value.question_translation.opt_b_audio)
      newData.append('opt_c_audio[]', value.question_translation.opt_c_audio)
      newData.append('lang_id[]', value.id)
    })

    await updateQuestion(newData)
      .then((response) => {
 
        setIsLoading(false)
        if (response.data.code == 200) {
          handleEditQuestion(qId);
          toastSuccess('Record update successfully')
        }
        if (response.data.code == 204) {
          toastError('This record already exist')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        toastError(err.data.error)
        console.log(err.message)
      })
  }

  useEffect(() => {
    getAllLangFunc()
    getAllCourseForDropdown()
    handleEditQuestion(id)
  }, [])

  var courseOptionList = [];
  courseList && courseList.map((item, i) =>
  courseOptionList.push({
      label: item.cat_title, 
      value: item.id
      
    }),
  );



  

  useEffect(() => {
  
    if (dbSavedCourese && dbSavedCourese.length > 0) {
      // Use map to transform editCourses into editCourseList
      const updatedEditCourseList = dbSavedCourese.map((item) => ({
        label: item.course.cat_title,
        value: item.course_id,
      }));
      // Update the state with the new editCourseList
      setEditCourseList(updatedEditCourseList);
    }
  }, [dbSavedCourese]);

  return (
    <>
      <h3>Edit Question</h3>
      <form className='form' onSubmit={handleSubmitForm}>
        <div className='card mb-5 mb-xl-10'>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6 '>
                <label className='col-lg-2 col-form-label  fw-bold fs-6'>Course</label>

                <div className='col-lg-4 fv-row'>


<MultiSelect
        options={courseOptionList}
        value={editCourseList}
        onChange={setEditCourseList}
        labelledBy="Choose One"
      />
                </div>

                <div className='col-lg-1'></div>

                <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Type</label>

                <div className='col-lg-3 fv-row'>
                  <select
                    name='gender'
                    className='form-control form-control-lg form-control-solid'
                    required
                    style={{borderRadius: '20px'}}
                    onChange={(e) => setQtype(e.target.value)}
                    value={qType}
                  >
                    <option value=''>Choose Type</option>
                    <option value='1'>Standard</option>
                    <option value='2'>Video</option>
                  </select>
                </div>
              </div>

              <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Q Code</label>
                  <div className='col-lg-4 fv-row'>
                 
                  <input
                      type='text'
                      name='q_code'
                      className='form-control form-control-solid'
                      placeholder='Question Code'
                      onChange={(e) => setQCode(e.target.value)}
                      value={qCode}
              
                    />
                  </div>
                </div>

          

            </div>
          </div>
        </div>

        {editQTransData.map((item,index) => (   
          <div key={index}>

          <div className='card mb-5 mb-xl-10' >
            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>

              {qType==1?
                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Language</label>
                  <div className='col-lg-4 fv-row'>
                    <select
                      name='gender'
                      className='form-control form-control-lg form-control-solid'
                      required
                      style={{borderRadius: '20px'}}
                      value={item.id}
                      onChange={(event) => updateObjectItem(index, 'lang_id', event.target.value)}
                    >
                      <option value={item.id}>{item.lang}</option>

                   
                    </select>
                  </div>
                </div>
                :''}

                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Content</label>

                  <div className='col-lg-10 fv-row'>
                    <textarea
                      name='email_template'
                      className='form-control form-control-solid h-100px'
                      value={item.question_translation?item.question_translation.q_content:''}
                      onChange={(event) => {
                        updateObjectItem(index, 'q_content', event.target.value)
                      }}
                      dir={item.lang.direction}
                      required
                    ></textarea>
                  </div>
                </div>

                {index==0 && qType==1?
                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Image</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='file'
                      name='q_image'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        setQImage(e.target.files[0])
                      }}
                    />

                  </div>

                  <div className='col-lg-3 fv-row'>
              {
                qImage?
                <img  src={imageUrl + qImage} alt='Image'  width='100' className='radio-container zoom d-inline'  /> :''
              }
                 
                                             
                  </div>
                </div>:''}

                {qType==1?
                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Audio</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='file'
                      name='q_audio'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        updateObjectItem(index, 'q_audio', e.target.files[0])
                      }}
                    />
                  </div>

                  
                  <div className='col-lg-3 fv-row'>
              {
                item.question_translation && item.question_translation.q_audio?
                <audio src={imageUrl + item.question_translation.q_audio}
                 controls={true} autoPlay={false} /> :''
              }
                 
                                             
                  </div>

                  <></>
                </div>
                :''}


                {qType==2?

            
                  <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Video</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='file'
                      name='q_video'
                      accept='video/*'
                      className='form-control form-control-solid'
                
                      onChange={(e) => {
                        setQVid(e.target.files[0])
                      }}
                   
                    />
                  </div>

                  <></>
                </div>
                :''}

               
                <h3>Answer Questions</h3>
                <div className='row mb-6 '>
                  
                <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name={`correct_opt${index}`}
                      value='a'
                      disabled={index==0?false:true}
                      onChange={(event) => setCorrectOpt(event.target.value)}
                      checked={( correctOpt=='a')?'checked':""}
                      required
                    />
                  </span>
                </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_a'
                      className='form-control form-control-solid'
                      onChange={(event) => updateObjectItem(index, 'opt_a', event.target.value)}
                      placeholder='Option A'
                  
                      value={item.question_translation?item.question_translation.opt_a:''}
                      dir={item.lang.direction}
                    />
                  </div>
                  {index==0? 
                  <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Option A Image</label>
                <div className='col-lg-2 fv-row'>
                  <input
                    type='file'
                    name='opt_a_image'
                    className='form-control form-control-solid'
                    onChange={(e) => {
                      setOptAImage(e.target.files[0])
                    }}
                  />
                </div>
                </>
                :''}

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Option A Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_audio'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        updateObjectItem(index, 'opt_a_audio', e.target.files[0])
                      }}
                    />
                  </div>


                </div>

                {/* show images for opt A edit start here */}
                <div className='row mb-6 '>
                  
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                
                  </label>
  
                    <div className='col-lg-3 fv-row'>
                    
                    </div>
                    {index==0 && optAImage? 
                    <>
             
                  <div className='col-lg-2 fv-row'>
             
                 <img  src={imageUrl + optAImage} alt='Opt A Image'  width='100' className='radio-container zoom d-inline'  /> :''
                  </div>
                  </>
                  :''}
  
                    <label className='col-lg-2 col-form-label  fw-bold fs-6'></label>
                    <div className='col-lg-2 fv-row'>
            

                    {
                item.question_translation && item.question_translation.opt_a_audio?
                <audio src={imageUrl + item.question_translation.opt_a_audio}
                 controls={true} autoPlay={false} /> :''
              }
                    </div>
  
  
                  </div>
                    {/* show images for  opt A  edit end here  */}
  
                <div className='row mb-6 '>
               
                <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name={`correct_opt${index}`}
                      value='b'
                      onChange={(event) => setCorrectOpt(event.target.value)}
                      required
                      checked={( correctOpt=='b')?'checked':""}
                      disabled={index==0?false:true}
                    />
                  </span>
                </label>


                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_b'
                      className='form-control form-control-solid'
                      placeholder='Option B'
                      onChange={(event) => updateObjectItem(index, 'opt_b', event.target.value)}
                      value={item.question_translation?item.question_translation.opt_b:''}
                      dir={item.lang.direction}
                    />
                  </div>
                  {index==0? 
                  <>
   <label className='col-lg-2 col-form-label  fw-bold fs-6'>Option B Image</label>
                <div className='col-lg-2 fv-row'>
                  <input
                    type='file'
                    name='opt_b_image'
                    className='form-control form-control-solid btn-primary'
                  />
                </div>
                </>:''}

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Option B Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_b_audio'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        updateObjectItem(index, 'opt_b_audio', e.target.files[0])
                      }}
                    />
                  </div>
                </div>

      {/* show images for opt B edit start here */}
      <div className='row mb-6 '>
                  
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                
                  </label>
  
                    <div className='col-lg-3 fv-row'>
                    
                    </div>
                    {index==0 && optBImage? 
                    <>
             
                  <div className='col-lg-2 fv-row'>
             
                 <img  src={imageUrl + optBImage} alt='Opt B Image'  width='100' className='radio-container zoom d-inline'  /> :''
                  </div>
                  </>
                  :''}
  
                    <label className='col-lg-2 col-form-label  fw-bold fs-6'></label>
                    <div className='col-lg-2 fv-row'>
            

                    {
                item.question_translation && item.question_translation.opt_b_audio?
                <audio src={imageUrl + item.question_translation.opt_b_audio}
                 controls={true} autoPlay={false} /> :''
              }
                    </div>
  
  
                  </div>
                    {/* show images for  opt B  edit end here  */}

                <div className='row mb-6 '>

                <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name={`correct_opt${index}`}
                      value='c'
                      onChange={(event) => setCorrectOpt(event.target.value)}
                      required
                      checked={( correctOpt=='c')?'checked':""}
                      disabled={index==0?false:true}
                    />
                  </span>
                </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_c'
                      className='form-control form-control-solid'
                      placeholder='Option C'
                      onChange={(event) => updateObjectItem(index, 'opt_c', event.target.value)}
                      value={item.question_translation?item.question_translation.opt_c:''}
                      dir={item.lang.direction}
                    />
                  </div>
                  {index==0? 
                  <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Option C Image</label>
                <div className='col-lg-2 fv-row'>
                  <input
                    type='file'
                    name='opt_c_image'
                    className='form-control form-control-solid'
                  />
                </div>
                </>:''}

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Option C Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_c_audio'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        updateObjectItem(index, 'opt_c_audio', e.target.files[0])
                      }}
                    />
                  </div>
                </div>
            
     {/* show images for opt C edit start here */}
     <div className='row mb-6 '>
                  
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                
                  </label>
  
                    <div className='col-lg-3 fv-row'>
                    
                    </div>
                    {index==0 && optCImage? 
                    <>
             
                  <div className='col-lg-2 fv-row'>
             
                 <img  src={imageUrl + optCImage} alt='Opt C Image'  width='100' className='radio-container zoom d-inline'  /> :''
                  </div>
                  </>
                  :''}
  
                    <label className='col-lg-2 col-form-label  fw-bold fs-6'></label>
                    <div className='col-lg-2 fv-row'>
            

                    {
                item.question_translation && item.question_translation.opt_c_audio?
                <audio src={imageUrl + item.question_translation.opt_c_audio}
                 controls={true} autoPlay={false} /> :''
              }
                    </div>
  
  
                  </div>
                    {/* show images for  opt C  edit end here  */}

              </div>
            </div>
          </div>
          </div>

          
 
        ))}
        <div className='d-flex justify-content-end py-6 px-9'>
          <Link to='/q-bank'  className='btn btn-default'>
            Cancel
          </Link>
          <button type='submit' className='btn btn-primary'>
            {isLoading ? (
              <div>
                <i className='fa fa-spinner fa-spin'></i>
              </div>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default EditQuestion
