import React, {useState, useEffect} from 'react'
import {getAllLang, getAllLangForDropdown} from '../apis/LanguageApi'
import {getCourseForDropdown} from '../apis/CategoryApi'
import {toastError, toastSuccess} from '../global/Global'
import {createQuestions, createVideoQuestions} from '../apis/QuestionsBankApi'
import {exit} from 'process'
import {Link, useNavigate} from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";


const AddQuestion = () => {


  const [isLoading, setIsLoading] = useState(false)
  const [langData, setLangData] = useState([])
  const [courseId, setCourseId] = useState('')
  const [qType, setQtype] = useState('')
  const [courseList, setCourseList] = useState([])
  const [optAImage, setOptAImage] = useState('')
  const [optBImage, setOptBImage] = useState('')
  const [optCImage, setOptCImage] = useState('')
  const [qImage, setQImage] = useState('')
  const [correctOpt, setCorrectOpt] = useState('')
  const [qCode, setQCode] = useState('')

  // states for  video questions
  const [qVid, setQVid] = useState('')
  const [vidOptAImage, setVidOptAImage] = useState('')
  const [vidOptBImage, setVidOptBImage] = useState('')
  const [vidOptCImage, setVidOptCImage] = useState('')
  const [vidOptAAudio, setVidOptAAudio] = useState('')
  const [vidOptBAudio, setVidOptBAudio] = useState('')
  const [vidOptCAudio, setVidOptCAudio] = useState('')
  const [vidOptA, setVidOptA] = useState('')
  const [vidOptB, setVidOptB] = useState('')
  const [vidOptC, setVidOptC] = useState('')
  const[vidCorrectOpt,setVidCorrectOPt]=useState('');
  const[vidQContent,setVidQContent]=useState('');
  const [selectedCoursesId, setSelectedCoursesId] = useState([]);



    // states end for  video questions



  const [qData, setQData] = useState([
    {
      qContent: '',
      qAudio: '',
      optA: '',
      optB: '',
      optC: '',
      langId: '',
      optAAudio: '',
      optBAudio: '',
      optCAudio: '',
    },
  ])

  const handleChangeValues = (index, property, value) => {

    const newArray = [...qData]
    const updatedObject = {...newArray[index]}
    updatedObject[property] = value
    newArray[index] = updatedObject
    setQData(newArray)
  }


  const handleChangeFunc = (index,langId) => {

          handleChangeValues(index, 'langId',langId);
    //  function1(index,langId);
   
  };


  const getAllLangFunc = async (pageNo) => {
    await getAllLangForDropdown()
      .then((response) => {
        setLangData(response.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }



  const getAllCourseForDropdown = async () => {
    setIsLoading(true)

    await getCourseForDropdown()
      .then((response) => {
  
        if (response.data.code != 206) {
          setCourseList(response.data.data)
        } else {
          setCourseList('')
          toastError('Content not available')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setIsLoading(true)



    const FormData = require('form-data')
    let newData = new FormData()
    
    newData.append('course_id',selectedCoursesId.map((item)=>(item.value)))
    newData.append('q_type', qType)
    newData.append('qCode', qCode)
    newData.append('opt_a_image', optAImage)
    newData.append('opt_b_image', optBImage)
    newData.append('opt_c_image', optCImage)
    newData.append('q_image', qImage)
    newData.append('correct_opt', correctOpt)

  

    Object.entries(qData).forEach(([key, value]) => {
      // console.log(value['qContent']);
      newData.append('q_content[]', value['qContent'])
      newData.append('q_audio[]', value['qAudio'])
      newData.append('opt_a[]', value['optA'])
      newData.append('opt_b[]', value['optB'])
      newData.append('opt_c[]', value['optC'])
      newData.append('opt_a_audio[]', value['optAAudio'])
      newData.append('opt_b_audio[]', value['optBAudio'])
      newData.append('opt_c_audio[]', value['optCAudio'])
    })


 

    await createQuestions(newData)
      .then((response) => {

        setIsLoading(false)

        if (response.data.code == 200) {
          toastSuccess('Record save successfully')
        }
        if (response.data.code == 204) {
          toastError(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
        toastError(err.data.error)
  
      })
  }



  // submit video form
  const handleSubmitVideoForm = async (e) => {

    e.preventDefault()
    setIsLoading(true)



    const FormData = require('form-data')
    let newData = new FormData()

    
    newData.append('course_id',selectedCoursesId.map((item)=>(item.value)))

    newData.append('qCode', qCode)
    newData.append('qVideo', qVid)
    newData.append('optA', vidOptA)
    newData.append('optB', vidOptB)
    newData.append('optC', vidOptC)
    newData.append('optAImage', vidOptAImage)
    newData.append('optBImage', vidOptBImage)
    newData.append('optCImage', vidOptCImage)
    newData.append('optAAudio', vidOptAAudio)
    newData.append('optBAudio', vidOptBAudio)
    newData.append('optCAudio', vidOptCAudio)
    newData.append('correct_opt', vidCorrectOpt)
    newData.append('vidQContent', vidQContent)
 



    await createVideoQuestions(newData)
      .then((response) => {
        setIsLoading(false)
     


        if (response.data.code == 200) {
          toastSuccess('Record save successfully')
          setCourseId('');
          setQVid('');
          setVidOptA('');
          setVidOptB('');
          setVidOptC('');
          setOptAImage('');
          setOptBImage('');
          setOptCImage('');
          setCorrectOpt('');
          setVidOptAAudio('');
          setVidOptBAudio('');
          setVidOptCAudio('');
        }
        if (response.data.code == 204) {
          toastError('This record already exist')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        toastError(err.data.error)
        console.log(err.message)
      })
  }


  useEffect(() => {
    getAllLangFunc(1)
    getAllCourseForDropdown();

  }, [])

  var courseOptionList = [];
  courseList && courseList.map((item, i) =>
  courseOptionList.push({
      label: item.cat_title, 
      value: item.id
      
    }),
  );

  return (
    <>
      <h3>Add New Questions</h3>
      <form className='form' onSubmit={handleSubmitForm}>
        <div className='card mb-5 mb-xl-10'>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6 '>
                <label className='col-lg-2 col-form-label  fw-bold fs-6'>Course</label>

                <div className='col-lg-4 fv-row'>


      <MultiSelect
        options={courseOptionList}
        value={selectedCoursesId}
        onChange={setSelectedCoursesId}
        labelledBy="Choose One"
      />
                </div>

                <div className='col-lg-1'></div>

                <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Type:</label>

                <div className='col-lg-3 fv-row'>
                  <select
                    name='gender'
                    className='form-control form-control-lg form-control-solid'
                    required
                    style={{borderRadius: '20px'}}
                    onChange={(e) => setQtype(e.target.value)}
                  >
                    <option value=''>Choose Type</option>
                    <option value='1'>Standard</option>
                    <option value='2'>Video</option>
                  </select>
                </div>
              </div>
              <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Q Code</label>
                  <div className='col-lg-4 fv-row'>
                 
                  <input
                      type='text'
                      name='q_code'
                      className='form-control form-control-solid'
                      placeholder='Question Code'
                      onChange={(e) => setQCode(e.target.value)}
              
                    />
                  </div>
                </div>

            </div>
          </div>
        </div>
        
      

        { qType==1? langData.map((item, index) => (

    
          <div className='card mb-5 mb-xl-10'>
            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>

                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label required fw-bold fs-6'>Language</label>
                  <div className='col-lg-4 fv-row'>
                 
                    <select
                      name='gender'
                      className='form-control form-control-lg form-control-solid'
                      required
                      style={{borderRadius: '20px'}}
                    >
                      <option value={item.id}>{item.lang}</option>
                    </select>
                  </div>
                </div>

            
                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Content</label>

                  <div className='col-lg-10 fv-row'>
                    <textarea
                      className='form-control form-control-solid h-100px'
                      dir={item.direction}
                      onChange={(e) => {
                        handleChangeFunc(index,item.id);
                        handleChangeValues(index, 'qContent', e.target.value);
                      
                      }}
                    ></textarea>
                  </div>
                </div>
{ index==0?
                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Image</label>
                  <div className='col-lg-3 fv-row'>
                    <input
                      type='file'
                      name='q_image'
                      className='form-control form-control-solid'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => {
                        setQImage(e.target.files[0])
                      }}
                    />
                  </div>
                </div>
:''}

                <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Audio</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='file'
                      name='q_audio'
                      accept='audio/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {handleChangeValues(index, 'qAudio', e.target.files[0]) }}  
                    />
                  </div>

                  <></>
                </div>

                <h3>Answer Questions</h3>
               

                <div className='row mb-6 '>
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                    <span class='form-check form-check-custom form-check-solid'>
                      <input class='form-check-input' type='radio'
                       name='correct_opt'
                        value='a' 
                        onChange={(e) => {setCorrectOpt(e.target.value)}}
                        disabled={index==0?false:true}
          
                      required/>
                    </span>
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_a'
                      className='form-control form-control-solid'
                      placeholder='Option A'
                      onChange={(e) => {handleChangeValues(index, 'optA', e.target.value) }} 
                      dir={item.direction}
                      
                    />
                  </div>
                  {index==0?
                    <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Image</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_image'
                      className='form-control form-control-solid'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => {setOptAImage(e.target.files[0])}}  

                    />
                  </div>
                  </>
                  :''}

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_audio'
                      accept='audio/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {handleChangeValues(index, 'optAAudio', e.target.files[0]) }}  
                    />
                  </div>
                </div>
                <div className='row mb-6 '>
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                    <span class='form-check form-check-custom form-check-solid'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='correct_opt'
                        value='b'
                        disabled={index==0?false:true}
                        onChange={(e) => {
                          setCorrectOpt(e.target.value);
                          handleChangeValues(index, 'langId', item.id)
                          }} 
                        required
                      />
                    </span>
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_b'
                      className='form-control form-control-solid'
                      placeholder='Option B'
                      onChange={(e) => {handleChangeValues(index, 'optB', e.target.value) }} 
                      dir={item.direction}
                      
                    />
                  </div>
                  {index==0?
                    <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Image</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_b_image'
                      accept='.png, .jpg, .jpeg'
                      className='form-control form-control-solid btn-primary'
                      onChange={(e) => {setOptBImage(e.target.files[0]) }} 
                    />
                  </div>
</>:''}
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_b_audio'
                      accept='audio/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {handleChangeValues(index, 'optBAudio', e.target.files[0]) }} 
                    />
                  </div>
                </div>
                <div className='row mb-6 '>
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                    <span class='form-check form-check-custom form-check-solid'>
                      <input class='form-check-input' type='radio'
                       name='correct_opt'
                      value='c' 
                      disabled={index==0?false:true}
                         onChange={(e) => {setCorrectOpt(e.target.value) }} 
                         required
                      />
                    </span>
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_c'
                      className='form-control form-control-solid'
                      placeholder='Option C'
                      onChange={(e) => {handleChangeValues(index, 'optC', e.target.value)}} 
                      dir={item.direction}
                      
                    />
                  </div>
                  {index==0?
                  <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Image</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_c_image'
                      className='form-control form-control-solid'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => {setOptCImage(e.target.files[0]) }} 
                    />
                  </div>
</>:''}
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_c_audio'
                      accept='audio/*'
                
                      className='form-control form-control-solid'
                      onChange={(e) => {handleChangeValues(index, 'optCAudio', e.target.files[0]) }} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )) :''}


 
        { qType==1?
        <div className='d-flex justify-content-end py-6 px-9'>
        <Link to='/q-bank'>
          <button type='button' class='btn btn-default'>
            Cancel
          </button>
          </Link>
          <button type='submit' class='btn btn-primary'>
            {isLoading ? (
              <div>
                <i className='fa fa-spinner fa-spin'></i>
              </div>
            ) : (
              'Save'
            )}
          </button>
        </div>
        :''}

      </form>


      { qType==2?



      <form className='form' onSubmit={handleSubmitVideoForm}>

      <div className='card mb-5 mb-xl-10'>
            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-9'>

              <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Content</label>

                  <div className='col-lg-10 fv-row'>
                    <textarea
                      className='form-control form-control-solid h-100px'
                      
                      onChange={(e) => {
                              setVidQContent(e.target.value)
                            }}
                    ></textarea>
                  </div>
                </div>
              
              <div className='row mb-6 '>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Question Video</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='file'
                      name='q_video'
                      accept='video/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                              setQVid(e.target.files[0])
                            }}
                   
                    />
                  </div>

                  <></>
                </div>
           

                <h3>Answer Questions</h3>
               

                <div className='row mb-6 '>
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                    <span class='form-check form-check-custom form-check-solid'>
                      <input class='form-check-input' type='radio'
                       name='correct_opt'
                        value='a' 
                        onChange={(e) => {
                              setVidCorrectOPt(e.target.value)
                            }}
                   
                    
                      required/>
                    </span>
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_a'
                      className='form-control form-control-solid'
                      placeholder='Option A'
                      onChange={(e) => {
                              setVidOptA(e.target.value)
                            }}
                  
                      
                    />
                  </div>
               
                    <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Image</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_image'
                      className='form-control form-control-solid'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => {
                              setVidOptAImage(e.target.files[0])
                            }}

                    />
                  </div>
                  </>
            

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_audio'
                      accept='audio/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        setVidOptAAudio(e.target.files[0])
                            }}
                  
                    />
                  </div>
                </div>


                <div className='row mb-6 '>
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                    <span class='form-check form-check-custom form-check-solid'>
                      <input class='form-check-input' type='radio'
                       name='correct_opt'
                        value='b' 
                        onChange={(e) => {
                              setVidCorrectOPt(e.target.value)
                            }}
                   
                    
                      required/>
                    </span>
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_b'
                      className='form-control form-control-solid'
                      placeholder='Option B'
                      onChange={(e) => {
                              setVidOptB(e.target.value)
                            }}
                  
                      
                    />
                  </div>
               
                    <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Image</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_image'
                      className='form-control form-control-solid'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => {
                              setVidOptBImage(e.target.files[0])
                            }}

                    />
                  </div>
                  </>
            

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_audio'
                      accept='audio/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        setVidOptBAudio(e.target.files[0])
                            }}
                  
                    />
                  </div>
                </div>



                <div className='row mb-6 '>
                  <label className='col-lg-1 col-form-label  fw-bold fs-6'>
                    <span class='form-check form-check-custom form-check-solid'>
                      <input class='form-check-input' type='radio'
                       name='correct_opt'
                        value='c' 
                        onChange={(e) => {
                              setVidCorrectOPt(e.target.value)
                            }}
                   
                    
                      required/>
                    </span>
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='text'
                      name='opt_c'
                      className='form-control form-control-solid'
                      placeholder='Option C'
                      onChange={(e) => {
                              setVidOptC(e.target.value)
                            }}
                  
                      
                    />
                  </div>
               
                    <>
                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Image</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_image'
                      className='form-control form-control-solid'
                      accept='.png, .jpg, .jpeg'
                      onChange={(e) => {
                              setVidOptCImage(e.target.files[0])
                            }}

                    />
                  </div>
                  </>
            

                  <label className='col-lg-2 col-form-label  fw-bold fs-6'>Answer Audio</label>
                  <div className='col-lg-2 fv-row'>
                    <input
                      type='file'
                      name='opt_a_audio'
                      accept='audio/*'
                      className='form-control form-control-solid'
                      onChange={(e) => {
                        setVidOptCAudio(e.target.files[0])
                            }}
                  
                    />
                  </div>
                </div>
             
              </div>



              
        <div className='d-flex justify-content-end py-6 px-9'>
        <Link to='/q-bank'>
          <button type='button' class='btn btn-default'>
            Cancel
          </button>
          </Link>
          <button type='submit' class='btn btn-primary'>
            {isLoading ? (
              <div>
                <i className='fa fa-spinner fa-spin'></i>
              </div>
            ) : (
              'Save'
            )}
          </button>
        </div>
        
            </div>
          </div>
            </form>
            :''}
    </>
  )
}

export default AddQuestion
