import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getDashBoardStat= async ()=>{
    var config = {
      method: 'get',
      url: `${API_URL}/admin-dashboard-states`,
    };

  const response=await axios(config)
  return response;
}
