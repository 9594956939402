import React from 'react'
import { MixedWidget11 } from '../../_metronic/partials/widgets'
const ChartSection = () => {
  return (
    <>
       <div class='col-xl-12'>

<div className='col-xxl-12'>
  

  <MixedWidget11
    className='card-xxl-stretch-50 mb-5 mb-xl-8'
    chartColor='primary'
    chartHeight='300px'
  />


</div>
    </div>
    </>
  )
}

export default ChartSection
