import axios from "axios";
const API_TOKEN=window.localStorage.getItem('authToken');
const API_URL = process.env.REACT_APP_API_URL


export const getAllStudents= async (pageNo)=>{
  
    var config = {
      method: 'get',
      url: `${API_URL}/get-all-students?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
       'Content-Type': 'application/json',
     },
    };

  const response=await axios(config)
  return response;
}
export const getStudentsForDropdown= async ()=>{
  
  var config = {
    method: 'get',
    url: `${API_URL}/get-std-dropdown`,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
     'Content-Type': 'application/json',
   },
  };

const response=await axios(config)
return response;
}
export const createStudent = async (data) => {

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/save-student`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;

};

export const importBulkQuestion = async (data) => {

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${API_URL}/import-question`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data : data
  };
  
  const response=await axios.request(config)
  return response;
  

};

export const deleteStudent= async (data)=>{

  var config = {
    method: 'delete',
    url: `${API_URL}/delete-student`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}

export const editStudent= async (data)=>{

  var config = {
    method: 'post',
    url: `${API_URL}/edit-student`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
const response=await axios(config)
return response;
}
export const updateStudent= async (data)=>{


  var config = {
    method: 'post',
    url: `${API_URL}/update-student`,
    headers: {
       Authorization: `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

const response=await axios(config)
return response;
}




